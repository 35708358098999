import { MessageType } from './model/MessageType';

var msgpack = require('msgpack-lite');

export interface UnmarshalResult {
    messageType: MessageType;
    message: any;
}

export function marshal(type: MessageType, message: any): Uint8Array {
    const typeArray = new TextEncoder().encode(type + ':');
    const dataArray = new Uint8Array(msgpack.encode(message));
    return concatArrays(typeArray, dataArray);
}

export function unmarshal(data: Uint8Array): UnmarshalResult {
    let delimiterIndex = -1;
    for (let i = 0; i < data.length; i++) {
        if (data[i] == 58) {
            delimiterIndex = i;
            break;
        }
    }
    if (delimiterIndex == -1) {
        throw new Error('Type delimiter not found in message.');
    }
    const typeArray = data.subarray(0, delimiterIndex);
    const messageArray = data.subarray(delimiterIndex + 1, data.length);
    const message = msgpack.decode(messageArray);
    return {
        messageType: new TextDecoder().decode(typeArray) as MessageType,
        message: message,
    };
}

function concatArrays(a: Uint8Array, b: Uint8Array) {
    // a, b TypedArray of same type
    const newArray = new Uint8Array(a.length + b.length);
    newArray.set(a, 0);
    newArray.set(b, a.length);
    return newArray;
}
