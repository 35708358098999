
import { Component, Prop, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../framework/state';
import { getResource, putResource } from '../../../../framework/client/resource';
import { Role } from '../../../../../common/framework/model/Role';
import RoleMembers from './RoleMembers.vue';
import SwitchField from '../../../fields/SwitchField.vue';
import SelectField from '../../../fields/SelectField.vue';
import PhoneField from '../../../fields/PhoneField.vue';
import TextField from '../../../fields/TextField.vue';
import EmailField from '../../../fields/EmailField.vue';
import UserRoles from '../users/UserRoles.vue';
import { ValidationObserver } from 'vee-validate';

@Component({
    components: {
        SwitchField,
        SelectField,
        PhoneField,
        TextField,
        EmailField,
        UserRoles,
        ValidationObserver,
        RoleMembers,
    },
})
export default class EditRole extends Vue {
    readonly resourceType = 'role';

    @Prop(String) readonly id!: string;

    shared = sharedState;
    local = {
        id: '',
        name: '',
        created: new Date(),
        modified: new Date(),
    };

    async mounted() {
        const row = (await getResource(this.resourceType, this.id))!! as Role;
        Object.assign(this.local, row);
        this.local.name = row.name;
    }

    async save() {
        const row = (await getResource(this.resourceType, this.id))!!;
        await putResource(this.resourceType, this.id, Object.assign(row, this.local));
        this.$router.go(-1);
    }

    back() {
        this.$router.go(-1);
    }
}
