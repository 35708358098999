import { DateTime } from 'luxon';

export function dateToDbDateString(date: Date) {
    return DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
}

export function dateToDbDateStringWithTimeZone(date: Date) {
    return DateTime.fromJSDate(date).toISO();
}

export function dateToUiDateString(date: Date) {
    if (!date) {
        return '';
    }
    return DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
}

export function dateToDbMonthDateString(date: Date) {
    return DateTime.fromJSDate(date).toFormat('yyyy-MM');
}

export function dateToUiDateTimeString(date: Date) {
    return DateTime.fromJSDate(date).toFormat('yyyy-MM-dd HH:mm:ss');
}

export function dateAndTimeToDbDateTimeString(date: Date, time: Date) {
    const d = new Date(time);
    d.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
    return d.toISOString();
}

export function dateToFinnishUiDateString(date: Date) {
    if (!date) {
        return '';
    }

    return DateTime.fromJSDate(date).toFormat('dd.MM.yyyy');
}

export function dateToFinnishDbDateString(date: Date) {
    if (!date) {
        return '';
    }

    return DateTime.fromJSDate(date).toISODate();
}

export function dateToDbDateTimeString(date: Date) {
    return DateTime.fromJSDate(date).toFormat('yyyy-MM-dd HH:mm:ss');
}

/**
 * Gets UTC beginning time of day from local beginning time of day.
 * @param date
 */
export function getUtcDate(date: Date): Date {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
}

/**
 * Gets local beginning time of day from date.
 * @param timestamp
 */
export function getDate(timestamp: Date): Date {
    return new Date(timestamp.getFullYear(), timestamp.getMonth(), timestamp.getDate());
}

/**
 * Gets local beginning time of day from UTC beginning time of day.
 * @param date
 */
export function getLocalDate(date: Date): Date {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
}

export function underscoreToCamel(str: string) {
    return str.replace(/(_[a-z0-9])/g, (s) => s.toUpperCase().replace(/_/g, ''));
}

export function hyphenatedToCamel(str: string) {
    return str.replace(/(-[a-z0-9])/g, (s) => s.toUpperCase().replace(/-/g, ''));
}

export function camelToUnderscore(str: string) {
    return str[0].toLocaleLowerCase() + str.substring(1).replace(/([A-Z])/g, (s) => '_' + s.toLowerCase());
}

export function camelToHyphenated(str: string) {
    return str[0].toLocaleLowerCase() + str.substring(1).replace(/([A-Z])/g, (s) => '-' + s.toLowerCase());
}

export function underscoreToHyphenated(str: string) {
    return str.toLowerCase().replace(/_/g, '-');
}

export function lowerCamelToUpperCamel(str: string) {
    return str.charAt(0).toUpperCase() + str.substring(1);
}

export function upperCamelToLowerCamel(str: string) {
    return str.charAt(0).toLowerCase() + str.substring(1);
}

export function dateDifferenceToString(laterDate: Date, earlierDate: Date) {
    const laterTimeMillis = laterDate.getTime();
    const earlierTimeMillis = earlierDate.getTime();

    // get total seconds between the times
    let delta = Math.abs(laterTimeMillis - earlierTimeMillis) / 1000;

    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / 60) % 60;

    return `${days}d ${hours}h ${minutes}min`;
}

export function dateDifferenceInEntireDays(laterDate: Date, earlierDate: Date): number {
    const laterTimeMillis = laterDate.getTime();
    const earlierTimeMillis = earlierDate.getTime();

    // get total seconds between the times
    const delta = Math.abs(laterTimeMillis - earlierTimeMillis) / 1000;

    // calculate (and subtract) whole days
    return Math.floor(delta / 86400);
}

export function getEnumerationValueLabelKey(enumName: string, key: string) {
    return 'enum.' + upperCamelToLowerCamel(enumName) + '.' + underscoreToCamel(key.toLowerCase());
}

export function moneyValueToString(value: number) {
    return (Math.round(value * 100) / 100).toFixed(2);
}
