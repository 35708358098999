
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../framework/state';
import { getResources } from '../../../../framework/client/resource';
import { getTailoredVehicleModelImages } from '../../../client/vehicle_model_client';
import { TailoredVehicleModel } from '../../../../../common/application/model/TailoredVehicleModel';
import { ModelImage } from '../../../../../common/application/model/ModelImage';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';
import { ImageCategory } from '../../../../../common/application/model/ImageCategory';
import { getTailoredVehicleModelImage } from '../../../client/tailored_vehicle_model_client';

@Component
export default class SelectVehicle extends Vue {
    shared = sharedState;
    tailoredVehicleModels = [] as TailoredVehicleModel[];
    tailoredVehicleImages = new Map<string, ModelImage[]>();

    async mounted() {
        await this.loadAsyncData();
    }

    select(id: string) {
        this.$router.push('/orders/add/' + id);
    }

    back() {
        this.$router.go(-1);
    }

    getVehicleModelImage(vehicleModelId: string): string {
        const vehicleModelImages = this.tailoredVehicleImages.get(vehicleModelId);

        if (!vehicleModelImages) {
            return '';
        }

        const exteriorImage = vehicleModelImages.find((image) => image.category === ImageCategory.EXTERIOR);

        if (!exteriorImage) {
            return '';
        }

        return exteriorImage.blob || '';
    }

    private async loadAsyncData(): Promise<void> {
        const tailoredVehicleModels = await getResources<TailoredVehicleModel>(
            ApplicationResource.TAILORED_VEHICLE_MODEL,
            -1,
            new Map([['customerId', sharedState.context.customerId!!]]),
        );
        this.tailoredVehicleImages.clear();

        for (const vehicle of tailoredVehicleModels) {
            const images = await getTailoredVehicleModelImages(vehicle.id);
            const imagesExtended: ModelImage[] = [];

            for (const image of images) {
                imagesExtended.push({
                    ...image,
                    blob: await this.getImage(image.id),
                });
            }

            this.tailoredVehicleImages.set(vehicle.id, imagesExtended);
        }
        this.tailoredVehicleModels = tailoredVehicleModels;
    }

    private async getImage(imageId: string): Promise<string> {
        if (!imageId) {
            return '';
        }

        const image = await getTailoredVehicleModelImage(imageId);
        return URL.createObjectURL(image);
    }
}
