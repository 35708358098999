import { EngineContext } from '../model/EngineContext';
import { ViewMode } from '../render/ViewMode';
import { getGlobalState } from '../../../service/global_state_service';
import { FrameworkStateType } from '../../../../../common/framework/enumeration/FrameworkStateType';

const pressedKeys = new Map<String, boolean>();
const previousPressedKeys = new Map<String, boolean>();

export function startMoveService(context: EngineContext) {
    window.addEventListener('keyup', keyUp, false);
    window.addEventListener('keydown', keyDown, false);
}

export function stopMoveService(context: EngineContext) {
    window.removeEventListener('keyup', keyUp);
    window.removeEventListener('keydown', keyDown);
}

function wasKeyReleased(code: string) {
    return previousPressedKeys.has(code) && !pressedKeys.has(code);
}

function wasKeyPressed(code: string) {
    return !previousPressedKeys.has(code) && pressedKeys.has(code);
}

export function moveServiceRenderLoop(timeNowMillis: number, timeDeltaMillis: number, context: EngineContext) {
    if (pressedKeys.has('KeyW')) {
        //console.log("forward");
        context.renderer.moveCameraForward(timeDeltaMillis);
    }
    if (pressedKeys.has('KeyS')) {
        //console.log("backward");
        context.renderer.moveCameraBackward(timeDeltaMillis);
    }
    if (pressedKeys.has('KeyA')) {
        //console.log("left");
        context.renderer.moveCameraLeft(timeDeltaMillis);
    }
    if (pressedKeys.has('KeyD')) {
        //console.log("right");
        context.renderer.moveCameraRight(timeDeltaMillis);
    }
    if (pressedKeys.has('Space')) {
        //console.log("jump");
        context.renderer.jumpCamera(timeDeltaMillis);
    }

    if (pressedKeys.has('Space')) {
        //console.log("jump");
        context.renderer.jumpCamera(timeDeltaMillis);
    }

    if (wasKeyPressed('KeyF')) {
        if (context.renderer.viewMode === ViewMode.FLY) {
            context.renderer.setViewMode(ViewMode.WALK);
        } else {
            context.renderer.setViewMode(ViewMode.FLY);
        }
    }

    if (wasKeyPressed('KeyG')) {
        if (context.renderer.viewMode === ViewMode.GOD) {
            context.renderer.setViewMode(ViewMode.WALK);
        } else {
            context.renderer.setViewMode(ViewMode.GOD);
        }
    }

    context.renderer.translateCamera(timeDeltaMillis);

    updatePreviousPressedKeys();
}

function updatePreviousPressedKeys() {
    previousPressedKeys.clear();
    for (const key of Array.from(pressedKeys.keys())) {
        previousPressedKeys.set(key, pressedKeys.get(key)!!);
    }
}

function keyUp(event: KeyboardEvent) {
    const engineContext = getGlobalState<EngineContext>(FrameworkStateType.XR_ENGINE_CONTEXT);
    if (engineContext && !engineContext.state.inputFocus) {
        pressedKeys.delete(event.code);
    }
}

function keyDown(event: KeyboardEvent) {
    const engineContext = getGlobalState<EngineContext>(FrameworkStateType.XR_ENGINE_CONTEXT);
    if (engineContext && !engineContext.state.inputFocus) {
        pressedKeys.set(event.code, true);
    }
}
