import Space from '../../../../../common/framework/model/Space';
import { EngineContext } from '../model/EngineContext';
import { getResources } from '../../../client/resource';
import Entity from '../../../../../common/framework/model/Entity';
import { FrameworkResource } from '../../../../../common/framework/enumeration/FrameworkResource';
import { EngineEntity } from '../model/EngineEntity';
import { addEntities, removeEntities } from './entity_service';

export async function loadSpace(space: Space, context: EngineContext) {
    console.log('loading space: ' + space.name);
    const entities = await getResources<Entity>(FrameworkResource.ENTITY, -1, new Map([['spaceId', space.id]]));
    await addEntities(entities as EngineEntity[], context);
}

export async function clearSpace(context: EngineContext) {
    console.log('clearing space.');
    removeEntities(Array.from(context.state.entities.values()), context);
}
