
import { Component, Prop, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../framework/state';
import { PAGE_SIZE } from '../../../../../common/framework/constants';
import { deleteResource, getResources } from '../../../../framework/client/resource';
import { getGroupUsers, getGroupUsersPaging } from '../../../client/users';
import { UserIdentity } from '../../../../../common/framework/model/UserIdentity';
import { UserGroup } from '../../../../../common/framework/model/UserGroup';

@Component
export default class GroupMembers extends Vue {
    @Prop(String) readonly id!: string;

    shared = sharedState;
    local = {
        rows: new Array<UserIdentity>(),
        total: 0,
        loading: false,
        page: 1,
        perPage: PAGE_SIZE,
        selected: undefined as UserIdentity | undefined,
    };

    async mounted() {
        await this.loadAsyncData();
    }

    async onPageChange(page: number) {
        this.local.page = page;
        await this.loadAsyncData();
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    const userGroups = await getResources<UserGroup>(
                        'user-group',
                        -1,
                        new Map([
                            ['groupId', this.id],
                            ['userId', this.local.selected.id],
                        ]),
                    );
                    await deleteResource('user-group', userGroups[0].id);
                    await this.loadAsyncData();
                }
            },
        });
    }

    async loadAsyncData() {
        this.local.loading = true;
        this.local.total = (await getGroupUsersPaging(this.id)).pageCount * PAGE_SIZE;
        this.local.rows = [];
        const rows = (await getGroupUsers(this.id, this.local.page - 1)) as Array<UserIdentity>;
        rows.forEach((row) => {
            this.local.rows.push(row);
        });
        this.local.loading = false;
        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }
    }
}
