import { render, staticRenderFns } from "./AssetLinks.vue?vue&type=template&id=a3ae2eb4&scoped=true&"
import script from "./AssetLinks.vue?vue&type=script&lang=ts&"
export * from "./AssetLinks.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3ae2eb4",
  null
  
)

export default component.exports