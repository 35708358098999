
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { sharedState } from '../../../../framework/state';
import { getResource, getResources, putResource } from '../../../../framework/client/resource';
import OrganizationUnits from './OrganizationUnits.vue';
import TextField from '../../../../framework/fields/TextField.vue';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';
import { Organization } from '../../../../../common/application/model/Organization';
import { Customer } from '../../../../../common/application/model/Customer';

@Component({
    components: { TextField, OrganizationUnits, ValidationObserver },
})
export default class EditOrganization extends Vue {
    readonly resourceType = ApplicationResource.ORGANIZATION;

    @Prop(String) readonly id!: string;

    shared = sharedState;
    local = {
        row: {} as Organization,
        customerLabel: '',
        customers: new Map<string, Customer>(),
    };

    async mounted() {
        this.local.row = (await getResource(this.resourceType, this.id))!! as Organization;

        const customers = (await getResources(
            'customer',
            0,
            new Map([['id', this.local.row.customerId]]),
        )) as Array<Customer>;
        this.local.customers = new Map(customers.map((c) => [c.id, c] as any)) as Map<string, Customer>;

        const customer = this.local.customers.get(this.local.row.customerId)!!;
        this.local.customerLabel = customer.name;
    }

    async save() {
        await putResource(this.resourceType, this.id, this.local.row);
        this.$router.go(-1);
    }

    back() {
        this.$router.go(-1);
    }
}
