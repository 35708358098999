
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../../state';
import { postResource } from '../../../../../client/resource';
import TextField from '../../../../../fields/TextField.vue';
import SelectField from '../../../../../fields/SelectField.vue';
import Space from '../../../../../../../common/framework/model/Space';
import { SpaceType } from '../../../../../../../common/framework/model/SpaceType';
import { getEnumOptions } from '../../../../../service/options';
import NumberField from '../../../../../fields/NumberField.vue';
import { ValidationObserver } from 'vee-validate';
import AutoCompleteField from '../../../../../fields/AutoCompleteField.vue';
import SwitchField from '../../../../../fields/SwitchField.vue';
import MarkDownField from '../../../../../fields/MarkDownField.vue';

@Component({
    components: { MarkDownField, SwitchField, AutoCompleteField, SelectField, TextField, ValidationObserver },
})
export default class AddSpace extends Vue {
    // Constants
    readonly resourceType = 'space';

    // Properties

    // State
    shared = sharedState;
    local = {
        row: {
            id: '',
            public: false,
            created: undefined as any as Date,
            modified: undefined as any as Date,
        } as Space,
        typeOptions: [] as { id: string | undefined; label: string }[],
    };

    // Functions
    async mounted() {
        this.local.typeOptions = getEnumOptions('SpaceType', Object.keys(SpaceType)).map((o) => {
            return { id: o.id, label: this.$t(o.labelKey).toString() };
        });
    }

    async add() {
        const addedRow = await postResource<Space>(this.resourceType, this.local.row);
        this.back();
    }

    back() {
        this.$router.go(-1);
    }
}
