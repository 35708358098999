
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../framework/state';
import { postResource } from '../../../../framework/client/resource';
import { Group } from '../../../../../common/framework/model/Group';
import SwitchField from '../../../fields/SwitchField.vue';
import SelectField from '../../../fields/SelectField.vue';
import PhoneField from '../../../fields/PhoneField.vue';
import TextField from '../../../fields/TextField.vue';
import EmailField from '../../../fields/EmailField.vue';
import { ValidationObserver } from 'vee-validate';

@Component({
    components: { SwitchField, SelectField, PhoneField, TextField, EmailField, ValidationObserver },
})
export default class AddGroup extends Vue {
    readonly resourceType = 'group';

    shared = sharedState;
    local = {
        id: '',
        name: '',
        created: new Date(),
        modified: new Date(),
    };

    clear() {
        this.local.name = '';
    }

    async add() {
        const object: Group = Object.assign(
            {
                id: '',
                created: new Date(),
                modified: new Date(),
            },
            this.local,
        ) as any as Group;
        await postResource(this.resourceType, object);

        this.$router.go(-1);
        this.clear();
    }

    back() {
        this.$router.go(-1);
    }
}
