
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../framework/state';
import { PAGE_SIZE } from '../../../../../common/framework/constants';
import { ApiToken } from '../../../../../common/framework/model/ApiToken';
import { deleteApiToken, getApiTokens } from '../../../client/api_token_client';
import { dateToUiDateString } from '../../../../../common/framework/util/convert';

@Component
export default class ApiTokens extends Vue {
    readonly resourceType = 'api-token';

    shared = sharedState;
    local = {
        rows: new Array<ApiToken>(),
        total: 0,
        loading: false,
        page: 1,
        perPage: PAGE_SIZE,
        selected: undefined as ApiToken | undefined,
    };

    async mounted() {
        await this.loadAsyncData();
    }

    async onPageChange(page: number) {
        this.local.page = page;
        await this.loadAsyncData();
    }

    add() {
        this.$router.push('/api-token/add');
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    await deleteApiToken(this.local.selected.id);
                    await this.loadAsyncData();
                }
            },
        });
    }

    async loadAsyncData() {
        this.local.loading = true;
        this.local.rows = [];
        const rows = await getApiTokens();
        rows.forEach((row) => {
            this.local.rows.push(row);
        });
        this.local.loading = false;
        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }
    }

    formatDate(date: Date) {
        console.log(date);
        return dateToUiDateString(date);
    }
}
