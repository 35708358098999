import WebXRPolyfill from 'webxr-polyfill';
import { FrameworkFeature } from '../../../../common/framework/enumeration/FrameworkFeature';
import { Feature } from '../../../../common/framework/enumeration/Feature';
import { FrameworkModule } from '../../../../common/framework/enumeration/FrameworkModule';
import { ModuleType } from '../../../../common/framework/enumeration/ModuleType';
import { Module } from '../../../../common/framework/model/Module';
import { registerXrNavigation } from './xr_navigation';
import { UiModule } from '../../../../common/framework/model/UiModule';

export class XrUiModule implements UiModule {
    get type(): ModuleType {
        return FrameworkModule.XR_UI;
    }

    get features(): Array<Feature> {
        return [FrameworkFeature.XR];
    }

    async initialize() {
        //const polyfill = new WebXRPolyfill();
        await registerXrNavigation();
    }

    async dispose() {}

    async onLoggedIn() {}

    async onLoggedOut() {}
}
