
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../framework/state';
import { getFieldNameFromVModelProperty } from '../util/component_util';
import { ValidationProvider } from 'vee-validate';

@Component({
    components: { ValidationProvider },
})
export default class SelectField extends Vue {
    @Prop(String) readonly name!: string;
    @Prop(String) readonly value!: string;
    @Prop(String) readonly labelPosition!: string;
    @Prop(String) readonly readonlyClass!: string;
    @Prop(Array) readonly options!: { id: string | undefined; label: string }[];
    @Prop(Boolean) readonly disabled!: string;
    @Prop(Boolean) readonly readonly!: string;
    @Prop(Boolean) readonly required!: boolean;
    @Prop(Boolean) readonly horizontal!: boolean;
    @Prop(Boolean) readonly expanded!: boolean;

    shared = sharedState;
    local = {
        value: undefined as string | undefined,
    };

    async mounted() {
        await this.modelValueChange(this.value);
    }

    @Watch('value')
    async modelValueChange(newValue: string) {
        this.local.value = newValue;
    }

    @Watch('local.value')
    async inputValueChange(newValue: string) {
        this.$emit('input', newValue);
    }

    getLabel(id: string) {
        if (this.options === undefined) {
            return '';
        }
        const filtered = this.options.filter((o) => o.id === id);
        if (filtered.length > 0) {
            return filtered[0].label;
        } else {
            return '';
        }
    }

    getFieldName() {
        return getFieldNameFromVModelProperty(this);
    }
}
