
import { Component, Prop, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../framework/state';
import { getResource, getResources } from '../../../../framework/client/resource';
import BField from 'buefy/src/components/field/Field.vue';
import {
    getAllUnitOptions,
    getCustomerOptions,
    getDealerOptions,
    getFinancingModelOptions,
} from '../../../service/options';
import { getSalesPerson, getOrdererPerson } from '../../../client/order';
import { sumRetrofittedAccessoryNetPrice } from '../../../service/pricing';
import { dateToFinnishUiDateString } from '../../../../../common/framework/util/convert';
import { TailoredVehicleModel } from '../../../../../common/application/model/TailoredVehicleModel';
import { Vehicle } from '../../../../../common/application/model/Vehicle';
import { TailoredFactoryAccessory } from '../../../../../common/application/model/TailoredFactoryAccessory';
import { TailoredRetrofittedAccessory } from '../../../../../common/application/model/TailoredRetrofittedAccessory';
import { OrderRetrofittedAccessory } from '../../../../../common/application/model/OrderRetrofittedAccessory';
import { OrderFactoryAccessory } from '../../../../../common/application/model/OrderFactoryAccessory';
import { Order } from '../../../../../common/application/model/Order';
import { getOptionLabel, getOptionLabelKey } from '../../../../framework/service/options';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';
import { Person } from '../../../../../common/application/model/Person';

@Component({
    components: { BField },
})
export default class OrderDetails extends Vue {
    shared = sharedState;
    local = {
        order: undefined as Order | undefined,
        tailoredVehicleModel: undefined as TailoredVehicleModel | undefined,
        vehicles: [] as Vehicle[],
        salesPerson: undefined as Person | undefined,
        orderPerson: undefined as Person | undefined,
        tailoredFactoryAccessories: [] as TailoredFactoryAccessory[],
        tailoredRetrofittedAccessories: [] as TailoredRetrofittedAccessory[],
        orderFactoryAccessories: [] as OrderFactoryAccessory[],
        orderRetrofittedAccessories: [] as OrderRetrofittedAccessory[],
        selectedVehicle: undefined as Vehicle | undefined,
    };

    @Prop(String) readonly id!: string;

    customerOptions = [{ id: undefined as string | undefined, label: '' }];
    unitOptions = [{ id: undefined as string | undefined, label: '' }];
    financingModelOptions = [{ id: undefined as string | undefined, labelKey: '' }];
    dealerOptions = [{ id: undefined as string | undefined, label: '' }];

    async mounted() {
        this.financingModelOptions = getFinancingModelOptions();
        this.dealerOptions = await getDealerOptions();
        this.local.order = await getResource<Order>('order', this.id);
        this.local.vehicles = await getResources<Vehicle>('vehicle', -1, new Map([['orderId', this.id]]));
        this.local.tailoredVehicleModel = await getResource<TailoredVehicleModel>(
            ApplicationResource.TAILORED_VEHICLE_MODEL,
            this.local.order!!.tailoredVehicleModelId,
        );
        this.local.tailoredFactoryAccessories = await getResources<TailoredFactoryAccessory>(
            ApplicationResource.TAILORED_FACTORY_ACCESSORY,
            -1,
            new Map([['tailoredVehicleModelId', this.local.order!!.tailoredVehicleModelId]]),
        );
        this.local.tailoredRetrofittedAccessories = await getResources<TailoredRetrofittedAccessory>(
            ApplicationResource.TAILORED_RETROFITTED_ACCESSORY,
            -1,
            new Map([['tailoredVehicleModelId', this.local.order!!.tailoredVehicleModelId]]),
        );
        this.local.orderFactoryAccessories = await getResources<OrderFactoryAccessory>(
            ApplicationResource.ORDER_FACTORY_ACCESSORY,
            -1,
            new Map([['orderId', this.id]]),
        );
        this.local.orderRetrofittedAccessories = await getResources<OrderRetrofittedAccessory>(
            ApplicationResource.ORDER_RETROFITTED_ACCESSORY,
            -1,
            new Map([['orderId', this.id]]),
        );
        this.customerOptions = await getCustomerOptions();
        this.unitOptions = await getAllUnitOptions();
        if (this.local.order!!.salesPersonId) {
            this.local.salesPerson = await getSalesPerson(this.local.order!!.salesPersonId);
        }
        this.local.orderPerson = await getOrdererPerson(this.local.order!!.orderPersonId);
    }

    getLabelKey(id: string, options: { id: string | undefined; labelKey: string }[]): string {
        return getOptionLabelKey(id, options);
    }

    getFactoryAccessory(accessory: OrderFactoryAccessory): TailoredFactoryAccessory {
        return this.local.tailoredFactoryAccessories!!.filter((a) => a.id === accessory.tailoredFactoryAccessoryId)[0];
    }

    getRetrofittedAccessory(accessory: OrderRetrofittedAccessory): TailoredRetrofittedAccessory {
        return this.local.tailoredRetrofittedAccessories!!.filter(
            (a) => a.id === accessory.tailoredRetrofittedAccessoryId,
        )[0];
    }

    sumRetrofittedAccessoryPrices(accessories: TailoredRetrofittedAccessory[]) {
        const accessoryIds = this.local.orderRetrofittedAccessories!!.map((o) => o.tailoredRetrofittedAccessoryId);
        return sumRetrofittedAccessoryNetPrice(accessories.filter((a) => accessoryIds.indexOf(a.id) !== -1));
    }

    async edit(id?: string) {
        if (this.local.selectedVehicle) {
            await this.$router.push('/vehicles/edit/' + this.local.selectedVehicle.id);
        } else if (id) {
            await this.$router.push('/vehicles/edit/' + id);
        }
    }

    getLabel(id: string, options: { id: string | undefined; label: string }[]) {
        return getOptionLabel(id, options);
    }

    onRowClick(row: Vehicle) {
        if (this.local.selectedVehicle && row.id === this.local.selectedVehicle.id) {
            this.local.selectedVehicle = undefined;
        }
    }

    formatDate(date: Date): string {
        return dateToFinnishUiDateString(date);
    }
}
