import { postLogin, postLogout } from '../client/login';
import router from '../../Router';
import { frameworkLoggedIn, frameworkLoggedOut } from '../framework_hooks';
import { applicationLoggedIn, applicationLoggedOut } from '../../application/application_hooks';
import { invokeUiModuleOnLoggedIn, invokeUiModuleOnLoggedOut } from '../../../common/framework/service/module_service';

export async function passwordLogin(email: string, password: string): Promise<boolean> {
    const result = await postLogin(email, password);
    await frameworkLoggedIn();
    await applicationLoggedIn();
    await invokeUiModuleOnLoggedIn();
    return result;
}

export async function logout() {
    console.log('logging out and clearing session storage.');
    await postLogout(router);
    await frameworkLoggedOut();
    await applicationLoggedOut();
    await invokeUiModuleOnLoggedOut();
}
