import { EntityMoveMessage } from '../../../../../common/framework/module/xr/network/model/EntityMoveMessage';
import { EngineContext } from '../model/EngineContext';
import { getResource } from '../../../client/resource';
import Entity from '../../../../../common/framework/model/Entity';
import { FrameworkResource } from '../../../../../common/framework/enumeration/FrameworkResource';
import { EntityDeleteMessage } from '../../../../../common/framework/module/xr/network/model/EntityDeleteMessage';
import { EngineEntity } from '../model/EngineEntity';
import { EntityMove } from '../../../../../common/framework/module/xr/network/model/EntityMove';
import Asset from '../../../../../common/framework/model/Asset';
import { getAssetBinaryMeta } from '../../../client/asset_binary_client';
import { AssetBinaryType } from '../../../../../common/framework/model/AssetBinaryType';
import { AssetType } from '../../../../../common/framework/model/AssetType';
import { getGlobalState } from '../../../service/global_state_service';
import { FrameworkStateType } from '../../../../../common/framework/enumeration/FrameworkStateType';
import { MessageType } from '../../../../../common/framework/module/xr/network/model/MessageType';

export async function addEntities(entities: EngineEntity[], context: EngineContext) {
    console.log('add ' + entities.length + ' entities begin ...');
    const assetIds = entities.map((e) => e.assetId);
    for (const assetId of assetIds) {
        if (context.state.assets.has(assetId)) {
            //console.log('Asset info already loaded: ' + assetId);
            continue;
        }

        const asset = await getResource<Asset>(FrameworkResource.ASSET, assetId);
        const assetBinaryMeta = await getAssetBinaryMeta(assetId, AssetBinaryType.ORIGINAL);
        if (!asset || !assetBinaryMeta) {
            console.log('Asset info loading failed: ' + assetId);
            continue;
        }

        context.state.assets.set(asset.id, { asset: asset, assetBinaryMeta });

        if (asset.type === AssetType.MODEL) {
            //console.log('Loading model: ' + asset.name + ' (' + asset.id + ')');
            await context.renderer.loadModel(
                asset.id,
                asset.category,
                asset.name,
                asset.description,
                '/api/asset/',
                assetBinaryMeta.id + '_' + asset.name,
            );
        }
    }

    let hasStaticEntities = false;
    for (const entity of entities) {
        context.state.entities.set(entity.id, entity);
        await context.renderer.addEntity(entity);
        if (!entity.dynamic) {
            hasStaticEntities = true;
        }
    }

    if (hasStaticEntities) {
        await context.renderer.buildRayIntersectBvh();
    }

    console.log('add ' + entities.length + ' entities done.');
}

export function removeEntities(entities: EngineEntity[], context: EngineContext): void {
    for (const entity of entities) {
        context.renderer.removeEntity(entity);
        context.state.entities.delete(entity.id);
    }
}

export async function onEntityMove(messageType: MessageType, message: EntityMoveMessage) {
    const context = getGlobalState<EngineContext>(FrameworkStateType.XR_ENGINE_CONTEXT);
    const nowMillis = new Date().getTime();
    for (const entityMove of message.Moves) {
        if (context.state.ownEntityIds.has(entityMove.Id)) {
            continue; // Ignore own entities as they are translated locally.
        }
        if (!context.state.entities.has(entityMove.Id)) {
            if (!context.state.loadingEntityIds.has(entityMove.Id)) {
                context.state.loadingEntityIds.set(entityMove.Id, new Date());
                //console.log("loading entity begin: " + entityMove.Id);
                const entity = (await getResource<Entity>(FrameworkResource.ENTITY, entityMove.Id)) as
                    | EngineEntity
                    | undefined;
                if (entity) {
                    entity.dynamic = true;
                    applyEntityMove(entity, entityMove, nowMillis);
                    await addEntities([entity], context);
                    context.state.loadingEntityIds.delete(entityMove.Id);
                    //console.log("loading entity end: " + entity.id + " : " + entity.name + " (" + message.UserId + ")");
                }
            } else {
                //console.log("ignoring move of loading entity: " + entityMove.Id);
            }
        }
        if (!context.state.entities.has(entityMove.Id)) {
            //console.log("Ignoring entity move message. Unable to load entity: " + entityMove.Id);
        } else {
            const entity = context.state.entities.get(entityMove.Id)!!;
            applyEntityMove(entity, entityMove, nowMillis);
        }
    }
}

function applyEntityMove(entity: EngineEntity, entityMove: EntityMove, nowMillis: number) {
    entity.x = entityMove.X / 100;
    entity.y = entityMove.Y / 100;
    entity.z = entityMove.Z / 100;
    entity.rx = entityMove.RX / 1000;
    entity.ry = entityMove.RY / 1000;
    entity.rz = entityMove.RZ / 1000;
    entity.rw = entityMove.RW / 1000;
    entity.lastMovedMillis = nowMillis;
}

export async function handleEntityDelete(entityDeleteMessage: EntityDeleteMessage, context: EngineContext) {
    if (context.state.entities.has(entityDeleteMessage.Id)) {
        removeEntities([context.state.entities.get(entityDeleteMessage.Id)!!], context);
    }
}
