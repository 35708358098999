
import { Component, Vue, Watch } from 'vue-property-decorator';
import { isNil } from 'lodash';

import { sharedState } from '../../../../state';
import Space from '../../../../../../common/framework/model/Space';
import { startEngineService, stopEngineService } from '../../service/engine_service';
import { EngineContext } from '../../model/EngineContext';
import { EngineState } from '../../model/EngineState';
import { EngineEntity } from '../../model/EngineEntity';
import { getProfile } from '../../../../client/profile';
import Entity from '../../../../../../common/framework/model/Entity';
import { getResource } from '../../../../client/resource';
import { FrameworkResource } from '../../../../../../common/framework/enumeration/FrameworkResource';
import { loadSpace } from '../../service/space_service';
import { initializeNetwork } from '../../../communication/service/network_service';
import { NexusClient } from '../../../../../../common/framework/module/xr/network/NexusClient';
import { SceneRenderer } from '../../render/SceneRenderer';
import { BabylonSceneRenderer } from '../../render/BabylonSceneRenderer';
import { addGlobalStateSetListener, removeGlobalStateSetListener } from '../../../../service/global_state_service';
import { FrameworkStateType } from '../../../../../../common/framework/enumeration/FrameworkStateType';
import CommunicationView from './CommunicationView.vue';
import { StateType } from '../../../../../../common/framework/enumeration/StateType';
import { SpaceState } from '../../../space/SpaceState';
import SpaceUsersView from './SpaceUsersView.vue';

@Component({
    components: { SpaceUsersView, CommunicationView },
})
export default class SpaceRenderView extends Vue {
    shared = sharedState;
    local = {
        renderCanvasClass: 'render-canvas disable-mobile-select',
        renderer: undefined as SceneRenderer | undefined,
        space: undefined as undefined | Space,
        context: undefined as EngineContext | undefined,

        canvasHeight: 0,
        sideContainerHeight: 300,
        bottomContainerHeight: 300,
    };

    renderer: SceneRenderer | undefined;
    context: EngineContext | undefined;
    canvas: undefined | HTMLCanvasElement;

    async mounted() {
        try {
            this.local.space = undefined;
            this.canvas = (document.getElementById('render-canvas') as HTMLCanvasElement)!!;
            document.onkeydown = async (evt) => {
                evt = evt || window.event;
                var isEscape = false;
                if ('key' in evt) {
                    isEscape = evt.key === 'Escape' || evt.key === 'Esc';
                } else {
                    isEscape = (evt as any).keyCode === 27;
                }
                if (isEscape) {
                    await this.setFullScreen(false);
                }
            };
            document.onfullscreenchange = async (e) => {
                if (!document.fullscreenElement) {
                    await this.setFullScreen(false);
                } else {
                    (document.fullscreenElement as HTMLElement).focus();
                    await this.setFullScreen(true);
                }
            };
            window.scrollTo(0, 0);

            const profile = sharedState.authenticated ? await getProfile() : undefined;
            const avatarHead =
                profile && profile.avatarHeadEntityId
                    ? (await getResource<Entity>(FrameworkResource.ENTITY, profile.avatarHeadEntityId!!))!!
                    : undefined;
            const avatarLeftHand =
                profile && profile.avatarLeftHandEntityId
                    ? (await getResource<Entity>(FrameworkResource.ENTITY, profile.avatarLeftHandEntityId!!))!!
                    : undefined;
            const avatarRightHand =
                profile && profile.avatarRightHandEntityId
                    ? (await getResource<Entity>(FrameworkResource.ENTITY, profile.avatarRightHandEntityId!!))!!
                    : undefined;
            const avatarTorso =
                profile && profile.avatarTorsoEntityId
                    ? (await getResource<Entity>(FrameworkResource.ENTITY, profile.avatarTorsoEntityId!!))!!
                    : undefined;
            const ownEntityIds = new Set<string>();
            if (avatarHead) {
                ownEntityIds.add(avatarHead.id);
            }
            if (avatarLeftHand) {
                ownEntityIds.add(avatarLeftHand.id);
            }
            if (avatarRightHand) {
                ownEntityIds.add(avatarRightHand.id);
            }
            if (avatarTorso) {
                ownEntityIds.add(avatarTorso.id);
            }

            const state: EngineState = {
                userId: sharedState.context.userId,
                authenticationToken: sharedState.context.idToken,
                inputFocus: false,
                engineTimer: undefined,
                engineRunning: false,
                lastNexusConnect: undefined,
                avatarHead: avatarHead as EngineEntity,
                avatarLeftHand: avatarLeftHand as EngineEntity,
                avatarRightHand: avatarRightHand as EngineEntity,
                avatarTorso: avatarTorso as EngineEntity,
                ownEntityIds: ownEntityIds,
                entities: new Map<string, EngineEntity>(),
                assets: new Map(),
                loadingEntityIds: new Map(),
            };

            let context = {
                state: state,
                renderer: undefined as SceneRenderer | undefined,
                client: undefined as NexusClient | undefined,
            };

            context.renderer = new BabylonSceneRenderer(this.canvas!!, context as EngineContext);
            context.renderer.showLoadingUI();
            if (sharedState.authenticated) {
                context.client = await initializeNetwork();
            }

            this.context = context as EngineContext;
            this.local.context = context as EngineContext;
            this.renderer = context.renderer;
            this.local.renderer = context.renderer;

            await this.renderer.startRenderer();
            await startEngineService(this.context);

            await addGlobalStateSetListener(FrameworkStateType.SPACE_STATE, this.onSpaceSet);
            context.renderer.hideLoadingUI();
        } catch (e: any) {
            console.error('Error in space render view mount.', e);
        }
        window.addEventListener('resize', this.resized);
        this.resized();
    }

    resized = () => {
        const div = document.getElementById('render-canvas')!!;
        if (div) {
            this.local.canvasHeight = parseInt(window.getComputedStyle(div).height!!);
            const uiHeight = this.local.canvasHeight - 130;
            this.local.bottomContainerHeight = uiHeight * 0.2;
            this.local.sideContainerHeight = uiHeight * 0.7;
        }
    };

    async destroyed() {
        window.removeEventListener('resize', this.resized);
        removeGlobalStateSetListener(FrameworkStateType.SPACE_STATE, this.onSpaceSet);
        if (this.context) {
            await stopEngineService(this.context);
        }
        if (this.renderer) {
            this.renderer.stopRenderer();
        }
        document.onkeydown = null;
        document.onfullscreenchange = null;
        window.onbeforeunload = null;
    }

    async onSpaceSet(type: StateType, spaceState: SpaceState) {
        const space = spaceState.space;
        if (this.local.space !== space && !isNil(this.context)) {
            if (this.local.space) {
                return; // Space already set.
            }
            this.local.space = space;
            if (this.local.space) {
                await loadSpace(this.local.space, this.context!!);
            }
        }
    }

    async setFullScreen(fullScreen: boolean) {
        if (fullScreen) {
            this.local.renderCanvasClass = 'render-canvas-overlay disable-mobile-select';
        } else {
            this.local.renderCanvasClass = 'render-canvas disable-mobile-select';
        }

        if (this.renderer) {
            this.renderer!!.setFullScreen(fullScreen);
        }
    }

    @Watch('local.value')
    async inputValueChange(newValue: string) {
        this.$emit('input', newValue);
    }

    back() {
        this.$router.go(-1);
    }

    async enterFullScreen() {
        if (!document.fullscreenElement) {
            await this.canvas!.requestFullscreen();
        }
    }

    async enterVr() {
        if (this.renderer) {
            await this.renderer.setVR(true);
        }
    }
}
