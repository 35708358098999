
import { Component, Prop, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../state';
import { getResource, putResource } from '../../../client/resource';
import TextField from '../../../fields/TextField.vue';
import SelectField from '../../../fields/SelectField.vue';
import { ValidationObserver } from 'vee-validate';
import AutoCompleteField from '../../../fields/AutoCompleteField.vue';
import SwitchField from '../../../fields/SwitchField.vue';
import TextAreaField from '../../../fields/TextAreaField.vue';
import NumberField from '../../../fields/NumberField.vue';
import { Option } from '../../../../../common/framework/model/Option';
import DefaultOptionJsonDataField from '../../../fields/DefaultOptionJsonDataField.vue';

@Component({
    components: {
        SwitchField,
        SelectField,
        TextField,
        AutoCompleteField,
        NumberField,
        TextAreaField,
        DefaultOptionJsonDataField,
        ValidationObserver,
    },
})
export default class EditOption extends Vue {
    // Constants
    readonly resourceType = 'option';

    // Properties
    @Prop(String) readonly id!: string;

    // State
    shared = sharedState;
    local = {
        row: {} as Option,
        typeOptions: [] as { id: string | undefined; label: string }[],
    };

    // Functions
    async mounted() {
        this.local.row = (await getResource<Option>(this.resourceType, this.id))!!;
    }

    async save() {
        await putResource(this.resourceType, this.id, this.local.row);
        this.back();
    }

    back() {
        this.$router.go(-1);
    }
}
