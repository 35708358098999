
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import { Loader } from '@googlemaps/js-api-loader';

import { sharedState } from '../../../../framework/state';
import { getResource, getResources } from '../../../../framework/client/resource';
import { postOrderAddRequest } from '../../../client/order';

import { getAllUnitOptions, getSalesPersonOptions } from '../../../service/options';
import { sumRetrofittedAccessoryNetPrice } from '../../../service/pricing';
import { getTailoredVehicleModelImages } from '../../../client/vehicle_model_client';
import {
    getRetrofittedAccessoryImage,
    getRetrofittedAccessoryImages,
} from '../../../client/retrofitted_accessory_client';
import { getProfile } from '../../../../framework/client/profile';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';
import { TailoredRetrofittedAccessory } from '../../../../../common/application/model/TailoredRetrofittedAccessory';
import { Dealer } from '../../../../../common/application/model/Dealer';
import { TailoredVehicleModel } from '../../../../../common/application/model/TailoredVehicleModel';
import { CustomerDealer } from '../../../../../common/application/model/CustomerDealer';
import { AccessoryImageCategory } from '../../../../../common/application/model/AccessoryImageCategory';
import { ImageCategory } from '../../../../../common/application/model/ImageCategory';
import { OrderAddRequest } from '../../../../../common/application/model/OrderAddRequest';
import { getEnumerationOptions, OptionValue } from '../../../../framework/service/options';
import TextField from '../../../../framework/fields/TextField.vue';
import DateField from '../../../../framework/fields/DateField.vue';
import SelectField from '../../../../framework/fields/SelectField.vue';
import PhoneField from '../../../../framework/fields/PhoneField.vue';
import EmailField from '../../../../framework/fields/EmailField.vue';
import TextAreaField from '../../../../framework/fields/TextAreaField.vue';
import NumberField from '../../../../framework/fields/NumberField.vue';
import { getTailoredVehicleModelImage } from '../../../client/tailored_vehicle_model_client';
import { FinancingModel } from '../../../../../common/application/model/FinancingModel';

@Component({
    components: {
        NumberField,
        EmailField,
        PhoneField,
        SelectField,
        TextField,
        DateField,
        TextAreaField,
        ValidationObserver,
    },
})
export default class AddOrder extends Vue {
    readonly resourceType = ApplicationResource.TAILORED_VEHICLE_MODEL;
    @Prop(String) readonly id!: string;

    shared = sharedState;
    local = {
        row: {
            additionalDetails: '',
            dealerId: '',
            financingModel: 'notDefined',
            financingCompany: '',
            requestedDeliveryDate: new Date(),
            salesPersonId: '',
            holdingTime: 24,
            mileagePerMonth: 2000,
            customerReference: '',
        } as any as OrderAddRequest,
        selectedVehicle: {} as TailoredVehicleModel,
        exteriorImageObjectUrl: '',
        interiorImageObjectUrl: '',
        loading: false,
        map: {} as google.maps.Map,
        financingModelOptions: [] as OptionValue[],
        retrofittedAccessoryImageObjectUrls: new Map<string, string>(),
        dealerMarker: undefined as any,
        factoryAccessories: [] as any[],
        factoryAccessoryIds: [] as string[],
        retrofittedAccessories: [] as TailoredRetrofittedAccessory[],
        retrofittedAccessoryIds: [] as string[],
        retrofittedAccessoriesNetPrice: 0,
    };

    unitOptions = [] as OptionValue[];
    salesPersonOptions = [] as OptionValue[];

    user: any = null;
    replacesVehicle = false;
    replaceableVehicle = {
        ownership: 'notDefined',
        leaseEnd: new Date(),
        licensePlate: null,
        makeModel: null,
        financeCompany: null,
    };
    myLatLng = { lat: 60.2803332, lng: 24.8165942 };
    dealers: Dealer[] = [];
    typedDealerName = '';
    dealer: Dealer = undefined as any as Dealer;

    async mounted() {
        await this.loadAsyncData();
        this.local.financingModelOptions = getEnumerationOptions('FinancingModel', Object.keys(FinancingModel), this);

        this.local.row = {
            ...this.local.row,
            unitId: sharedState.context.unitId ? sharedState.context.unitId : '',
        };
    }

    @Watch('local.row.dealerId')
    public async onDealerIdChange(newDealerId: string) {
        this.salesPersonOptions = [];
        for (const dealer of this.dealers) {
            if (dealer.id === newDealerId) {
                this.setDealerLocation(dealer);
                this.dealer = dealer;
                this.salesPersonOptions = await getSalesPersonOptions(dealer.id);
            }
        }
        if (!newDealerId) {
            this.local.dealerMarker.setMap(null);
        }
    }

    @Watch('local.retrofittedAccessoryIds')
    public async onRetrofittedAccessoriesChange(retrofittedAccessoryIds: string[]) {
        this.local.retrofittedAccessoriesNetPrice = sumRetrofittedAccessoryNetPrice(
            this.local.retrofittedAccessories.filter((a) => retrofittedAccessoryIds.indexOf(a.id) !== -1),
        );
    }

    async submit() {
        this.local.row.requestedDeliveryDate.setUTCHours(24, 0, 0, 0);

        await postOrderAddRequest({
            ...this.local.row,
            customerId: this.local.selectedVehicle.customerId,
            orderPersonId: this.shared.context.userId,
            projectedDeliveryDate: this.local.row.requestedDeliveryDate,
            replaceableVehicleOwnership: this.replaceableVehicle.ownership,
            replaceableVehicleLeaseEnd: this.replaceableVehicle.leaseEnd,
            replaceableVehicleLicensePlate: this.replaceableVehicle.licensePlate,
            replaceableVehicleMakeModel: this.replaceableVehicle.makeModel,
            replaceableVehicleFinanceCompany: this.replaceableVehicle.financeCompany,
            tailoredFactoryAccessoryIds: this.local.factoryAccessoryIds,
            tailoredRetrofittedAccessoryIds: this.local.retrofittedAccessoryIds,
            tailoredVehicleModelId: this.local.selectedVehicle.id,
            userFirstName: this.user.firstName,
            userLastName: this.user.lastName,
            userPhone: this.user.phone,
        });

        await this.$router.push('/orders');
    }

    setDealerLocation(dealer: Dealer) {
        const geocoder = new google.maps.Geocoder();
        const address = `${dealer.visitingAddressStreet}, ${dealer.visitingAddressPostalCode} ${dealer.visitingAddressMunicipality}, ${dealer.visitingAddressCountryCode}`;
        geocoder.geocode({ address: address }, (results: any, status: any) => {
            if (status == 'OK') {
                this.local.dealerMarker.setMap(this.local.map);
                this.local.map.setCenter(results[0].geometry.location);
                this.local.dealerMarker.setPosition(results[0].geometry.location);
                this.local.dealerMarker.setAnimation(google.maps.Animation.BOUNCE);

                setTimeout(() => {
                    this.local.dealerMarker.setAnimation(null);
                }, 750);
            } else {
                this.local.dealerMarker.setMap(null);
                console.error('Geocode was not successful for the following reason: ' + status);
                this.local.map.setCenter(this.myLatLng);
            }
        });
    }

    get filteredDealers() {
        return this.dealers
            .filter((dealer) => !!dealer.id)
            .filter((dealer) => {
                return (
                    dealer.name!!.toString().toLowerCase().indexOf(this.typedDealerName.toLowerCase()) != -1 ||
                    (dealer.customerNumber &&
                        dealer.customerNumber!!.toString().toLowerCase().indexOf(this.typedDealerName.toLowerCase()) !=
                            -1)
                );
            });
    }

    back() {
        this.$router.go(-1);
    }

    setDealerId(option: OptionValue): void {
        this.local.row = {
            ...this.local.row,
            dealerId: option.id || '',
        };
    }

    private async loadAsyncData(): Promise<void> {
        this.local.loading = true;

        this.user = await getProfile();
        this.unitOptions = await getAllUnitOptions();
        this.local.selectedVehicle = (await getResource<TailoredVehicleModel>(this.resourceType, this.id))!!;

        const customerDealersIds = (
            await getResources<CustomerDealer>(
                ApplicationResource.CUSTOMER_DEALER,
                -1,
                new Map([['customerId', this.local.selectedVehicle.customerId]]),
            )
        ).map(({ dealerId }) => dealerId);

        this.dealers = ((await getResources(ApplicationResource.DEALER, -1)) as Dealer[]).filter(
            (d) => customerDealersIds.indexOf(d.id) !== -1,
        );

        this.local.retrofittedAccessories = (await getResources(
            ApplicationResource.TAILORED_RETROFITTED_ACCESSORY,
            -1,
            new Map([['tailoredVehicleModelId', this.local.selectedVehicle.id]]),
        )) as Array<any>;

        for (const retrofittedAccessory of this.local.retrofittedAccessories) {
            if (!retrofittedAccessory.externalId || retrofittedAccessory.externalId.length !== 36) {
                continue;
            }
            const images = await getRetrofittedAccessoryImages(retrofittedAccessory.externalId);

            for (const image of images) {
                if (image.category === AccessoryImageCategory.PRIMARY) {
                    const imageBlob = await getRetrofittedAccessoryImage(image.id);
                    this.local.retrofittedAccessoryImageObjectUrls.set(
                        retrofittedAccessory.id,
                        URL.createObjectURL(imageBlob),
                    );
                }
            }
        }

        this.local.factoryAccessories = (await getResources(
            ApplicationResource.TAILORED_FACTORY_ACCESSORY,
            -1,
            new Map([['tailoredVehicleModelId', this.local.selectedVehicle.id]]),
        )) as Array<any>;
        this.local.factoryAccessoryIds = this.local.factoryAccessories.map(({ id }) => id);

        try {
            await this.initMap();
        } catch (error) {
            console.error(error);
        }

        const images = await getTailoredVehicleModelImages(this.id);
        for (const image of images) {
            if (image.category === ImageCategory.EXTERIOR) {
                this.local.exteriorImageObjectUrl = await this.getImage(image.id);
            }
            if (image.category === ImageCategory.INTERIOR) {
                this.local.interiorImageObjectUrl = await this.getImage(image.id);
            }
        }

        this.local.loading = false;
    }

    private async getImage(imageId: string): Promise<string> {
        if (!imageId) {
            return '';
        }

        const image = await getTailoredVehicleModelImage(imageId);
        return URL.createObjectURL(image);
    }

    private initMap(): void {
        AddOrder.loader
            .importLibrary('maps')
            .then(() => {
                this.local.map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
                    center: { lat: 60.293761, lng: 24.9657867 },
                    zoom: 10,
                });

                this.local.map.setCenter(this.myLatLng);
                this.local.map.setZoom(14);
                this.local.dealerMarker = new google.maps.Marker();
            })
            .catch((error: any) => {
                console.error(`Error loading Google Maps: ${error.toString()}`);
            });
    }

    private static get loader(): Loader {
        return new Loader({
            apiKey: 'AIzaSyD3X2dXxNfl348ipkZ7ft7VMXaHEgS6x4A',
            version: 'weekly',
        });
    }
}
