import { apiDelete, apiGet, apiPost, apiPut } from './index';
import { PagingInfo } from '../../../common/framework/model/PagingInfo';
import { ERROR_API_CALL_FAILED } from '../../../common/framework/constants';

export async function getResource<T>(resourceType: string, id: string): Promise<T | undefined> {
    const response = await apiGet('/api/resource/' + resourceType + '/' + id);
    if (response.status === 200) {
        return (await response.json()) as T;
    } else if (response.status === 404) {
        return undefined;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getResourcePaging(resourceType: string, parameters?: Map<string, string>): Promise<PagingInfo> {
    const queryParameters = parameters
        ? '?' + Array.from(parameters, ([key, value]) => encodeURI(key) + '=' + encodeURI(value)).join('&')
        : '';

    const response = await apiGet('/api/resource/' + resourceType + '/paging' + queryParameters);
    if (response.status === 200) {
        return (await response.json()) as PagingInfo;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getResources<T>(
    resourceType: string,
    page: number = -1,
    parameters?: Map<string, string>,
): Promise<T[]> {
    const queryParameters = parameters
        ? '?' + Array.from(parameters, ([key, value]) => encodeURI(key) + '=' + encodeURI(value)).join('&')
        : '';

    const response = await apiGet('/api/resource/' + resourceType + '' + queryParameters, { page });
    if (response.status === 200) {
        return (await response.json()) as T[];
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function deleteResource(resourceType: string, id: string): Promise<void> {
    const response = await apiDelete('/api/resource/' + resourceType + '/' + id);
    if (response.status === 200) {
        return;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function postResource<T>(resourceType: string, resource: T): Promise<T> {
    const response = await apiPost('/api/resource/' + resourceType + '', resource);
    if (response.status === 200) {
        return (await response.json()) as T;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function putResource<T>(resourceType: string, id: string, resource: T): Promise<T> {
    const response = await apiPut('/api/resource/' + resourceType + '/' + id, resource);
    if (response.status === 200) {
        return (await response.json()) as T;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}
