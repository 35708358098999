export enum OrderState {
    DRAFT = 'draft',
    CREATED = 'created',
    APPROVED = 'approved',
    REJECTED = 'rejected',
    CONFIRMED = 'confirmed',
    INDELIVERY = 'indelivery',
    DELIVERED = 'delivered',
    CANCELED = 'canceled',
}
