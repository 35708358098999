
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { sharedState } from '../../../state';
import { getUser, putUser } from '../../../client/users';
import UserGroups from './UserGroups.vue';
import SelectField from '../../../fields/SelectField.vue';
import PhoneField from '../../../fields/PhoneField.vue';
import TextField from '../../../fields/TextField.vue';
import EmailField from '../../../fields/EmailField.vue';
import SwitchField from '../../../fields/SwitchField.vue';
import UserRoles from './UserRoles.vue';
import {
    getCustomerOptions,
    getDealerOptions,
    getImporterOptions,
    getOrganizationOptions,
    getUnitOptions,
} from '../../../../application/service/options';
import { OptionValue } from '../../../service/options';
import { User } from '../../../../../common/framework/model/User';

@Component({
    components: {
        UserRoles,
        SwitchField,
        SelectField,
        PhoneField,
        TextField,
        EmailField,
        UserGroups,
        ValidationObserver,
    },
})
export default class EditUser extends Vue {
    @Prop(String) public readonly id!: string;

    $refs!: {
        userGroups: UserGroups;
        userRoles: UserRoles;
    };

    shared = sharedState;
    local = {
        row: {} as User,
        localeOptions: [
            { id: 'fi', label: 'FI' },
            { id: 'en', label: 'EN' },
        ],
        importerOptions: [] as OptionValue[],
        dealerOptions: [] as OptionValue[],
        customerOptions: [] as OptionValue[],
        organizationOptions: [] as OptionValue[],
        unitOptions: [] as OptionValue[],
    };

    public async mounted() {
        const userId = this.$props.id;
        this.local.row = await getUser(userId);

        this.local.importerOptions = await getImporterOptions();
        this.local.dealerOptions = await getDealerOptions();
        // Show All Customers if user is admin
        this.local.customerOptions = await getCustomerOptions(!this.local.row.admin);
    }

    @Watch('local.row.manager')
    @Watch('local.row.orderer')
    @Watch('local.row.approver')
    public async customerRoleChanged() {
        const noCustomerRole = !this.local.row.manager && !this.local.row.orderer && !this.local.row.approver;
        if (noCustomerRole) {
            this.local.row = {
                ...this.local.row,
                customerId: undefined,
                organizationId: undefined,
                unitId: undefined,
            };
            await this.onCustomerIdChange(undefined);
        }
    }
    @Watch('local.row.customerId')
    public async onCustomerIdChange(newCustomerId: string | undefined) {
        if (newCustomerId) {
            this.local.organizationOptions = await getOrganizationOptions(newCustomerId);
            if (this.local.organizationOptions.filter((o) => o.id === this.local.row.organizationId).length === 0) {
                this.local.row = {
                    ...this.local.row,
                    organizationId: undefined,
                };
            }
        } else {
            this.local.organizationOptions = [{ id: undefined, label: '' }];
            this.local.row = {
                ...this.local.row,
                organizationId: undefined,
            };
        }
    }

    @Watch('local.row.organizationId')
    public async onOrganizationIdChange(newOrganizationId: string) {
        if (newOrganizationId) {
            this.local.unitOptions = await getUnitOptions(newOrganizationId);
            if (this.local.unitOptions.filter((o) => o.id === this.local.row.unitId).length === 0) {
                this.local.row = {
                    ...this.local.row,
                    unitId: undefined,
                };
            }
        } else {
            this.local.unitOptions = [{ id: undefined, label: '' }];
            this.local.row = {
                ...this.local.row,
                unitId: undefined,
            };
        }
    }

    public async save() {
        await putUser(this.id, this.local.row);
        (this.$refs.userGroups as any).save();
        (this.$refs.userRoles as any).save();

        await this.$router.push('/users');

        this.$buefy.toast.open({
            message: 'User saved.',
            type: 'is-success',
            position: 'is-top',
        });
    }

    public back() {
        this.$router.go(-1);
    }
}
