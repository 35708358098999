
import { Component, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../../../framework/state';
import { PAGE_SIZE } from '../../../../../common/framework/constants';

import { deleteResource, getResourcePaging, getResources } from '../../../../framework/client/resource';
import { getEnumerationOptions, getGroupOptions, getOptionLabel } from '../../../service/options';
import Asset from '../../../../../common/framework/model/Asset';
import { AssetType } from '../../../../../common/framework/model/AssetType';
import TextField from '../../../fields/TextField.vue';
import AssetSelect from '../../../fields/AssetSelect.vue';
import { ValidationObserver } from 'vee-validate';
import SelectComponent from '../../../fields/SelectComponent.vue';

@Component({
    components: { SelectComponent },
})
export default class Assets extends Vue {
    readonly resourceType = 'asset';

    shared = sharedState;
    local = {
        rows: new Array<Asset>(),
        total: 0,
        loading: false,
        page: 1,
        perPage: PAGE_SIZE,
        selected: undefined as Asset | undefined,
        assetTypeOptions: [] as { id: string | undefined; label: string }[],
        selectedAssetType: undefined as AssetType | undefined,
    };

    async mounted() {
        this.local.assetTypeOptions = getEnumerationOptions('AssetType', Object.keys(AssetType), this);
        await this.loadAsyncData();
    }

    async onPageChange(page: number) {
        this.local.page = page;
        await this.loadAsyncData();
    }

    add() {
        this.$router.push('/asset/add');
    }

    uploadAsset() {
        this.$router.push('/asset/upload');
    }

    edit() {
        if (this.local.selected) {
            this.$router.push('/asset/' + this.local.selected.id + '/edit');
        }
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    await deleteResource(this.resourceType, this.local.selected.id);
                    await this.loadAsyncData();
                }
            },
        });
    }

    @Watch('local.selectedAssetType')
    async selectedAssetTypeChange(newValue: string | undefined) {
        await this.loadAsyncData();
    }

    async loadAsyncData() {
        this.local.loading = true;

        const parameters = new Map();
        if (this.local.selectedAssetType) {
            parameters.set('type', this.local.selectedAssetType);
        }

        this.local.total = (await getResourcePaging(this.resourceType, parameters)).pageCount * PAGE_SIZE;
        this.local.rows = [];
        const rows = (await getResources(this.resourceType, this.local.page - 1, parameters)) as Array<Asset>;
        rows.forEach((row) => {
            this.local.rows.push(row);
        });
        this.local.loading = false;
        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }
    }

    getAssetTypeLabel(id: string): string {
        return getOptionLabel(id, this.local.assetTypeOptions);
    }
}
