import { apiGet } from '../../framework/client';

import { ERROR_API_CALL_FAILED } from '../../../common/framework/constants';

import { AccessoryImage } from '../../../common/application/model/AccessoryImage';

export async function getRetrofittedAccessoryImages(retrofittedAccessoryId: string): Promise<AccessoryImage[]> {
    const response = await apiGet(`/api/user/retrofitted_accessories/${retrofittedAccessoryId}/images`);
    if (response.status === 200) {
        return (await response.json()) as AccessoryImage[];
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getRetrofittedAccessoryImage(retrofittedAccessoryImageId: string): Promise<Blob> {
    const response = await apiGet(`/api/user/retrofitted-accessory-images/${retrofittedAccessoryImageId}`);
    if (response.status === 200) {
        return await response.blob();
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}
