import { RouteConfig } from 'vue-router';
import SelectVehicle from './components/orderer/order/SelectVehicle.vue';
import Orders from './components/orderer/order/Orders.vue';
import AddOrder from './components/orderer/order/AddOrder.vue';
import EditOrder from './components/orderer/order/EditOrder.vue';
import EditVehicle from './components/orderer/order/EditVehicle.vue';
import Importers from './components/admin/importers/Importers.vue';
import AddImporter from './components/admin/importers/AddImporter.vue';
import EditImporter from './components/admin/importers/EditImporter.vue';
import Dealers from './components/admin/dealers/Dealers.vue';
import AddDealer from './components/admin/dealers/AddDealer.vue';
import EditDealer from './components/admin/dealers/EditDealer.vue';
import CustomerDealers from './components/configurer/customer/dealer/CustomerDealers.vue';
import Customers from './components/admin/customers/Customers.vue';
import AddCustomer from './components/admin/customers/AddCustomer.vue';
import EditCustomer from './components/admin/customers/EditCustomer.vue';
import AddOrganization from './components/admin/organizations/AddOrganization.vue';
import CustomerUsers from './components/configurer/customer/user/CustomerUsers.vue';
import AddCustomerUser from './components/configurer/customer/user/AddCustomerUser.vue';
import EditCustomerUser from './components/configurer/customer/user/EditCustomerUser.vue';
import Organizations from './components/admin/organizations/Organizations.vue';
import EditOrganization from './components/admin/organizations/EditOrganization.vue';
import AddUnit from './components/admin/units/AddUnit.vue';
import EditUnit from './components/admin/units/EditUnit.vue';
import RetrofittedAccessories from './components/configurer/accessory/retrofitted/local/RetrofittedAccessories.vue';
import AddRetrofittedAccessory from './components/configurer/accessory/retrofitted/local/AddRetrofittedAccessory.vue';
import EditRetrofittedAccessory from './components/configurer/accessory/retrofitted/local/EditRetrofittedAccessory.vue';
import TailoredVehicleModels from './components/configurer/model/TailoredVehicleModels.vue';
import AddTailoredVehicleModel from './components/configurer/model/AddTailoredVehicleModel.vue';
import EditTailoredVehicleModel from './components/configurer/model/EditTailoredVehicleModel.vue';
import AddTailoredFactoryAccessory from './components/configurer/accessory/factory/tailored/AddTailoredFactoryAccessory.vue';
import EditTailoredFactoryAccessory from './components/configurer/accessory/factory/tailored/EditTailoredFactoryAccessory.vue';
import AddTailoredRetrofittedAccessory from './components/configurer/accessory/retrofitted/tailored/AddTailoredRetrofittedAccessory.vue';
import EditTailoredRetrofittedAccessory from './components/configurer/accessory/retrofitted/tailored/EditTailoredRetrofittedAccessory.vue';
import EditNotificationGroup from './components/admin/notifications/EditNotificationGroup.vue';
import NotificationGroups from './components/admin/notifications/NotificationGroups.vue';
import AddNotificationGroup from './components/admin/notifications/AddNotificationGroup.vue';
import {ApplicationResource} from "../../common/application/enumeration/ApplicationResource";

export function getApplicationRoutes(): RouteConfig[] {
    return [
        { path: '/orders', component: Orders },
        { path: '/orders/select-vehicle', component: SelectVehicle },
        { path: '/orders/add/:id', component: AddOrder, props: true },
        { path: '/orders/:id/edit', component: EditOrder, props: true },
        { path: '/vehicles/edit/:id', component: EditVehicle, props: true },

        { path: '/importers', component: Importers },
        { path: '/importers/add', component: AddImporter },
        { path: '/importers/edit/:id', component: EditImporter, props: true },

        { path: '/dealers', component: Dealers },
        { path: '/dealers/add', component: AddDealer },
        { path: '/dealers/edit/:id', component: EditDealer, props: true },

        { path: '/customer-dealers', component: CustomerDealers },

        { path: '/customers', component: Customers },
        { path: '/customers/add', component: AddCustomer },
        { path: '/customers/edit/:id', component: EditCustomer, props: true },

        { path: '/customers/:customerId/users', component: CustomerUsers },
        { path: '/customers/:customerId/users/add', component: AddCustomerUser },
        { path: '/customers/:customerId/users/edit/:id', component: EditCustomerUser, props: true },

        { path: '/organizations', component: Organizations },
        { path: '/customers/:customerId/organizations/add', component: AddOrganization, props: true },
        { path: '/organizations/edit/:id', component: EditOrganization, props: true },

        { path: '/organizations/:organizationId/units/add', component: AddUnit, props: true },

        { path: '/units/edit/:id', component: EditUnit, props: true },

        { path: '/retrofitted-accessories', component: RetrofittedAccessories },
        { path: '/retrofitted-accessories/add', component: AddRetrofittedAccessory },
        { path: '/retrofitted-accessories/edit/:id', component: EditRetrofittedAccessory, props: true },

        { path: '/tailored-vehicle-models', component: TailoredVehicleModels },
        { path: '/tailored-vehicle-models/add', component: AddTailoredVehicleModel },
        { path: '/tailored-vehicle-models/edit/:id', component: EditTailoredVehicleModel, props: true },

        {
            path: '/tailored-vehicle-models/:tailoredVehicleModelId/tailored-factory-accessories/add',
            component: AddTailoredFactoryAccessory,
            props: true,
        },
        { path: '/tailored-factory-accessories/edit/:id', component: EditTailoredFactoryAccessory, props: true },

        {
            path: '/tailored-vehicle-models/:tailoredVehicleModelId/tailored-retrofitted-accessories/add',
            component: AddTailoredRetrofittedAccessory,
            props: true,
        },
        {
            path: '/tailored-retrofitted-accessories/edit/:id',
            component: EditTailoredRetrofittedAccessory,
            props: true,
        },

        { path: `/${ApplicationResource.NOTIFICATION_GROUP}`, component: NotificationGroups },
        { path: `/${ApplicationResource.NOTIFICATION_GROUP}/add`, component: AddNotificationGroup },
        { path: `/${ApplicationResource.NOTIFICATION_GROUP}/:id`, component: EditNotificationGroup, props: true },
    ];
}
