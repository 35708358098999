import { BrowserContext } from '../common/framework/model/BrowserContext';
import { SoftwareVersion } from '../common/framework/model/SoftwareVersion';
import { FrameworkUserRole } from '../common/framework/enumeration/FrameworkUserRole';
import { NavigationItem } from './framework/navigation/NavigationItem';
import { Feature } from '../common/framework/enumeration/Feature';
import { getAnonymousContext } from '../common/framework/service/browser_context_service';
import { Role } from '../common/framework/enumeration/Role';
import { ApplicationUserRole } from '../common/application/enumeration/ApplicationUserRole';

export class AppState {
    navigation: NavigationItem[] = [];
    navigationStartMenus: NavigationItem[] = [];
    navigationEndMenus: NavigationItem[] = [];

    context: BrowserContext = getAnonymousContext();
    version = { name: '', version: '', features: [] } as SoftwareVersion;

    email: string | undefined;
    routeAfterLogin: string | undefined;
    customerId: string | undefined;

    mobileBurgerOpen = false;
    connected = false;
    private loading = 0;

    get customerIdFilter(): string | undefined {
        if (this.context.customerId) {
            return this.context.customerId;
        }
        if (this.customerId !== undefined) {
            return this.customerId;
        }
        return undefined;
    }

    get authenticated() {
        return this.context.idToken.length > 0;
    }

    get anonymous() {
        return this.context.userId === 'anonymous';
    }

    get admin() {
        return this.context.roles.indexOf(FrameworkUserRole.ADMIN) !== -1;
    }

    get configurer() {
        return this.context.groups && this.context.groups.indexOf(ApplicationUserRole.CONFIGURER) !== -1;
    }

    get dealer() {
        return this.context.groups && this.context.groups.indexOf(ApplicationUserRole.DEALER) !== -1;
    }

    get manager() {
        return this.context.groups && this.context.groups.indexOf(FrameworkUserRole.MANAGER) !== -1;
    }

    get orderer() {
        return this.context.groups && this.context.groups.indexOf(ApplicationUserRole.ORDERER) !== -1;
    }

    get customer() {
        return this.context.groups && this.context.groups.indexOf(ApplicationUserRole.CUSTOMER) !== -1;
    }

    get approver() {
        return this.context.groups && this.context.groups.indexOf(ApplicationUserRole.APPROVER) !== -1;
    }

    hasRole(role: Role) {
        return this.context.roles.indexOf(role.toString()) !== -1;
    }

    enabled(feature: Feature) {
        return this.version.features.indexOf(feature) !== -1;
    }

    isLoading() {
        return this.loading > 0;
    }

    increaseLoadingCounter() {
        this.loading++;
    }

    decreaseLoadingCounter() {
        setTimeout(() => {
            if (this.loading > 0) {
                this.loading--;
            }
        }, 250);
    }
}
