
import { Component, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../../../framework/state';
import { deleteResource, getResourcePaging, getResources } from '../../../../framework/client/resource';
import {
    getAllUnitOptions,
    getCustomerOptions,
    getDealerOptions,
    getOrderStateOptions,
    getTailoredVehicleModelOptions,
} from '../../../service/options';
import { getUserName } from '../../../../framework/client/users';
import {
    postOrderApproveRequest,
    postOrderCancelRequest,
    postOrderConfirmRequest,
    postOrderDeliverRequest,
    postOrderRejectRequest,
    postSendOrderDetailsRequest,
} from '../../../client/order';
import OrderDetails from './OrderDetails.vue';
import CustomerContextSelector from '../../configurer/context/CustomerContextSelector.vue';
import OrderNumberTextInput from '../../../fields/OrderNumberTextInput.vue';
import UnitSelect from '../../../fields/UnitSelect.vue';
import OrderStateSelect from '../../../fields/OrderStateSelect.vue';
import SelectOrderByLicensePlate from '../../../fields/SelectOrderByLicensePlate.vue';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';
import { Order } from '../../../../../common/application/model/Order';
import { PAGE_SIZE } from '../../../../../common/framework/constants';
import { OrderState } from '../../../../../common/application/model/OrderState';
import { getOptionLabel, getOptionLabelKey, OptionValue } from '../../../../framework/service/options';
import { dateToFinnishUiDateString } from '../../../../../common/framework/util/convert';

@Component({
    components: {
        SelectOrderByLicensePlate,
        OrderStateSelect,
        OrderNumberTextInput,
        UnitSelect,
        CustomerContextSelector,
        OrderDetails,
    },
})
export default class Orders extends Vue {
    readonly resourceType = ApplicationResource.ORDER;

    shared = sharedState;
    local = {
        rows: new Array<Order>(),
        total: 0,
        loading: false,
        page: 1,
        showDelivered: false,
        perPage: PAGE_SIZE,
        selected: undefined as Order | undefined,
    };

    dealerOptions = [] as OptionValue[];
    customerOptions = [] as OptionValue[];
    unitOptions = [] as OptionValue[];
    tailoredVehicleModelOptions = [] as OptionValue[];
    orderStateOptions = [{ id: undefined as string | undefined, labelKey: '' }];
    customerId = '';
    unitId = '';
    orderId = '';
    orderNumber = '';
    orderState = '' as string;
    userNames: Map<string, String> = new Map();

    async mounted() {
        this.dealerOptions = await getDealerOptions();
        this.customerOptions = await getCustomerOptions();
        this.unitOptions = await getAllUnitOptions();
        this.tailoredVehicleModelOptions = await getTailoredVehicleModelOptions();
        this.orderStateOptions = getOrderStateOptions();
        this.customerId = this.shared.customerIdFilter ? this.shared.customerIdFilter : '';

        await this.loadAsyncData();
        document.addEventListener('selected-customer-changed', this.selectedCustomerChanged);
    }

    async destroyed() {
        document.removeEventListener('selected-customer-changed', this.selectedCustomerChanged);
    }

    async selectedCustomerChanged() {
        console.log('selectedCustomerChanged', sharedState.customerIdFilter);
        this.customerId = sharedState.customerIdFilter ? sharedState.customerIdFilter : '';
        this.unitId = '';
        await this.loadAsyncData();
    }

    @Watch('unitId')
    async unitIdChanged() {
        await this.loadAsyncData();
    }

    @Watch('orderNumber')
    async orderNumberChanged() {
        await this.loadAsyncData();
    }

    @Watch('orderId')
    async orderIdChanged() {
        await this.loadAsyncData();
    }

    @Watch('orderState')
    async orderStateChanged() {
        await this.loadAsyncData();
    }

    async onPageChange(page: number) {
        this.local.page = page;
        await this.loadAsyncData();
    }

    @Watch('local.showDelivered')
    async showDeliveredChanged() {
        await this.loadAsyncData();
    }

    add() {
        this.$router.push('/orders/select-vehicle');
    }

    async approve() {
        if (this.local.selected) {
            await postOrderApproveRequest(this.local.selected.id);
            await this.loadAsyncData();
        }
    }

    async reject() {
        if (this.local.selected) {
            const orderId = this.local.selected!!.id;
            this.$buefy.dialog.prompt({
                message: this.$t('field.rejectReason').toString(),
                cancelText: this.$t('button.cancel').toString(),
                confirmText: this.$t('button.ok').toString(),
                inputAttrs: {
                    maxlength: 256,
                },
                onConfirm: async (value) => {
                    await postOrderRejectRequest(orderId, value);
                    await this.loadAsyncData();
                },
            });
        }
    }

    async confirm() {
        if (this.local.selected) {
            await postOrderConfirmRequest(this.local.selected.id);
            await this.loadAsyncData();
        }
    }

    async deliver() {
        if (this.local.selected) {
            await postOrderDeliverRequest(this.local.selected.id);
            await this.loadAsyncData();
        }
    }

    async cancel() {
        if (this.local.selected) {
            await postOrderCancelRequest(this.local.selected.id);
            await this.loadAsyncData();
        }
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    await deleteResource(this.resourceType, this.local.selected.id);
                    await this.loadAsyncData();
                }
            },
        });
    }

    sendDetails() {
        this.$buefy.dialog.prompt({
            message: this.$t('field.receiverEmail').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            inputAttrs: {
                maxlength: 256,
            },
            onConfirm: async (value) => {
                const orderId = this.local.selected!!.id;
                await postSendOrderDetailsRequest(orderId, value);
                await this.loadAsyncData();
            },
        });
    }

    edit() {
        if (this.local.selected) {
            this.$router.push(`/orders/${this.local.selected.id}/edit`);
        }
    }

    async loadAsyncData() {
        this.local.loading = true;
        this.local.total = (await getResourcePaging(this.resourceType, this.getFilter())).rowCount;
        this.local.rows = [];

        const rows = await getResources<Order>(this.resourceType, this.local.page - 1, this.getFilter());

        for (let row of rows) {
            this.local.rows.push(row);
            if (!this.userNames.has(row.orderPersonId)) {
                const userName = await getUserName(row.orderPersonId);
                const userFirstAndLastName = userName.firstName + ' ' + userName.lastName;
                this.userNames.set(row.orderPersonId, userFirstAndLastName);
            }
        }

        this.local.loading = false;
        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }
    }

    getFilter(): Map<string, string> | undefined {
        const map: Map<string, string> = new Map();
        if (sharedState.customerIdFilter) {
            map.set('customerId', sharedState.customerIdFilter);
        }
        if (this.orderId) {
            map.set('id', this.orderId);
        }
        if (this.unitId) {
            map.set('unitId', this.unitId);
        }
        if (this.orderNumber) {
            map.set('orderNumber', this.orderNumber);
        }
        if (this.orderState) {
            map.set('state', this.orderState);
        } else {
            map.set(
                'stateIn',
                [
                    OrderState.DRAFT,
                    OrderState.REJECTED,
                    OrderState.CREATED,
                    OrderState.APPROVED,
                    OrderState.CONFIRMED,
                ].join(','),
            );
        }

        return map;
    }

    getLabel(id: string, options: { id: string | undefined; label: string }[]) {
        return getOptionLabel(id, options);
    }

    getLabelKey(id: string, options: { id: string | undefined; labelKey: string }[]) {
        return getOptionLabelKey(id, options);
    }

    getStateTagType(orderState: OrderState): string {
        if (orderState === OrderState.DRAFT) {
            return 'is-danger';
        } else if (orderState === OrderState.REJECTED) {
            return 'is-danger';
        } else if (orderState === OrderState.CREATED) {
            return 'is-warning';
        } else if (orderState === OrderState.APPROVED) {
            return 'is-warning';
        } else if (orderState === OrderState.CONFIRMED) {
            return 'is-success';
        } else if (orderState === OrderState.INDELIVERY) {
            return 'is-success';
        } else {
            return 'is-light';
        }
    }

    onRowClick(row: Order) {
        if (this.local.selected && row.id === this.local.selected.id) {
            this.local.selected = undefined;
        }
    }

    formatDate(date: string): string {
        return dateToFinnishUiDateString(new Date(date));
    }
}
