import { EngineContext } from '../model/EngineContext';
import {
    addNetworkMessageListener,
    disconnectNetwork,
    isNetworkConnected,
    removeNetworkMessageListener,
} from '../../communication/service/network_service';
import { sendAvatarParts } from './avatar_service';
import { onEntityMove, removeEntities } from './entity_service';
import { moveServiceRenderLoop, startMoveService, stopMoveService } from './move_service';
import { removeGlobalState, setGlobalState } from '../../../service/global_state_service';
import { FrameworkStateType } from '../../../../../common/framework/enumeration/FrameworkStateType';
import { MessageType } from '../../../../../common/framework/module/xr/network/model/MessageType';

export async function startEngineService(context: EngineContext): Promise<void> {
    startMoveService(context);
    context.state.engineTimer = setInterval(async () => {
        if (context.state.engineRunning) {
            return;
        }
        context.state.engineRunning = true;
        try {
            await runEngineService(context);
        } catch (e: any) {
            console.error('unexpected error in second timer.', e);
        }
        context.state.engineRunning = false;
    }, 300);
    await setGlobalState(FrameworkStateType.XR_ENGINE_CONTEXT, context);

    addNetworkMessageListener(MessageType.ENTITY_MOVE, onEntityMove);
    console.log('--- XR ENGINE ONLINE ---');
}

export async function stopEngineService(context: EngineContext) {
    console.log('--- XR ENGINE OFFLINE ---');
    removeNetworkMessageListener(MessageType.ENTITY_MOVE, onEntityMove);
    await removeGlobalState(FrameworkStateType.XR_ENGINE_CONTEXT);
    stopMoveService(context);
    if (context.state.engineTimer) {
        clearInterval(context.state.engineTimer);
    }
    try {
        disconnectNetwork();
    } catch (e: any) {
        console.error('error in nexus disconnect.', e);
    }
    console.log('engine stopped.');
}

async function runEngineService(context: EngineContext): Promise<void> {
    try {
        if (isNetworkConnected()) {
            await sendAvatarParts(context);
        }
    } catch (e: any) {
        console.error('error in nexus connect.', e);
    }
}

export function engineRenderLoop(timeNowMillis: number, timeDeltaMillis: number, context: EngineContext) {
    try {
        context.state.entities.forEach((entity) => {
            if (!entity.lastMovedMillis) {
                entity.lastMovedMillis = timeNowMillis;
            }
            if (entity.dynamic && timeNowMillis - entity.lastMovedMillis!! > 10000) {
                removeEntities([entity], context);
            } else {
                context.renderer.interpolateEntity(timeDeltaMillis, entity);
            }
        });
        moveServiceRenderLoop(timeNowMillis, timeDeltaMillis, context);
    } catch (e: any) {
        console.error('error in engine render loop.', e);
    }
}
