
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { isEmpty, isNil } from 'lodash';

import { sharedState } from '../../../../state';
import { EngineContext } from '../../model/EngineContext';
import { CommunicationState } from '../../../communication/model/CommunicationState';
import { getGlobalState } from '../../../../service/global_state_service';
import { FrameworkStateType } from '../../../../../../common/framework/enumeration/FrameworkStateType';
import { ChannelState } from '../../../communication/model/ChannelState';
import TextField from '../../../../fields/TextField.vue';
import TextComponent from '../../../../fields/TextComponent.vue';
import { sendChatMessage } from '../../../communication/service/network_service';

@Component({
    components: { TextComponent, TextField },
})
export default class CommunicationView extends Vue {
    @Prop(Object) readonly context!: EngineContext;
    @Prop(Number) readonly height!: number;

    shared = sharedState;
    local = {
        communicationState: {} as any as CommunicationState,
        selectedChannel: undefined as ChannelState | undefined,
        lastMessageCount: 0,
        chatInput: '',
    };

    async mounted() {
        this.local.communicationState = getGlobalState<CommunicationState>(FrameworkStateType.COMMUNICATION_STATE);
        this.channelsChanged();
        window.addEventListener('resize', this.resized);
    }

    resized = () => {
        const div = document.getElementById('messages-content')!!;
        if (div) {
            div.scrollTop = div.scrollHeight;
        }
    };

    async destroyed() {
        window.removeEventListener('resize', this.resized);
    }

    @Watch('local.communicationState.channels')
    channelsChanged() {
        if (
            !isNil(this.local.selectedChannel) &&
            !isNil(this.local.communicationState.channels) &&
            this.local.communicationState.channels.indexOf(this.local.selectedChannel) === -1
        ) {
            this.local.selectedChannel = undefined;
        }
        if (!isEmpty(this.local.communicationState.channels) && isNil(this.local.selectedChannel)) {
            this.local.selectedChannel = this.local.communicationState.channels[0];
        }
    }

    @Watch('local.selectedChannel.messages')
    messagesChanged() {
        setTimeout(() => {
            const div = document.getElementById('messages-content')!!;
            if (div && this.local.selectedChannel) {
                if (div.scrollTop < div.scrollHeight) {
                    const computedLineHeight = parseInt(window.getComputedStyle(div).lineHeight!!);
                    const messageCount = this.local.selectedChannel!!.messages.length;
                    const messageCountDiff = messageCount - this.local.lastMessageCount;
                    this.local.lastMessageCount = messageCount;
                    if (messageCountDiff > 0) {
                        let newScrollTop = div.scrollTop + computedLineHeight * messageCountDiff;
                        if (newScrollTop > div.scrollHeight) {
                            newScrollTop = div.scrollHeight;
                        }
                        div.scrollTop = newScrollTop;
                    }
                }
            } else {
                this.local.lastMessageCount = 0;
            }
        }, 200);
    }

    channelSelected(channel: ChannelState) {
        this.local.selectedChannel = channel;
    }

    async chatInputSend() {
        if (this.local.selectedChannel) {
            console.log(this.local.chatInput);
            await sendChatMessage(this.local.selectedChannel.id, this.local.chatInput);
            this.local.chatInput = '';
        }
    }

    async chatInputFocus(focus: boolean) {
        const engineContext = getGlobalState<EngineContext>(FrameworkStateType.XR_ENGINE_CONTEXT);
        engineContext.state.inputFocus = focus;
    }
}
