
import { Component, Prop, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../../state';
import { getResource, putResource } from '../../../../../client/resource';
import TextField from '../../../../../fields/TextField.vue';
import SelectField from '../../../../../fields/SelectField.vue';
import { SpaceType } from '../../../../../../../common/framework/model/SpaceType';
import Space from '../../../../../../../common/framework/model/Space';
import { getEnumOptions } from '../../../../../service/options';
import NumberField from '../../../../../fields/NumberField.vue';
import { ValidationObserver } from 'vee-validate';
import AutoCompleteField from '../../../../../fields/AutoCompleteField.vue';
import SwitchField from '../../../../../fields/SwitchField.vue';
import MarkDownField from '../../../../../fields/MarkDownField.vue';

@Component({
    components: { SwitchField, SelectField, TextField, AutoCompleteField, MarkDownField, ValidationObserver },
})
export default class EditSpace extends Vue {
    // Constants
    readonly resourceType = 'space';

    // Properties
    @Prop(String) readonly id!: string;

    // State
    shared = sharedState;
    local = {
        row: {
            id: '',
            type: '' as SpaceType,
            category: '',
            name: '',
            key: '',
            description: '',
            created: undefined as any as Date,
            modified: undefined as any as Date,
        } as Space,
        typeOptions: [] as { id: string | undefined; label: string }[],
    };

    // Functions
    async mounted() {
        this.local.typeOptions = getEnumOptions('SpaceType', Object.keys(SpaceType)).map((o) => {
            return { id: o.id, label: this.$t(o.labelKey).toString() };
        });
        Object.assign(this.local.row, await getResource<Space>(this.resourceType, this.id));
    }

    async save() {
        await putResource(this.resourceType, this.id, this.local.row);
        this.back();
    }

    back() {
        this.$router.go(-1);
    }
}
