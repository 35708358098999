
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../state';
import { getFieldNameFromVModelProperty } from '../util/component_util';
import { ValidationProvider } from 'vee-validate';
import { v4 as uuid } from 'uuid';
import * as ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/eclipse';
import { Editor } from 'brace';
import { isNil } from 'lodash';
import stringify from 'json-stable-stringify';

@Component({
    components: { ValidationProvider },
})
export default class JsonField extends Vue {
    @Prop(String) readonly name!: string;
    @Prop(Object) readonly value!: object;
    @Prop(Boolean) readonly readonly!: boolean;
    @Prop(Boolean) readonly disabled!: string;

    // Refs
    $refs!: {
        observer: Vue;
    };

    shared = sharedState;
    local = {
        jsonEditorContainerId: uuid.toString(),
    };
    editor: Editor | undefined;

    async mounted() {
        const container = document.getElementById(this.local.jsonEditorContainerId)!!;
        this.editor = ace.edit(container);
        this.editor.$blockScrolling = Infinity;
        this.editor.setFontSize('16px');
        this.editor.setReadOnly(this.readonly && !this.disabled);
        this.editor.getSession().setMode('ace/mode/json');
        this.editor.setTheme('ace/theme/eclipse');
        this.editor.getSession().on('change', () => {
            if (this.isJsonString(this.editor!!.getValue())) {
                this.$emit('input', JSON.parse(this.editor!!.getValue()));
            } else {
                this.$emit('input', undefined);
            }
        });
        this.editor.setOptions({
            minLines: 5,
            maxLines: 20,
        });
        await this.modelValueChange(this.value);
    }

    @Watch('readonly')
    async readonlyChange(newValue: boolean) {
        this.editor!!.setReadOnly(newValue && !this.disabled);
    }

    @Watch('disabled')
    async disabledChange(newValue: boolean) {
        this.editor!!.setReadOnly(this.readonly && !newValue);
    }

    @Watch('value')
    async modelValueChange(newValue: object) {
        if (!isNil(newValue)) {
            if (
                !this.isJsonString(this.editor!!.getValue()) ||
                this.normalizedJson(JSON.parse(this.editor!!.getValue())) != this.normalizedJson(newValue)
            ) {
                this.editor!!.setValue(this.normalizedJson(newValue), -1);
            }
        }
    }

    isJsonString(str: string) {
        try {
            JSON.parse(str);
        } catch (e: any) {
            return false;
        }
        return true;
    }

    getFieldName() {
        return getFieldNameFromVModelProperty(this);
    }

    normalizedJson(object: any): string {
        return stringify(object, { space: '\t' });
    }

    flattenObject(ob: any) {
        let toReturn = {} as any;

        for (let i in ob) {
            if (!ob.hasOwnProperty(i)) continue;

            if (typeof ob[i] == 'object') {
                const flatObject = this.flattenObject(ob[i]);
                for (let x in flatObject) {
                    if (!flatObject.hasOwnProperty(x)) continue;

                    toReturn[i + '.' + x] = flatObject[x];
                }
            } else {
                toReturn[i] = ob[i];
            }
        }
        return toReturn;
    }
}
