
import { Component, Prop, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../state';
import { PAGE_SIZE } from '../../../../../../common/framework/constants';

import { deleteResource, getResourcePaging, getResources } from '../../../../client/resource';
import AssetLink from '../../../../../../common/framework/model/AssetLink';

@Component
export default class AssetLinks extends Vue {
    readonly resourceType = 'asset-link';

    // Properties
    @Prop(String) readonly parentAssetId!: string;

    shared = sharedState;
    local = {
        rows: new Array<AssetLink>(),
        total: 0,
        loading: false,
        page: 1,
        perPage: PAGE_SIZE,
        selected: undefined as AssetLink | undefined,
    };

    async mounted() {
        await this.loadAsyncData();
    }

    async onPageChange(page: number) {
        this.local.page = page;
        await this.loadAsyncData();
    }

    add() {
        this.$router.push('/asset/' + this.parentAssetId + '/add-link');
    }

    edit() {
        if (this.local.selected) {
            this.$router.push('/asset-link/' + this.local.selected.id + '/edit');
        }
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    await deleteResource(this.resourceType, this.local.selected.id);
                    await this.loadAsyncData();
                }
            },
        });
    }

    async loadAsyncData() {
        this.local.loading = true;

        const parameters = new Map();
        if (this.parentAssetId) {
            parameters.set('parentAssetId', this.parentAssetId);
        }

        this.local.total = (await getResourcePaging(this.resourceType, parameters)).pageCount * PAGE_SIZE;
        this.local.rows = [];
        const rows = (await getResources(this.resourceType, this.local.page - 1, parameters)) as Array<AssetLink>;
        rows.forEach((row) => {
            this.local.rows.push(row);
        });
        this.local.loading = false;
        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }
    }
}
