import showdown from 'showdown';

const converter = new showdown.Converter();
export function mdToHtml(md: string) {
    if (md) {
        return converter.makeHtml(md.replace('<', ''));
    } else {
        return md;
    }
}
