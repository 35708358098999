export enum FrameworkFeature {
    ADMIN_API = 'admin-api',
    ANONYMOUS_ACCESS = 'anonymous-access',
    PASSWORD_AUTHENTICATION = 'password-authentication',
    SAML_AUTHENTICATION = 'saml-authentication',
    GOOGLE_AUTHENTICATION = 'google-authentication',
    MICROSOFT_AUTHENTICATION = 'microsoft-authentication',
    VOICE_CHAT = 'voice-chat',
    XR = 'xr',
    XR_SPACES = 'xr-spaces',
    XR_SPACES_PUBLIC_LIST = 'xr-spaces-public-list',
    XR_BAKES = 'xr-bakes',
    DATA_SPACES = 'data-spaces',
}
