
import {Component, Vue, Watch} from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { sharedState } from '../../../state';
import { UserInfo } from '../../../../../common/framework/model/UserInfo';
import { postUser } from '../../../client/users';
import { postRequestTokenPasswordChange } from '../../../client/password';
import UserGroups from './UserGroups.vue';
import TextField from '../../../fields/TextField.vue';
import PhoneField from '../../../fields/PhoneField.vue';
import SelectField from '../../../fields/SelectField.vue';
import EmailField from '../../../fields/EmailField.vue';
import SwitchField from '../../../fields/SwitchField.vue';
import UserRoles from './UserRoles.vue';
import { OptionValue } from '../../../service/options';
import {
    getCustomerOptions,
    getDealerOptions,
    getImporterOptions,
    getOrganizationOptions, getUnitOptions
} from "../../../../application/service/options";

@Component({
    components: {
        SwitchField,
        SelectField,
        PhoneField,
        TextField,
        EmailField,
        UserRoles,
        UserGroups,
        ValidationObserver,
    },
})
export default class AddUser extends Vue {
    shared = sharedState;
    local = {
        localeOptions: [
            { id: 'fi', label: 'FI' },
            { id: 'en', label: 'EN' },
        ],
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        locale: '',
        locked: false,
        importerId: undefined as undefined | string,
        dealerId: undefined as undefined | string,
        customerId: undefined as undefined | string,
        organizationId: undefined as undefined | string,
        unitId: undefined as undefined | string,
        admin: false,
        manager: false,
        configurer: false,
        orderer: false,
        approver: false,
        dealer: false,
        importerOptions: [] as OptionValue[],
        dealerOptions: [] as OptionValue[],
        customerOptions: [] as OptionValue[],
        organizationOptions: [] as OptionValue[],
        unitOptions: [] as OptionValue[],
    };
    id = '';

    $refs!: {
        userRoles: UserRoles;
        userGroups: UserGroups;
    };

    async mounted() {
        this.local.importerOptions = await getImporterOptions();
        this.local.dealerOptions = await getDealerOptions();
        this.local.customerOptions = await getCustomerOptions();
    }

    @Watch('local.customerId')
    public async onCustomerIdChange(newCustomerId: string) {
        if (newCustomerId) {
            this.local.organizationOptions = await getOrganizationOptions(newCustomerId);
            if (this.local.organizationOptions.filter(o => o.id === this.local.organizationId).length === 0) {
                this.local.organizationId = undefined;
            }
        } else {
            this.local.organizationOptions = [{id: undefined, label: ''}];
            this.local.organizationId = undefined;
        }
    }

    @Watch('local.organizationId')
    public async onOrganizationIdChange(newOrganizationId: string) {
        if (newOrganizationId) {
            this.local.unitOptions = await getUnitOptions(newOrganizationId);
            if (this.local.unitOptions.filter(o => o.id === this.local.unitId).length === 0) {
                this.local.unitId = undefined;
            }
        } else {
            this.local.unitOptions = [{id: undefined, label: ''}];
            this.local.unitId = undefined;
        }
    }

    async add() {
        const addedUser = await postUser(
            new UserInfo(
                this.local.firstName,
                this.local.lastName,
                this.local.email,
                this.local.phone,
                this.local.admin,
                this.local.manager,
                this.local.configurer,
                this.local.dealer,
                this.local.orderer,
                this.local.approver,
                this.local.locale,
                false,
                this.local.importerId,
                this.local.dealerId,
                this.local.customerId,
                this.local.organizationId,
                this.local.unitId,
            ),
        );
        this.id = addedUser.id;
        await (this.$refs.userRoles as any).saveWithUserId(addedUser.id);
        await (this.$refs.userGroups as any).saveWithUserId(addedUser.id);

        await this.$router.push('/users');
        if (await postRequestTokenPasswordChange(this.local.email)) {
            this.$buefy.toast.open({
                message: 'User added and invited.',
                type: 'is-success',
                position: 'is-top',
            });
        } else {
            this.$buefy.toast.open({
                message: 'User added but invitation failed.',
                type: 'is-warning',
                position: 'is-top',
            });
        }

        this.local.firstName = '';
        this.local.lastName = '';
        this.local.email = '';
    }

    back() {
        this.$router.go(-1);
    }
}
