import Vue from 'vue';
import Component from 'vue-class-component';
import { sharedState } from './framework/state';
import { localizeVeeValidate } from '../common/i18n';
import { getProfile, putProfile } from './framework/client/profile';
import { logout } from './framework/service/authentication_service';

@Component
export default class App extends Vue {
    public shared = sharedState;
    public local = {
        langs: ['fi', 'en'],
    };

    public async logout() {
        await logout();
    }

    public async changeLanguage(lang: string) {
        this.shared.mobileBurgerOpen = false;
        this.$i18n.locale = lang;
        localizeVeeValidate(lang);

        if (sharedState.authenticated) {
            const profile = await getProfile();
            await putProfile({ ...profile, locale: lang });
        }
    }
}
