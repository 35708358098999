
import { Component, Prop, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../framework/state';
import { getResource, putResource } from '../../../framework/client/resource';
import { ValidationObserver } from 'vee-validate';
import { Profile } from '../../../../common/framework/model/Profile';
import { getProfile, putProfile } from '../../client/profile';
import PhoneField from '../../fields/PhoneField.vue';
import EmailField from '../../fields/EmailField.vue';
import TextField from '../../fields/TextField.vue';
import AutoCompleteField from '../../fields/AutoCompleteField.vue';

@Component({
    components: { EmailField, PhoneField, TextField, AutoCompleteField, ValidationObserver },
})
export default class EditProfile extends Vue {
    // Constants
    readonly resourceType = 'pofile-info';

    // Properties
    @Prop(String) readonly id!: string;

    // State
    shared = sharedState;
    local = {
        row: {} as Profile,
        typeOptions: [] as { id: string | undefined; label: string }[],
    };

    // Functions
    async mounted() {
        this.local.row = await getProfile();
    }

    async save() {
        await putProfile(this.local.row);
        this.$buefy.toast.open({
            message: this.$t('message.profileSaved').toString(),
            type: 'is-success',
            position: 'is-top',
        });
    }
}
