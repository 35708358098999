import { getResources } from '../../framework/client/resource';
import { getVehicleMakes } from '../client/vehicle-makes';
import { getVehicleModelInfo, getVehicleModels } from '../client/vehicle_model_client';
import { underscoreToCamel, underscoreToHyphenated } from '../../../common/framework/util/convert';
import { sharedState } from '../../framework/state';
import { getSalesPersons } from '../client/order';
import { Importer } from '../../../common/application/model/Importer';
import { Dealer } from '../../../common/application/model/Dealer';
import { VehicleType } from '../../../common/application/model/VehicleType';
import { VehicleModel } from '../../../common/application/model/erp/VehicleModel';
import { OrderState } from '../../../common/application/model/OrderState';
import { FactoryAccessoryCategory } from '../../../common/application/model/erp/FactoryAccessoryCategory';
import { FinancingModel } from '../../../common/application/model/FinancingModel';
import { Person } from '../../../common/application/model/Person';
import { TailoredVehicleModel } from '../../../common/application/model/TailoredVehicleModel';
import { Customer } from '../../../common/application/model/Customer';
import { Unit } from '../../../common/application/model/Unit';
import { Organization } from '../../../common/application/model/Organization';
import { ApplicationResource } from '../../../common/application/enumeration/ApplicationResource';

export async function getImporterOptions() {
    return [{ id: undefined as string | undefined, label: '' }].concat(
        ((await getResources('importer', -1)) as Importer[]).map((o) => {
            return { id: o.id, label: o.name };
        }),
    );
}

export async function getDealerOptions() {
    return [{ id: undefined as string | undefined, label: '' }].concat(
        ((await getResources('dealer', -1)) as Dealer[]).map((o) => {
            return { id: o.id, label: o.name };
        }),
    );
}

export async function getCustomerOptions(withCustomerContext: boolean = true) {
    const parameters: Map<string, string> | undefined = withCustomerContext
        ? sharedState.context.customerId
            ? new Map([['id', sharedState.context.customerId]])
            : undefined
        : undefined;
    return [{ id: undefined as string | undefined, label: '' }].concat(
        ((await getResources('customer', -1, parameters)) as Customer[]).map((o) => {
            return { id: o.id, label: o.name };
        }),
    );
}

export async function getAllUnitOptions() {
    return [{ id: undefined as string | undefined, label: '' }].concat(
        (
            (await getResources(
                'unit',
                -1,
                sharedState.context.customerId ? new Map([['customerId', sharedState.context.customerId]]) : undefined,
            )) as Unit[]
        ).map((o) => {
            return { id: o.id, label: o.name };
        }),
    );
}

export async function getTailoredVehicleModelOptions() {
    return [{ id: undefined as string | undefined, label: '' }].concat(
        (
            (await getResources(
                ApplicationResource.TAILORED_VEHICLE_MODEL,
                -1,
                sharedState.context.customerId ? new Map([['customerId', sharedState.context.customerId]]) : undefined,
            )) as TailoredVehicleModel[]
        ).map((o) => {
            return { id: o.id, label: o.name };
        }),
    );
}

export async function getVehicleMakeOptions() {
    return [{ id: undefined as string | undefined, label: '' }].concat(
        (await getVehicleMakes()).map((o) => {
            return { id: o.oid.toString(), label: o.name };
        }),
    );
}

export async function getVehicleModelOptions(makeId: string, vehicleType: VehicleType) {
    return [{ id: undefined as string | undefined, label: '' }].concat(
        (await getVehicleModels(makeId))
            .filter((m) => belongsToVehicleType(m, vehicleType))
            .map((o) => {
                return { id: o.oid.toString(), label: o.name + ' (' + o.factoryCode + ')' };
            }),
    );
}

function belongsToVehicleType(model: VehicleModel, vehicleType: VehicleType | undefined) {
    if (!vehicleType) {
        return true;
    } else if (vehicleType === VehicleType.PASSENGER_CAR) {
        return model.class.startsWith('M');
    } else if (vehicleType === VehicleType.VAN) {
        return model.class.startsWith('N');
    } else if (vehicleType === VehicleType.TRUCK) {
        return model.class.startsWith('O');
    } else {
        return false;
    }
}

export function getOrderStateOptions() {
    return [{ id: undefined as string | undefined, labelKey: '' }].concat(
        Object.keys(OrderState).map((key) => {
            return {
                id: underscoreToHyphenated(key),
                labelKey: 'enum.orderState.' + underscoreToCamel(key.toLowerCase()),
            };
        }),
    );
}

export function getFactoryAccessoryCategoryOptions() {
    return [{ id: undefined as string | undefined, labelKey: '' }].concat(
        Object.keys(FactoryAccessoryCategory).map((key) => {
            return {
                id: underscoreToHyphenated(key),
                labelKey: 'enum.factoryAccessoryCategory.' + underscoreToCamel(key.toLowerCase()),
            };
        }),
    );
}

export function getFinancingModelOptions() {
    return [{ id: undefined as string | undefined, labelKey: 'field.notDefined' }].concat(
        Object.keys(FinancingModel).map((key) => {
            return {
                id: underscoreToHyphenated(key),
                labelKey: 'enum.financingModel.' + underscoreToCamel(key.toLowerCase()),
            };
        }),
    );
}

export async function getFactoryAccessoryOptions(modelId: string) {
    const vehicleModelInfo = await getVehicleModelInfo(modelId);
    return [{ id: undefined as string | undefined, label: '' }].concat(
        vehicleModelInfo.factoryAccessories.map((o) => {
            return { id: o.oid.toString(), label: o.name + ' (' + o.code + ')' };
        }),
    );
}

export async function getRetrofittedAccessoryOptions(modelId: string) {
    const vehicleModelInfo = await getVehicleModelInfo(modelId);
    return [{ id: undefined as string | undefined, label: '' }].concat(
        vehicleModelInfo.retrofittedAccessories.map((o) => {
            return { id: o.oid.toString(), label: o.name + ' (' + o.code + ')' };
        }),
    );
}

export async function getOrganizationOptions(customerId: string) {
    return [{ id: undefined as string | undefined, label: '' }].concat(
        ((await getResources('organization', -1, new Map([['customerId', customerId]]))) as Organization[])
            .filter((o) => o.customerId === customerId)
            .map((o) => {
                return { id: o.id, label: o.name };
            }),
    );
}

export async function getUnitOptions(organizationId: string) {
    return [{ id: undefined as string | undefined, label: '' }].concat(
        ((await getResources('unit', -1, new Map([['organizationId', organizationId]]))) as Unit[])
            .filter((o) => o.organizationId === organizationId)
            .map((o) => {
                return { id: o.id, label: o.name };
            }),
    );
}

export async function getSalesPersonOptions(dealerId: string) {
    return [{ id: undefined as string | undefined, label: '' }].concat(
        ((await getSalesPersons(dealerId)) as Person[]).map((o) => {
            return { id: o.id, label: o.lastName + ' ' + o.firstName };
        }),
    );
}
