import { isEmpty } from 'lodash';

import { Resource } from '../../../common/framework/enumeration/Resource';
import { getResources } from '../client/resource';

const labelMemoryStore: Map<string, Map<string, string>> = new Map<string, Map<string, string>>();

export async function getResourceLabels(
    rows: Array<any>,
    idField: string,
    resource: Resource,
    resourceLabelField: string,
) {
    return await loadResourceLabels(resource.toString(), resourceLabelField, collectSpaceIds(rows, idField));
}

function collectSpaceIds(rows: Array<any>, field: string) {
    const spaceIds: Array<string> = [];
    for (let row of rows) {
        if (row[field]) {
            spaceIds.push(row[field]!!);
        }
    }
    return spaceIds;
}

async function loadResourceLabels(resource: string, field: string, ids: Array<string>): Promise<Map<string, string>> {
    if (!labelMemoryStore.has(resource)) {
        labelMemoryStore.set(resource, new Map<string, string>());
    }
    const resourceLabels = labelMemoryStore.get(resource)!!;

    const labels = new Map<string, string>();
    const idsToLoad: Array<string> = [];
    for (const id of ids) {
        if (resourceLabels.has(id)) {
            labels.set(id, resourceLabels.get(id)!!);
        } else {
            idsToLoad.push(id);
        }
    }

    // Load ids not found from memory store.
    const idsLoaded: Array<string> = [];
    if (idsToLoad.length > 0) {
        const rows = await getResources(resource, -1, new Map<string, string>([['idIn', ids.join(',')]]));
        for (const row of rows) {
            const id = (row as any).id as string;
            const label = (row as any)[field] as string;
            if (isEmpty(label)) {
                // Mark rows with no label field content with ?.
                resourceLabels.set(id, '?');
                labels.set(id, '?');
            } else {
                resourceLabels.set(id, label);
                labels.set(id, label);
            }
            idsLoaded.push(id);
        }
    }

    let idsMissing = idsToLoad.filter((el) => !idsLoaded.includes(el));

    // Mark ids not loaded from database with -
    for (const id of idsMissing) {
        resourceLabels.set(id, '-');
        labels.set(id, '-');
    }

    return labels;
}
