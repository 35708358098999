
import { Component, Prop, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../../../framework/state';
import { getResource, putResource } from '../../../../../../framework/client/resource';
import { getImporterOptions } from '../../../../../service/options';
import RetrofittedAccessoryImages from './RetrofittedAccessoryImages.vue';
import { ApplicationResource } from '../../../../../../../common/application/enumeration/ApplicationResource';
import { RetrofittedAccessory } from '../../../../../../../common/application/model/RetrofittedAccessory';
import { OptionValue } from '../../../../../../framework/service/options';
import TextField from '../../../../../../framework/fields/TextField.vue';
import NumberField from '../../../../../../framework/fields/NumberField.vue';
import SelectField from '../../../../../../framework/fields/SelectField.vue';
import { ValidationObserver } from 'vee-validate';

@Component({
    components: { SelectField, NumberField, TextField, RetrofittedAccessoryImages, ValidationObserver },
})
export default class EditRetrofittedAccessory extends Vue {
    readonly resourceType = ApplicationResource.RETROFITTED_ACCESSORY;

    @Prop(String) readonly id!: string;

    shared = sharedState;
    local = {
        row: {} as RetrofittedAccessory,
        vendorOptions: [] as OptionValue[],
    };

    async mounted() {
        this.local.vendorOptions = await getImporterOptions();
        this.local.row = (await getResource<RetrofittedAccessory>(this.resourceType, this.id))!!;
    }

    async save() {
        await putResource(this.resourceType, this.id, this.local.row);
        this.$router.go(-1);
    }

    back() {
        this.$router.go(-1);
    }
}
