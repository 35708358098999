import Timeout = NodeJS.Timeout;

import { getResources } from '../../../client/resource';
import Nexus from '../../../../../common/framework/model/Nexus';
import { FrameworkResource } from '../../../../../common/framework/enumeration/FrameworkResource';
import { NexusClient } from '../../../../../common/framework/module/xr/network/NexusClient';
import { LoginRequest } from '../../../../../common/framework/module/xr/network/model/LoginRequest';
import { v4 as uuid } from 'uuid';
import { MessageType } from '../../../../../common/framework/module/xr/network/model/MessageType';
import { JoinRequest } from '../../../../../common/framework/module/xr/network/model/JoinRequest';
import { EntityMoveMessage } from '../../../../../common/framework/module/xr/network/model/EntityMoveMessage';
import { MediaStreamMessage } from '../../../../../common/framework/module/xr/network/model/MediaStreamMessage';
import { isNil } from 'lodash';
import { getSpaceChannelId } from './channel_service';
import { getGlobalState, hasGlobalState } from '../../../service/global_state_service';
import { FrameworkStateType } from '../../../../../common/framework/enumeration/FrameworkStateType';
import { CommunicationState } from '../model/CommunicationState';
import { sharedState } from '../../../state';
import { ChatMessage } from '../../../../../common/framework/module/xr/network/model/ChatMessage';
import { ChatPayload } from '../model/ChatPayload';

let client: NexusClient | undefined;

export async function initializeNetwork(): Promise<NexusClient | undefined> {
    console.log('constructing nexus client...');
    const parameters = new Map<string, string>();
    parameters.set('name', 'default');
    const nexuses = await getResources<Nexus>(FrameworkResource.NEXUS, -1, parameters);
    if (nexuses.length === 0) {
        console.log('no default nexus available.');
        return undefined;
    } else {
        client = new NexusClient(nexuses[0].url, (url: string): WebSocket => {
            return new WebSocket(url);
        });
        client.onReceive = async (messageType: MessageType, message: any) => {
            try {
                if (messageType === MessageType.LOGIN_RESPONSE) {
                    if (client) {
                        const spaceId = getSpaceChannelId();
                        console.log('joining channel: ' + spaceId);
                        await client.send<JoinRequest>(MessageType.JOIN_REQUEST, {
                            SpaceId: spaceId,
                            EntityId: undefined,
                            ChannelType: 'space',
                            RequestId: uuid(),
                        });
                    }
                }
                if (networkMessageListeners.has(messageType)) {
                    for (const networkMessageListener of networkMessageListeners.get(messageType)!!) {
                        await networkMessageListener(messageType, message);
                    }
                }
            } catch (e: any) {
                console.error('xr on message receive error. ', e);
            }
        };
        console.log('constructed nexus client.');
    }
}

export async function connectNetwork(): Promise<void> {
    if (!hasGlobalState(FrameworkStateType.COMMUNICATION_STATE)) {
        console.warn('ignored nexus connect invocation as xr engine context is not in global state.');
        return;
    }
    const state = getGlobalState<CommunicationState>(FrameworkStateType.COMMUNICATION_STATE);
    if (client && !client.connected) {
        if (!state.lastNexusConnect || state.lastNexusConnect.getTime() + 5000 < new Date().getTime()) {
            state.lastNexusConnect = new Date();
            await client.connect();
            console.log('nexus connected.');
            await client.send<LoginRequest>(MessageType.LOGIN_REQUEST, {
                RequestId: uuid(),
                Token: state.authenticationToken,
            });
        }
    }
}

export function disconnectNetwork(): void {
    if (isNetworkConnected()) {
        client!!.disconnect();
        console.log('nexus disconnected.');
    }
}

export function isNetworkConnected() {
    return client && client.connected;
}

export function sendEntityMoveMessage(entityMove: EntityMoveMessage) {
    if (client && client.connected) {
        client.send<EntityMoveMessage>(MessageType.ENTITY_MOVE, entityMove);
    } else {
        console.warn('unable to send entity move message as client is not connected.');
    }
}

export function sendMediaStreamMessage(mediaStreamMessage: MediaStreamMessage) {
    if (client && client.connected) {
        client.send<MediaStreamMessage>(MessageType.MEDIA_STREAM, mediaStreamMessage);
    } else {
        console.warn('unable to send media stream message as client is not connected.');
    }
}

export function sendChatMessage(channelId: string, message: string) {
    const state = getGlobalState<CommunicationState>(FrameworkStateType.COMMUNICATION_STATE);
    const chatPayload: ChatPayload = {
        type: 'chat',
        message: message,
        timestamp: new Date().getTime(),
    };
    const chatMessage: ChatMessage = {
        ChannelId: channelId,
        UserId: '',
        Message: JSON.stringify(chatPayload),
    };
    sendMessage(MessageType.CHAT, chatMessage);
}

export function sendMessage<T>(type: MessageType, message: T) {
    if (client && client.connected) {
        client.send<T>(type, message);
    } else {
        console.warn('unable to send ' + type + ' message as client is not connected.');
    }
}

export async function startNetwork() {
    startNetworkEngine();
}

export async function stopNetwork() {
    stopNetworkEngine();
}

let networkEngineIntervalId: Timeout | undefined = undefined;

function startNetworkEngine() {
    networkEngineIntervalId = setInterval(networkEngineLoop, 1000);
}

function stopNetworkEngine() {
    if (!isNil(networkEngineIntervalId)) {
        clearInterval(networkEngineIntervalId);
    }
}

async function networkEngineLoop() {
    try {
        if (!isNetworkConnected() && sharedState.authenticated) {
            await connectNetwork();
        }
    } catch (e: any) {
        console.error('Error connecting to network.', e);
    }
}

type NetworkMessageListener<T> = (type: MessageType, message: T) => Promise<void>;
const networkMessageListeners = new Map<MessageType, Array<NetworkMessageListener<any>>>();

export function addNetworkMessageListener<T>(type: MessageType, listener: NetworkMessageListener<T>) {
    if (!networkMessageListeners.has(type)) {
        networkMessageListeners.set(type, []);
    }
    networkMessageListeners.get(type)!!.push(listener);
}

export function removeNetworkMessageListener<T>(type: MessageType, listener: NetworkMessageListener<T>) {
    if (!networkMessageListeners.has(type)) {
        networkMessageListeners.set(type, []);
    }
    const index = networkMessageListeners.get(type)!!.indexOf(listener);
    if (index != -1) {
        networkMessageListeners.get(type)!!.splice(index, 1);
    }
}
