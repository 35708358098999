
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../framework/state';
import { PAGE_SIZE } from '../../../../../common/framework/constants';

import { deleteResource, getResourcePaging, getResources } from '../../../../framework/client/resource';
import { getEnumOptions, getOptionLabel } from '../../../service/options';
import NodeInstance from '../../../../../common/framework/model/NodeInstance';
import { dateDifferenceToString, dateToUiDateTimeString } from '../../../../../common/framework/util/convert';

@Component
export default class Nodes extends Vue {
    readonly resourceType = 'node-instance';

    shared = sharedState;
    local = {
        rows: new Array<NodeInstance>(),
        total: 0,
        loading: false,
        page: 1,
        perPage: PAGE_SIZE,
        selected: undefined as NodeInstance | undefined,
    };

    async mounted() {
        await this.loadAsyncData();
    }

    async onPageChange(page: number) {
        this.local.page = page;
        await this.loadAsyncData();
    }

    async loadAsyncData() {
        this.local.loading = true;
        this.local.total = (await getResourcePaging(this.resourceType)).pageCount * PAGE_SIZE;
        this.local.rows = [];
        const rows = (await getResources(this.resourceType, this.local.page - 1)) as Array<NodeInstance>;
        rows.forEach((row) => {
            this.local.rows.push(row);
        });
        this.local.loading = false;
        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }
    }

    getBooleanTagClass(enabledBoolean: boolean): string {
        if (enabledBoolean == true) {
            return 'is-dark';
        } else {
            return 'is-light';
        }
    }

    isOnline(lastHeartBeat: Date): boolean {
        return new Date().getTime() - new Date(lastHeartBeat).getTime() < 30 * 1000;
    }

    getOnlineTagClass(enabledBoolean: boolean): string {
        if (enabledBoolean == true) {
            return 'is-info';
        } else {
            return 'is-light';
        }
    }

    dateToString(date: Date) {
        return dateToUiDateTimeString(date);
    }

    dateDiffToString(laterDate: Date, earlierDate: Date) {
        return dateDifferenceToString(new Date(laterDate), new Date(earlierDate));
    }
}
