import { Scopes } from './Scopes';

export class BrowserContext {
    readonly userId: string;
    readonly email: string;
    readonly roles: string[] = [];
    readonly groups: string[];
    idToken: string;
    readonly serviceName: string = '';
    version: string;
    readonly locale: string | undefined;
    readonly scopes: Scopes = {} as Scopes;
    readonly importerId: string | undefined;
    readonly dealerId: string | undefined;
    readonly customerId: string | undefined;
    readonly organizationId: string | undefined;
    readonly unitId: string | undefined;

    constructor(
        userId: string,
        email: string,
        groups: string[],
        roles: string[],
        idToken: string,
        serviceName: string,
        version: string,
        locale: string | undefined,
        scopes: Scopes,
        importerId: string | undefined,
        dealerId: string | undefined,
        customerId: string | undefined,
        organizationId: string | undefined,
        unitId: string | undefined,
    ) {
        this.userId = userId;
        this.email = email;
        this.groups = groups;
        this.roles = roles;
        this.idToken = idToken;
        this.serviceName = serviceName;
        this.version = version;
        this.locale = locale;
        this.scopes = scopes;
        this.importerId = importerId;
        this.dealerId = dealerId;
        this.customerId = customerId;
        this.organizationId = organizationId;
        this.unitId = unitId;
    }
}
