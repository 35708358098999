
import { Component, Vue, Watch } from 'vue-property-decorator';
import { hyphenatedToCamel, lowerCamelToUpperCamel } from '../../../../../../../common/framework/util/convert';
import { AssetType } from '../../../../../../../common/framework/model/AssetType';
import { ValidationObserver } from 'vee-validate';
import { BakeType } from '../../../../../../../common/framework/model/BakeType';
import { getEnumOptions } from '../../../../../service/options';
import { sharedState } from '../../../../../state';
import IntegerField from '../../../../../fields/IntegerField.vue';
import NumberField from '../../../../../fields/NumberField.vue';
import SelectField from '../../../../../fields/SelectField.vue';
import TextAreaField from '../../../../../fields/TextAreaField.vue';
import TextField from '../../../../../fields/TextField.vue';
import AssetBinaryComponent from '../../../../../components/admin/asset/AssetBinaryComponent.vue';
import AssetLinks from '../../../../../components/admin/asset/link/AssetLinks.vue';

@Component({
    components: {
        NumberField,
        IntegerField,
        AssetLinks,
        TextAreaField,
        AssetBinaryComponent,
        SelectField,
        TextField,
        ValidationObserver,
    },
})
export default class UploadBake extends Vue {
    // Refs
    $refs!: {
        observer: Vue;
    };

    shared = sharedState;
    local = {
        file: null as { name: string; size: number; type: string } | null,
        loading: false,
        bakeType: undefined as AssetType | undefined,
        category: '',
        islandMargin: 0.1,
        textureSize: 1024,
        studioFileName: '',
        bakeTypeOptions: [] as { id: string | undefined; label: string }[],
    };

    async mounted() {
        this.local.bakeTypeOptions = getEnumOptions('BakeType', Object.keys(BakeType)).map((o) => {
            return { id: o.id, label: this.$t(o.labelKey).toString() };
        });
    }

    @Watch('local.file')
    async fileChange(file: { name: string; size: number; type: string }) {
        if (file && file.name && (await (this.$refs.observer as any).validate())) {
            this.upload();
        }
    }

    upload() {
        this.local.loading = true;

        const formData = new FormData();
        const elementId = 'bakeUpload';
        formData.append('bakeBinary', (document.getElementById(elementId) as any).files[0]);

        const xhr = new XMLHttpRequest();
        xhr.addEventListener('loadend', (e: Event) => {
            this.local.loading = false;
        });
        xhr.addEventListener('error', () => {
            this.errorToast();
            this.local.loading = false;
        });
        xhr.addEventListener('abort', () => {
            this.local.loading = false;
        });
        xhr.onreadystatechange = () => {
            if (xhr.readyState == 4) {
                if (xhr.status == 200 || xhr.status == 301) {
                    this.$router.go(-1);
                } else {
                    console.warn('Upload returned error status: ' + xhr.status);
                    this.errorToast();
                }
            }
        };

        const url =
            '/api/bake/upload?category=' +
            encodeURI(this.local.category) +
            '&bakeType=' +
            encodeURI('' + this.local.bakeType) +
            '&islandMargin=' +
            encodeURI('' + this.local.islandMargin) +
            '&textureSize=' +
            encodeURI('' + this.local.textureSize) +
            '&studioFileName=' +
            encodeURI(this.local.studioFileName);
        xhr.open('POST', url, true);
        xhr.setRequestHeader('Authorization', 'Bearer ' + sharedState.context.idToken);
        //xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(formData);
    }

    errorToast() {
        this.$buefy.toast.open({
            message: this.$t('error.system.error').toString(),
            type: 'is-danger',
            position: 'is-top',
        });
    }

    convertHyphenatedToCamel(str: string) {
        return lowerCamelToUpperCamel(hyphenatedToCamel(str));
    }

    back() {
        this.$router.go(-1);
    }
}
