
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../../../../framework/state';
import { managerGetUser, managerPutUser } from '../../../../../framework/client/users';
import { getOrganizationOptions, getUnitOptions } from '../../../../service/options';
import { UserInfo } from '../../../../../../common/framework/model/UserInfo';
import { OptionValue } from '../../../../../framework/service/options';
import PhoneField from '../../../../../framework/fields/PhoneField.vue';
import SelectField from '../../../../../framework/fields/SelectField.vue';
import EmailField from '../../../../../framework/fields/EmailField.vue';
import TextField from '../../../../../framework/fields/TextField.vue';
import { ValidationObserver } from 'vee-validate';

@Component({
    components: { TextField, EmailField, SelectField, PhoneField, ValidationObserver },
})
export default class EditCustomerUser extends Vue {
    @Prop(String) public readonly id!: string;

    shared = sharedState;
    local = {
        row: {} as UserInfo,
        localeOptions: [
            { key: 'fi', label: 'FI' },
            { key: 'en', label: 'EN' },
        ],
        customerOptions: [] as OptionValue[],
        organizationOptions: [] as OptionValue[],
        unitOptions: [] as OptionValue[],
    };

    public async mounted() {
        this.local.row = await managerGetUser(this.id);
    }

    @Watch('local.row.customerId')
    public async onCustomerIdChange(newCustomerId: string) {
        if (newCustomerId) {
            this.local.organizationOptions = await getOrganizationOptions(newCustomerId);
            if (this.local.organizationOptions.filter((o) => o.id === this.local.row.organizationId).length === 0) {
                this.local.row = {
                    ...this.local.row,
                    organizationId: undefined,
                };
            }
        } else {
            this.local.organizationOptions = [{ id: undefined, label: '' }];
            this.local.row = {
                ...this.local.row,
                organizationId: undefined,
            };
        }
    }

    @Watch('local.row.organizationId')
    public async onOrganizationIdChange(newOrganizationId: string) {
        if (newOrganizationId) {
            this.local.unitOptions = await getUnitOptions(newOrganizationId);
            if (this.local.unitOptions.filter((o) => o.id === this.local.row.unitId).length === 0) {
                this.local.row = {
                    ...this.local.row,
                    unitId: undefined,
                };
            }
        } else {
            this.local.unitOptions = [{ id: undefined, label: '' }];
            this.local.row = {
                ...this.local.row,
                unitId: undefined,
            };
        }
    }

    public async save() {
        await managerPutUser(
            this.id,
            new UserInfo(
                this.local.row.firstName,
                this.local.row.lastName,
                this.local.row.email,
                this.local.row.phone,
                this.local.row.admin,
                this.local.row.manager,
                this.local.row.configurer,
                this.local.row.dealer,
                this.local.row.orderer,
                this.local.row.approver,
                this.local.row.locale,
                this.local.row.locked,
                this.local.row.importerId,
                this.local.row.dealerId,
                this.local.row.customerId,
                this.local.row.organizationId,
                this.local.row.unitId,
            ),
        );

        await this.$router.push('/customers/users');

        this.$buefy.toast.open({
            message: 'User saved.',
            type: 'is-success',
            position: 'is-top',
        });
    }

    public back() {
        this.$router.go(-1);
    }
}
