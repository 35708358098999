
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../state';
import { postPasswordChange } from '../../client/password';
import PasswordField from '../../fields/PasswordField.vue';
import { ValidationObserver } from 'vee-validate';

const localState = {
    oldPassword: '',
    password: '',
    password2: '',
};

@Component({
    components: { PasswordField, ValidationObserver },
})
export default class PasswordChange extends Vue {
    $refs!: {
        observer: Vue;
    };

    data() {
        return {
            shared: sharedState,
            local: localState,
        };
    }

    async submit() {
        if (localState.password == localState.password2) {
            await this.changePassword();
        } else {
            this.$buefy.toast.open({
                message: this.$t('message.passwordsDoNotMatch').toString(),
                type: 'is-warning',
                position: 'is-top',
            });
        }
    }

    async changePassword() {
        if (await postPasswordChange(localState.password!!, localState.oldPassword!!)) {
            this.$buefy.toast.open({
                message: this.$t('message.passwordChanged').toString(),
                type: 'is-success',
                position: 'is-top',
            });
            localState.oldPassword = '';
            localState.password = '';
            localState.password2 = '';
            (this.$refs.observer as any).reset();
        } else {
            this.$buefy.toast.open({
                message: this.$t('message.passwordChangeFailed').toString(),
                type: 'is-warning',
                position: 'is-top',
            });
        }
    }
}
