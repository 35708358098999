import { sharedState } from '../../state';
import { FrameworkFeature } from '../../../../common/framework/enumeration/FrameworkFeature';
import { NavigationItem } from '../../navigation/NavigationItem';
import Space from '../../../../common/framework/model/Space';
import { getAccessibleSpaces } from '../../service/resource_service';

export async function registerXrNavigation() {
    const settings = sharedState.navigationStartMenus.filter((m) => m.labelKey === 'nav.settings')[0].children;
    const assets = settings.filter((i) => i.labelKey === 'nav.assets')[0];
    settings.splice(settings.indexOf(assets) + 1, 0, {
        icon: undefined,
        label: undefined,
        labelKey: 'nav.bakes',
        url: '/bake',
        dividerBefore: false,
        get visible() {
            return (
                sharedState.admin &&
                sharedState.enabled(FrameworkFeature.ADMIN_API) &&
                sharedState.enabled(FrameworkFeature.XR_BAKES)
            );
        },
        children: [],
    });
}
