import { sharedState } from '../framework/state';
import { FrameworkFeature } from '../../common/framework/enumeration/FrameworkFeature';
import {ApplicationResource} from "../../common/application/enumeration/ApplicationResource";

export async function registerApplicationNavigation() {
    sharedState.navigationStartMenus.unshift(
        {
            icon: 'car',
            label: undefined,
            labelKey: 'nav.fleet',
            url: 'undefined',
            dividerBefore: false,
            get visible() {
                return sharedState.authenticated;
            },
            children: [
                {
                    icon: 'basket',
                    label: undefined,
                    labelKey: 'nav.orders',
                    url: '/orders',
                    dividerBefore: false,
                    get visible() {
                        return sharedState.admin || sharedState.orderer || sharedState.approver || sharedState.dealer || sharedState.configurer;
                    },
                    children: [],
                },
                {
                    icon: 'plus-circle',
                    label: undefined,
                    labelKey: 'nav.addOrder',
                    url: '/orders/select-vehicle',
                    dividerBefore: false,
                    get visible() {
                        return sharedState.admin || sharedState.orderer;
                    },
                    children: [],
                },
            ],
        },
        {
            icon: 'wrench',
            label: undefined,
            labelKey: 'nav.configuration',
            url: undefined,
            dividerBefore: false,
            get visible() {
                return sharedState.admin || sharedState.configurer;
            },
            children: [
                {
                    icon: undefined,
                    label: undefined,
                    labelKey: 'nav.retrofittedAccessories',
                    url: '/retrofitted-accessories',
                    dividerBefore: false,
                    get visible() {
                        return sharedState.admin || sharedState.configurer;
                    },
                    children: [],
                },
                {
                    icon: undefined,
                    label: undefined,
                    labelKey: 'nav.customerDealers',
                    url: '/customer-dealers',
                    dividerBefore: false,
                    get visible() {
                        return sharedState.admin || sharedState.configurer;
                    },
                    children: [],
                },
                {
                    icon: undefined,
                    label: undefined,
                    labelKey: 'nav.tailoredVehicleModels',
                    url: '/tailored-vehicle-models',
                    dividerBefore: false,
                    get visible() {
                        return sharedState.admin || sharedState.configurer;
                    },
                    children: [],
                },
            ],
        },
        {
            icon: 'settings-outline',
            label: undefined,
            labelKey: 'nav.fleetManagementSettings',
            url: undefined,
            dividerBefore: false,
            get visible() {
                return sharedState.admin || sharedState.manager;
            },
            children: [
                {
                    icon: undefined,
                    label: undefined,
                    labelKey: 'nav.importers',
                    url: '/importers',
                    dividerBefore: false,
                    get visible() {
                        return sharedState.admin;
                    },
                    children: [],
                },
                {
                    icon: undefined,
                    label: undefined,
                    labelKey: 'nav.dealers',
                    url: '/dealers',
                    dividerBefore: false,
                    get visible() {
                        return sharedState.admin;
                    },
                    children: [],
                },
                {
                    icon: undefined,
                    label: undefined,
                    labelKey: 'nav.customers',
                    url: '/customers',
                    dividerBefore: false,
                    get visible() {
                        return sharedState.admin;
                    },
                    children: [],
                },
                {
                    icon: undefined,
                    label: undefined,
                    labelKey: 'nav.notifications',
                    url: `/${ApplicationResource.NOTIFICATION_GROUP}`,
                    dividerBefore: false,
                    get visible() {
                        return sharedState.admin;
                    },
                    children: [],
                },
                {
                    icon: undefined,
                    label: undefined,
                    labelKey: 'nav.users',
                    url: '/customers/users',
                    dividerBefore: false,
                    get visible() {
                        return sharedState.admin || sharedState.manager;
                    },
                    children: [],
                },
                {
                    icon: undefined,
                    label: undefined,
                    labelKey: 'nav.organizations',
                    url: '/organizations',
                    dividerBefore: false,
                    get visible() {
                        return sharedState.admin || sharedState.manager;
                    },
                    children: [],
                },
            ],
        },
    );
}
