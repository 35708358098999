
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../framework/state';
import { deleteResource, getResourcePaging, getResources } from '../../../../framework/client/resource';
import { getCustomerOptions } from '../../../service/options';
import CustomerContextSelector from '../context/CustomerContextSelector.vue';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';
import { getOptionLabel, OptionValue } from '../../../../framework/service/options';
import { TailoredVehicleModel } from '../../../../../common/application/model/TailoredVehicleModel';
import { PAGE_SIZE } from '../../../../../common/framework/constants';

@Component({
    components: { CustomerContextSelector },
})
export default class TailoredVehicleModels extends Vue {
    readonly resourceType = ApplicationResource.TAILORED_VEHICLE_MODEL;

    shared = sharedState;
    local = {
        rows: new Array<TailoredVehicleModel>(),
        total: 0,
        loading: false,
        page: 1,
        perPage: PAGE_SIZE,
        selected: undefined as TailoredVehicleModel | undefined,
        customerOptions: [] as OptionValue[],
    };

    async mounted() {
        this.local.customerOptions = await getCustomerOptions();
        await this.loadAsyncData();

        document.addEventListener('selected-customer-changed', this.selectedCustomerChanged);
    }

    async destroyed() {
        document.removeEventListener('selected-customer-changed', this.selectedCustomerChanged);
    }

    async selectedCustomerChanged() {
        await this.loadAsyncData();
    }

    async onPageChange(page: number) {
        this.local.page = page;
        await this.loadAsyncData();
    }

    add() {
        this.$router.push('/tailored-vehicle-models/add');
    }

    edit() {
        if (this.local.selected) {
            this.$router.push('/tailored-vehicle-models/edit/' + this.local.selected.id);
        }
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    await deleteResource(this.resourceType, this.local.selected.id);
                    await this.loadAsyncData();
                }
            },
        });
    }

    async loadAsyncData() {
        this.local.loading = true;
        if (this.shared.context.importerId) {
            this.local.total = (await getResourcePaging(this.resourceType, this.getFilter())).pageCount * PAGE_SIZE;
            this.local.rows = [];
            const rows = (await getResources(
                this.resourceType,
                this.local.page - 1,
                this.getFilter(),
            )) as Array<TailoredVehicleModel>;
            rows.forEach((row) => {
                this.local.rows.push(row);
            });
        }
        this.local.loading = false;
        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }
    }

    private getFilter(): Map<string, string> {
        const map = new Map<string, string>();
        map.set('importerId', this.shared.context.importerId!!);

        if (this.shared.customerIdFilter) {
            map.set('customerId', this.shared.customerIdFilter);
        }

        return map;
    }

    getLabel(id: string, options: { id: string | undefined; label: string }[]) {
        return getOptionLabel(id, options);
    }

    get tooltipLabel(): string {
        if (!this.shared.context.importerId) {
            return this.$t('message.noImporter').toString();
        } else {
            return this.$t('message.addNewTailoredVehicle').toString();
        }
    }

    onRowClick(row: TailoredVehicleModel) {
        if (this.local.selected && row.id === this.local.selected.id) {
            this.local.selected = undefined;
        }
    }
}
