
import { Component, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../../../../state';
import { PAGE_SIZE } from '../../../../../../../common/framework/constants';

import { deleteResource, getResource, getResourcePaging, getResources } from '../../../../../client/resource';
import Entity from '../../../../../../../common/framework/model/Entity';
import Space from '../../../../../../../common/framework/model/Space';
import { FrameworkResource } from '../../../../../../../common/framework/enumeration/FrameworkResource';
import Asset from '../../../../../../../common/framework/model/Asset';
import { UserInfo } from '../../../../../../../common/framework/model/UserInfo';
import SpaceSelect from '../../../fields/SpaceSelect.vue';
import { getResourceLabels } from '../../../../../service/label_service';

@Component({
    components: { SpaceSelect },
})
export default class Entities extends Vue {
    readonly resourceType = 'entity';

    shared = sharedState;
    local = {
        rows: new Array<Entity>(),
        total: 0,
        loading: false,
        page: 1,
        perPage: PAGE_SIZE,
        selected: undefined as Entity | undefined,
        spaceId: undefined as string | undefined,
    };
    spaceLabels: Map<string, string> = new Map();
    assetLabels: Map<string, string> = new Map();
    entityLabels: Map<string, string> = new Map();

    async mounted() {
        await this.loadAsyncData();
    }

    async onPageChange(page: number) {
        this.local.page = page;
        await this.loadAsyncData();
    }

    add() {
        this.$router.push('/entity/add');
    }

    edit() {
        if (this.local.selected) {
            this.$router.push('/entity/' + this.local.selected.id + '/edit');
        }
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    await deleteResource(this.resourceType, this.local.selected.id);
                    await this.loadAsyncData();
                }
            },
        });
    }

    @Watch('local.spaceId')
    spaceSelected() {
        this.loadAsyncData();
    }

    async loadAsyncData() {
        this.local.loading = true;

        const parameters = new Map<string, string>();
        if (this.local.spaceId) {
            parameters.set('spaceId', this.local.spaceId);
        }

        this.local.total = (await getResourcePaging(this.resourceType, parameters)).pageCount * PAGE_SIZE;
        this.local.rows = [];
        const rows = (await getResources(this.resourceType, this.local.page - 1, parameters)) as Array<Entity>;

        this.spaceLabels = await getResourceLabels(rows, 'spaceId', FrameworkResource.SPACE, 'name');
        this.assetLabels = await getResourceLabels(rows, 'assetId', FrameworkResource.ASSET, 'name');
        this.entityLabels = await getResourceLabels(rows, 'parentId', FrameworkResource.ENTITY, 'name');

        rows.forEach((row) => {
            this.local.rows.push(row);
        });

        this.local.loading = false;
        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }
    }

    getSpaceName(spaceId: string) {
        if (this.spaceLabels.has(spaceId)) {
            return this.spaceLabels.get(spaceId);
        } else {
            return spaceId;
        }
    }

    getAssetName(assetId: string) {
        if (this.assetLabels.has(assetId)) {
            return this.assetLabels.get(assetId);
        } else {
            return assetId;
        }
    }

    getParentName(parentId: string) {
        if (this.entityLabels.has(parentId)) {
            return this.entityLabels.get(parentId);
        } else {
            return parentId;
        }
    }
}
