
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../../../state';
import { getResource, postResource } from '../../../../client/resource';
import TextField from '../../../../fields/TextField.vue';
import AssetLink from '../../../../../../common/framework/model/AssetLink';
import { ValidationObserver } from 'vee-validate';
import AssetSelect from '../../../../fields/AssetSelect.vue';
import Asset from '../../../../../../common/framework/model/Asset';
import { FrameworkResource } from '../../../../../../common/framework/enumeration/FrameworkResource';

@Component({
    components: { TextField, AssetSelect, ValidationObserver },
})
export default class AddAssetLink extends Vue {
    // Constants
    readonly resourceType = 'asset-link';

    // Properties
    @Prop(String) readonly parentAssetId!: string;

    // State
    shared = sharedState;
    local = {
        row: {} as AssetLink,
    };

    // Functions
    async mounted() {
        this.local.row = {
            ...this.local.row,
            parentAssetId: this.parentAssetId,
        };
    }

    @Watch('local.row.childAssetId')
    async childAssetIdChange(newChildAssetId: string) {
        if (newChildAssetId) {
            this.local.row = {
                ...this.local.row,
                alias: (await getResource<Asset>(FrameworkResource.ASSET, newChildAssetId))!!.name,
            };
        } else {
            this.local.row = {
                ...this.local.row,
                alias: '',
            };
        }
    }

    async add() {
        const addedRow = await postResource<AssetLink>(this.resourceType, this.local.row);
        this.$router.go(-1);
    }

    back() {
        this.$router.go(-1);
    }
}
