
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../framework/state';
import { getFieldNameFromVModelProperty } from '../util/component_util';
import { ValidationProvider } from 'vee-validate';

@Component({
    components: { ValidationProvider },
})
export default class SwitchField extends Vue {
    @Prop(String) readonly name!: string;
    @Prop(Boolean) readonly value!: boolean;
    @Prop(Boolean) readonly readonly!: string;
    @Prop(Boolean) readonly required!: boolean;
    @Prop(Boolean) readonly horizontal!: boolean;
    @Prop(Boolean) readonly disabled!: string;
    @Prop(String) readonly type!: string;

    shared = sharedState;
    local = {
        value: undefined as boolean | undefined,
    };

    async mounted() {
        await this.modelValueChange(this.value);
    }

    @Watch('value')
    async modelValueChange(newValue: boolean) {
        this.local.value = newValue;
    }

    @Watch('local.value')
    async inputValueChange(newValue: boolean) {
        this.$emit('input', newValue);
    }

    getFieldName() {
        return getFieldNameFromVModelProperty(this);
    }
}
