
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../state';
import { postRequestTokenPasswordChange } from '../../client/password';
import EmailField from '../../fields/EmailField.vue';
import { ValidationObserver } from 'vee-validate';

@Component({
    components: { EmailField, ValidationObserver },
})
export default class RequestPasswordChange extends Vue {
    shared = sharedState;
    local = {
        email: undefined,
    };

    async requestPasswordChange() {
        if (await postRequestTokenPasswordChange(this.local.email!!)) {
            this.$buefy.toast.open({
                type: 'is-success',
                message: this.$t('message.passwordChangeInstructionsSent').toString(),
            });
            this.local.email = undefined;
            await this.$router.push('/');
        } else {
            this.$buefy.toast.open({
                type: 'is-warning',
                message: this.$t('message.passwordChangeInstructionsSendFailed').toString(),
            });
        }
    }

    async submit() {
        await this.requestPasswordChange();
    }

    back() {
        this.$router.go(-1);
    }
}
