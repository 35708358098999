import { EngineContext } from '../model/EngineContext';
import { EngineEntity } from '../model/EngineEntity';
import { EntityMove } from '../../../../../common/framework/module/xr/network/model/EntityMove';
import { getSpaceChannelId } from '../../communication/service/channel_service';
import { sendEntityMoveMessage } from '../../communication/service/network_service';

export async function sendAvatarParts(context: EngineContext) {
    const state = context.state;

    const avatarEntities: Array<EngineEntity> = [];
    if (state.avatarHead) {
        context.renderer.readAvatarHeadPositionAndOrientation(state.avatarHead);
        avatarEntities.push(state.avatarHead);
    }
    if (state.avatarLeftHand) {
        context.renderer.readAvatarLeftHandPositionAndOrientation(state.avatarLeftHand);
        avatarEntities.push(state.avatarLeftHand);
    }
    if (state.avatarRightHand) {
        context.renderer.readAvatarRightHandPositionAndOrientation(state.avatarRightHand);
        avatarEntities.push(state.avatarRightHand);
    }
    if (state.avatarTorso) {
        context.renderer.readAvatarTorsoPositionAndOrientation(state.avatarTorso);
        avatarEntities.push(state.avatarTorso);
    }

    sendEntityMove(avatarEntities, context);
}

function sendEntityMove(entities: EngineEntity[], context: EngineContext) {
    const channelId = getSpaceChannelId();
    const entityMoves: Array<EntityMove> = [];
    for (let entity of entities) {
        if (entity.visible) {
            entityMoves.push({
                Id: entity.id,
                X: Math.trunc(entity.x * 100),
                Y: Math.trunc(entity.y * 100),
                Z: Math.trunc(entity.z * 100),
                RX: Math.trunc(entity.rx * 1000),
                RY: Math.trunc(entity.ry * 1000),
                RZ: Math.trunc(entity.rz * 1000),
                RW: Math.trunc(entity.rw * 1000),
            });
        }
    }
    const entityMove = {
        UserId: context.state.userId,
        ChannelId: channelId,
        Moves: entityMoves,
    };
    sendEntityMoveMessage(entityMove);
}
