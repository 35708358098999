
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../state';
import { PAGE_SIZE } from '../../../common/framework/constants';
import { FrameworkResource } from '../../../common/framework/enumeration/FrameworkResource';
import { getResource, getResources } from '../client/resource';
import { ValidationProvider } from 'vee-validate';
import { getFieldNameFromVModelProperty } from '../util/component_util';
import Asset from '../../../common/framework/model/Asset';

@Component({
    components: { ValidationProvider },
})
export default class AssetSelect extends Vue {
    @Prop(String) name!: string;
    @Prop(String) readonly value!: string;
    @Prop(Boolean) readonly required!: boolean;
    @Prop(Boolean) readonly disabled!: string;

    shared = sharedState;
    local = {
        rows: new Array<Asset>(),
        total: 0,
        loading: false,
        page: 1,
        perPage: PAGE_SIZE,
        selected: undefined as Asset | undefined,
        typed: '',
        selectedId: undefined as string | undefined,
    };

    objects: Asset[] = [];

    async mounted() {
        this.objects = (await getResources(FrameworkResource.ASSET, -1)) as Asset[];
        if (this.local.selectedId) {
            this.local.typed = (await getResource<Asset>(FrameworkResource.ASSET, this.local.selectedId))!!.name;
        }
        await this.modelValueChange(this.value);
    }

    @Watch('value')
    async modelValueChange(newValue: string) {
        this.local.selectedId = newValue;
        if (this.local.selectedId) {
            this.local.typed = (await getResource<Asset>(FrameworkResource.ASSET, this.local.selectedId))!!.name;
        } else {
            this.local.typed = '';
        }
    }

    @Watch('local.selectedId')
    async selectedIChange(newValue: string) {
        this.$emit('input', newValue);
    }

    get filteredObjects() {
        return [
            {
                id: undefined as undefined | string,
                name: '-',
            },
        ].concat(
            this.objects
                .filter((o) => {
                    return o.name.toString().toLowerCase().indexOf(this.local.typed.toLowerCase()) != -1;
                })
                .map((o) => {
                    return {
                        id: o.id as undefined | string,
                        name: o.name,
                    };
                }),
        );
    }

    getFieldName() {
        return getFieldNameFromVModelProperty(this);
    }
}
