import { sharedState } from '../../state';
import { FrameworkFeature } from '../../../../common/framework/enumeration/FrameworkFeature';
import { NavigationItem } from '../../navigation/NavigationItem';
import Space from '../../../../common/framework/model/Space';
import { getAccessibleSpaces } from '../../service/resource_service';
import { setGlobalSpace } from './space_service';

const spaceNavigationItems: NavigationItem[] = [];
const spaceNavigationMenu: NavigationItem = {
    icon: 'map-outline',
    label: undefined,
    labelKey: 'nav.company',
    url: undefined,
    dividerBefore: false,
    get visible() {
        return sharedState.enabled(FrameworkFeature.XR_SPACES) && spaceNavigationItems.length > 0;
    },
    children: spaceNavigationItems,
};

export async function registerSpaceNavigation() {
    if (
        sharedState.enabled(FrameworkFeature.XR_SPACES) &&
        (!sharedState.anonymous || sharedState.enabled(FrameworkFeature.XR_SPACES_PUBLIC_LIST))
    ) {
        sharedState.navigationStartMenus.unshift(spaceNavigationMenu);
    }
    await loadSpaceNavigationItems();

    const settingsMenu = sharedState.navigationStartMenus.filter((m) => m.labelKey === 'nav.settings')[0];
    sharedState.navigationStartMenus.splice(sharedState.navigation.indexOf(settingsMenu), 0, {
        icon: 'cube-scan',
        label: undefined,
        labelKey: 'nav.inventory',
        url: undefined,
        dividerBefore: false,
        get visible() {
            return (
                sharedState.admin &&
                (sharedState.enabled(FrameworkFeature.DATA_SPACES) || sharedState.enabled(FrameworkFeature.XR_SPACES))
            );
        },
        children: [
            {
                icon: 'inbox-multiple-outline',
                label: undefined,
                labelKey: 'nav.spaces',
                url: '/space',
                dividerBefore: false,
                get visible() {
                    return sharedState.admin && sharedState.enabled(FrameworkFeature.ADMIN_API);
                },
                children: [],
            },
            {
                icon: 'sim',
                label: undefined,
                labelKey: 'nav.entities',
                url: '/entity',
                dividerBefore: false,
                get visible() {
                    return sharedState.admin && sharedState.enabled(FrameworkFeature.ADMIN_API);
                },
                children: [],
            },
        ],
    });
}

let loading = false;
export async function loadSpaceNavigationItems() {
    if (
        sharedState.enabled(FrameworkFeature.XR_SPACES) &&
        (!sharedState.anonymous || sharedState.enabled(FrameworkFeature.XR_SPACES_PUBLIC_LIST))
    ) {
        if (loading) {
            return;
        }
        loading = true;
        spaceNavigationItems.splice(0, spaceNavigationItems.length);
        const spaces = await getAccessibleSpaces();
        if (spaces.length > 0) {
            await setGlobalSpace(spaces[0].id);
        }
        for (const space of spaces) {
            spaceNavigationItems.push({
                icon: undefined,
                label: space.name,
                labelKey: undefined,
                url: `/space/${space.id}/view`,
                dividerBefore: false,
                visible: true,
                children: [],
            });
        }
        loading = false;
    }
}

export function setSpaceNavigationMenuLabel(space: Space) {
    (spaceNavigationMenu as any).label = space.name;
}
