import { render, staticRenderFns } from "./ApiTokens.vue?vue&type=template&id=4e79f0d3&scoped=true&"
import script from "./ApiTokens.vue?vue&type=script&lang=ts&"
export * from "./ApiTokens.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e79f0d3",
  null
  
)

export default component.exports