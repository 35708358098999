
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { sharedState } from '../../../../framework/state';
import { getResource, putResource } from '../../../../framework/client/resource';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';
import { Importer } from '../../../../../common/application/model/Importer';
import TextField from '../../../../framework/fields/TextField.vue';

@Component({
    components: { TextField, ValidationObserver },
})
export default class EditImporter extends Vue {
    readonly resourceType = ApplicationResource.IMPORTER;

    @Prop(String) readonly id!: string;

    shared = sharedState;
    local = {
        row: {} as Importer,
    };

    async mounted() {
        this.local.row = (await getResource(this.resourceType, this.id))!! as Importer;
    }

    async save() {
        await putResource(this.resourceType, this.id, this.local.row);
        this.$router.go(-1);
    }

    back() {
        this.$router.go(-1);
    }
}
