
import { Component, Prop, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../framework/state';
import { deleteResource, getResourcePaging, getResources } from '../../../../framework/client/resource';
import { Organization } from '../../../../../common/application/model/Organization';
import { PAGE_SIZE } from '../../../../../common/framework/constants';
import { Customer } from '../../../../../common/application/model/Customer';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';

@Component
export default class CustomerOrganizations extends Vue {
    readonly resourceType = ApplicationResource.ORGANIZATION;

    @Prop(String) readonly customerId!: string;

    shared = sharedState;
    local = {
        rows: new Array<Organization>(),
        customers: new Map<string, Customer>(),
        customerId: '',
        total: 0,
        loading: false,
        page: 1,
        perPage: PAGE_SIZE,
        selected: undefined as Organization | undefined,
    };

    async mounted() {
        if (this.customerId) {
            this.local.customerId = this.customerId;
        } else if (this.shared.context.customerId) {
            this.local.customerId = this.shared.context.customerId;
        }

        if (!this.local.customerId.length) {
            return;
        }

        await this.loadAsyncData();
    }

    async onPageChange(page: number) {
        this.local.page = page;
        await this.loadAsyncData();
    }

    add() {
        this.$router.push('/customers/' + this.local.customerId + '/organizations/add');
    }

    edit() {
        if (this.local.selected) {
            this.$router.push('/organizations/edit/' + this.local.selected.id);
        }
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    await deleteResource(this.resourceType, this.local.selected.id);
                    await this.loadAsyncData();
                }
            },
        });
    }

    onRowClick(row: Organization) {
        if (this.local.selected && row.id === this.local.selected.id) {
            this.local.selected = undefined;
        }
    }

    private async loadAsyncData() {
        this.local.loading = true;

        const customers = await getResources<Customer>('customer', 0, new Map([['id', this.local.customerId]]));
        this.local.customers = new Map(customers.map((c) => [c.id, c] as any)) as Map<string, Customer>;

        this.local.total = (
            await getResourcePaging(this.resourceType, new Map([['customerId', this.local.customerId]]))
        ).rowCount;
        this.local.rows = [];
        const rows = await getResources<Organization>(
            this.resourceType,
            this.local.page - 1,
            new Map([['customerId', this.local.customerId]]),
        );
        rows.forEach((row) => {
            this.local.rows.push(row);
        });
        this.local.loading = false;
        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }
    }
}
