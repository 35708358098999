
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../framework/state';
import { queryOrdersByLicensePlate } from '../client/order';
import { ValidationProvider } from 'vee-validate';

@Component({
    components: { ValidationProvider },
})
export default class SelectOrderByLicensePlate extends Vue {
    @Prop(String) readonly value!: string;

    shared = sharedState;
    local = {
        licensePlate: undefined as string | undefined,
    };

    async mounted() {}

    @Watch('local.licensePlate')
    async selectedIChange(newValue: string) {
        if (newValue === undefined || newValue === '') {
            this.$emit('input', undefined);
            return;
        }
        const orders = await queryOrdersByLicensePlate(newValue);
        if (orders.length > 0) {
            this.$emit('input', orders[0].id);
        } else {
            this.$emit('input', undefined);
        }
    }
}
