
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../../../../../framework/state';
import { getResource, putResource } from '../../../../../../framework/client/resource';
import { getFactoryAccessoryCategoryOptions, getFactoryAccessoryOptions } from '../../../../../service/options';
import { getVehicleModelInfo } from '../../../../../client/vehicle_model_client';
import SelectField from '../../../../../../framework/fields/SelectField.vue';
import TextField from '../../../../../../framework/fields/TextField.vue';
import { ApplicationResource } from '../../../../../../../common/application/enumeration/ApplicationResource';
import { FactoryAccessoryCategory } from '../../../../../../../common/application/model/erp/FactoryAccessoryCategory';
import { TailoredVehicleModel } from '../../../../../../../common/application/model/TailoredVehicleModel';
import { TailoredFactoryAccessory } from '../../../../../../../common/application/model/TailoredFactoryAccessory';
import { getOptionLabelKey, OptionValue } from '../../../../../../framework/service/options';
import { ValidationObserver } from 'vee-validate';

@Component({
    components: { TextField, SelectField, ValidationObserver },
})
export default class EditTailoredFactoryAccessory extends Vue {
    readonly resourceType = ApplicationResource.TAILORED_FACTORY_ACCESSORY;

    @Prop(String) readonly id!: string;

    shared = sharedState;
    local = {
        row: {} as TailoredFactoryAccessory,
        category: '',
        tailoredVehicleModel: {} as TailoredVehicleModel | undefined,
        factoryAccessoryOptions: [] as OptionValue[],
        factoryAccessoryCategoryOptions: [] as Array<{ id: undefined | string; labelKey: string }>,
        loading: false,
    };

    async mounted() {
        await this.loadAsyncData();
    }

    @Watch('local.row.externalId')
    async externalIdChanged(externalId: string) {
        if (this.local.loading) {
            return;
        }

        if (externalId) {
            const vehicleModelInfo = await getVehicleModelInfo(this.local.tailoredVehicleModel!!.modelExternalId);
            const factoryAccessory = vehicleModelInfo.factoryAccessories.filter(
                (o) => o.oid.toString() === externalId,
            )[0];
            this.local.row = {
                ...this.local.row,
                vendorFactoryAccessoryCode: factoryAccessory.code,
            };
            this.local.category = factoryAccessory.category;
            this.local.category = this.$t(
                getOptionLabelKey(factoryAccessory.category, this.local.factoryAccessoryCategoryOptions),
            ).toString();
        } else {
            this.local.row = {
                ...this.local.row,
                vendorFactoryAccessoryCode: '',
            };
            this.local.category = FactoryAccessoryCategory.DEFAULT;
            this.local.category = '';
        }
    }

    async save() {
        await putResource(this.resourceType, this.id, this.local.row);
        this.$router.go(-1);
    }

    back() {
        this.$router.go(-1);
    }

    private async loadAsyncData(): Promise<void> {
        this.local.loading = true;

        this.local.row = (await getResource(this.resourceType, this.id))!! as TailoredFactoryAccessory;
        this.local.tailoredVehicleModel = (await getResource<TailoredVehicleModel>(
            ApplicationResource.TAILORED_VEHICLE_MODEL,
            this.local.row.tailoredVehicleModelId,
        ))!!;
        this.local.factoryAccessoryOptions = await getFactoryAccessoryOptions(
            this.local.tailoredVehicleModel.modelExternalId,
        );
        this.local.factoryAccessoryCategoryOptions = getFactoryAccessoryCategoryOptions();

        this.local.loading = false;
    }
}
