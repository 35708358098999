import { apiGet, apiPost } from '../../framework/client';

import { ERROR_API_CALL_FAILED } from '../../../common/framework/constants';

import { OrderAddRequest } from '../../../common/application/model/OrderAddRequest';
import { Order } from '../../../common/application/model/Order';
import { RejectOrderRequest } from '../../../common/application/model/RejectOrderRequest';
import { SendOrderDetailsEmailRequest } from '../../../common/application/model/SendOrderDetailsEmailRequest';
import { Person } from '../../../common/application/model/Person';

export async function postOrderAddRequest(orderAddRequest: OrderAddRequest): Promise<Order> {
    const response = await apiPost('/api/orderer/orders', orderAddRequest);
    if (response.status === 200) {
        return (await response.json()) as Order;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function postOrderUpdateRequest(order: Order): Promise<Order> {
    const response = await apiPost('/api/user/orders/' + order.id, order);
    if (response.status === 200) {
        return (await response.json()) as Order;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function postOrderRejectRequest(orderId: string, rejectReason: string): Promise<Order> {
    const rejectOrderRequest: RejectOrderRequest = {
        rejectReason,
    };
    const response = await apiPost('/api/approver/orders/' + orderId + '/reject', rejectOrderRequest);
    if (response.status === 200) {
        return (await response.json()) as Order;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function postOrderApproveRequest(orderId: string): Promise<Order> {
    const response = await apiPost('/api/approver/orders/' + orderId + '/approve', {});
    if (response.status === 200) {
        return (await response.json()) as Order;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function postOrderDeliverRequest(orderId: string): Promise<Order> {
    const response = await apiPost('/api/configurer/orders/' + orderId + '/deliver', {});
    if (response.status === 200) {
        return (await response.json()) as Order;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function postOrderConfirmRequest(orderId: string): Promise<Order> {
    const response = await apiPost('/api/configurer/orders/' + orderId + '/confirm', {});
    if (response.status === 200) {
        return (await response.json()) as Order;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function postOrderCancelRequest(orderId: string): Promise<Order> {
    const response = await apiPost('/api/orderer/orders/' + orderId + '/cancel', {});
    if (response.status === 200) {
        return (await response.json()) as Order;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function postSendOrderDetailsRequest(orderId: string, email: string): Promise<Order> {
    const request: SendOrderDetailsEmailRequest = {
        email,
    };
    const response = await apiPost('/api/user/orders/' + orderId + '/send-details', request);
    if (response.status === 200) {
        return (await response.json()) as Order;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getSalesPersons<T>(dealerId: string): Promise<Person[]> {
    const response = await apiGet(`/api/user/dealers/${dealerId}/sales-persons`);
    if (response.status === 200) {
        return (await response.json()) as Person[];
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getSalesPerson<T>(salesPersonId: string): Promise<Person> {
    const response = await apiGet(`/api/user/sales-persons/${salesPersonId}`);
    if (response.status === 200) {
        return (await response.json()) as Person;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getOrdererPerson<T>(ordererPersonId: string): Promise<Person> {
    const response = await apiGet(`/api/user/orderer-persons/${ordererPersonId}`);
    if (response.status === 200) {
        return (await response.json()) as Person;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function queryOrdersByLicensePlate<T>(licensePlate: string): Promise<Order[]> {
    const response = await apiGet('/api/user/query/order', { licensePlate });
    if (response.status === 200) {
        return (await response.json()) as Order[];
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}
