
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { sharedState } from '../../../../framework/state';
import { getResource, putResource } from '../../../../framework/client/resource';
import { postOrderConfirmRequest } from '../../../client/order';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';
import { TailoredRetrofittedAccessory } from '../../../../../common/application/model/TailoredRetrofittedAccessory';
import { Vehicle } from '../../../../../common/application/model/Vehicle';
import TextField from '../../../../framework/fields/TextField.vue';

@Component({
    components: { TextField, ValidationObserver },
})
export default class EditVehicle extends Vue {
    readonly resourceType = ApplicationResource.VEHICLE;

    @Prop(String) readonly id!: string;

    shared = sharedState;
    local = {
        row: {} as Vehicle,
    };

    async mounted() {
        this.local.row = (await getResource<Vehicle>(this.resourceType, this.id))!!;
    }

    async save() {
        const row = (await getResource<Vehicle>(this.resourceType, this.id))!!;
        const originalRow = { ...row };

        await putResource(this.resourceType, this.id, this.local.row);

        if (
            (originalRow.licensePlate === '' || !originalRow.licensePlate) &&
            row.licensePlate !== '' &&
            row.licensePlate
        ) {
            await postOrderConfirmRequest(this.local.row.orderId);
        }

        await this.$router.push('/orders');
    }

    back() {
        this.$router.push('/orders');
    }
}
