import { sharedState } from './state';
import { FrameworkFeature } from '../../common/framework/enumeration/FrameworkFeature';

export function registerFrameworkNavigation() {
    sharedState.navigationStartMenus.unshift({
        icon: 'settings-outline',
        label: undefined,
        labelKey: 'nav.settings',
        url: undefined,
        dividerBefore: false,
        get visible() {
            return sharedState.admin && sharedState.enabled(FrameworkFeature.ADMIN_API);
        },
        children: [
            {
                icon: undefined,
                label: undefined,
                labelKey: 'nav.users',
                url: '/users',
                dividerBefore: false,
                get visible() {
                    return sharedState.admin && sharedState.enabled(FrameworkFeature.ADMIN_API);
                },
                children: [],
            },
            {
                icon: undefined,
                label: undefined,
                labelKey: 'nav.groups',
                url: '/groups',
                dividerBefore: false,
                get visible() {
                    return sharedState.admin && sharedState.enabled(FrameworkFeature.ADMIN_API);
                },
                children: [],
            },
            {
                icon: undefined,
                label: undefined,
                labelKey: 'nav.roles',
                url: '/roles',
                dividerBefore: false,
                get visible() {
                    return sharedState.admin && sharedState.enabled(FrameworkFeature.ADMIN_API);
                },
                children: [],
            },
            {
                icon: undefined,
                label: undefined,
                labelKey: 'nav.assets',
                url: '/asset',
                dividerBefore: true,
                get visible() {
                    return sharedState.admin && sharedState.enabled(FrameworkFeature.ADMIN_API);
                },
                children: [],
            },
            {
                icon: undefined,
                label: undefined,
                labelKey: 'nav.jobs',
                url: '/job',
                dividerBefore: true,
                get visible() {
                    return sharedState.admin && sharedState.enabled(FrameworkFeature.ADMIN_API);
                },
                children: [],
            },
            {
                icon: undefined,
                label: undefined,
                labelKey: 'nav.nodes',
                url: '/node',
                dividerBefore: false,
                get visible() {
                    return sharedState.admin && sharedState.enabled(FrameworkFeature.ADMIN_API);
                },
                children: [],
            },
            {
                icon: undefined,
                label: undefined,
                labelKey: 'nav.options',
                url: '/option',
                dividerBefore: true,
                get visible() {
                    return sharedState.admin && sharedState.enabled(FrameworkFeature.ADMIN_API);
                },
                children: [],
            },
        ],
    });

    sharedState.navigationEndMenus.unshift({
        icon: 'account-outline',
        label: undefined,
        labelKey: 'nav.profile',
        url: undefined,
        dividerBefore: false,
        get visible() {
            return sharedState.authenticated;
        },
        children: [
            {
                icon: undefined,
                label: undefined,
                labelKey: 'nav.passwordChange',
                url: '/profile/password-change',
                dividerBefore: false,
                get visible() {
                    return sharedState.authenticated && sharedState.enabled(FrameworkFeature.PASSWORD_AUTHENTICATION);
                },
                children: [],
            },
            {
                icon: undefined,
                label: undefined,
                labelKey: 'nav.apiTokens',
                url: '/api-token',
                dividerBefore: false,
                get visible() {
                    return sharedState.authenticated;
                },
                children: [],
            },
            {
                icon: undefined,
                label: undefined,
                labelKey: 'nav.editProfile',
                url: '/profile/edit',
                dividerBefore: false,
                get visible() {
                    return sharedState.authenticated;
                },
                children: [],
            },
        ],
    });
}

/*
            <div
              v-if="shared.authenticated && shared.enabled('password-authentication')"
              class="navbar-item has-dropdown is-hoverable mobile-modal">

              <a class="navbar-link">
                <b-icon icon="account-outline" size="is-small" class="nav-icon"></b-icon>
                <span>{{$t('nav.profile')}}</span>
              </a>

              <div class="navbar-dropdown">
                <router-link
                  v-if="shared.authenticated && shared.enabled('password-authentication')"
                  class="navbar-item"
                  to="/profile/password-change">
                  <span>{{$t('nav.passwordChange')}}</span>
                </router-link>

                <router-link v-if="shared.authenticated"
                        class="navbar-item" to="/api-token">
                  <span>{{$t('nav.apiTokens')}}</span>
                </router-link>

                <router-link v-if="shared.authenticated"
                             class="navbar-item" to="/profile/edit">
                  <span>{{$t('nav.editProfile')}}</span>
                </router-link>
              </div>
            </div>
 */
