
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../state';
import { FrameworkFeature } from '../../../../common/framework/enumeration/FrameworkFeature';
import EmailField from '../../fields/EmailField.vue';
import { ValidationObserver } from 'vee-validate';
import PasswordField from '../../fields/PasswordField.vue';
import { passwordLogin } from '../../service/authentication_service';
import { ApplicationFeature } from '../../../../common/application/enumeration/ApplicationFeature';
@Component({
    components: { EmailField, PasswordField, ValidationObserver },
})
export default class LoginForm extends Vue {
    shared = sharedState;
    local = {
        email: '',
        password: '',
    };

    mounted() {
        if (
            !sharedState.enabled(FrameworkFeature.PASSWORD_AUTHENTICATION) &&
            sharedState.enabled(FrameworkFeature.SAML_AUTHENTICATION)
        ) {
            window.location.href = '/saml/login';
        }
        if (sharedState.email) {
            this.local.email = sharedState.email;
        }
    }

    async login() {
        const email = this.local.email!!;
        const password = this.local.password!!;
        if (await passwordLogin(email, password)) {
            this.local.email = '';
            this.local.password = '';
            sharedState.email = '';
            if (sharedState.routeAfterLogin) {
                await this.$router.replace(sharedState.routeAfterLogin).catch(() => {});
                sharedState.routeAfterLogin = '';
            } else {
                await this.$router.replace('/').catch(() => {});
            }
        } else {
            this.$buefy.toast.open({
                message: this.$t('message.loginFailed').toString(),
                type: 'is-warning',
                position: 'is-top',
            });
        }
    }

    async submit() {
        await this.login();
    }

    async signInWithGoogle() {
        window.location.href = '/auth/google/login';
    }

    async signInWithMicrosoft() {
        window.location.href = '/auth/microsoft/login';
    }

    get oidcIsEnabled(): boolean {
        return this.shared.enabled(ApplicationFeature.OIDC_AUTHENTICATION);
    }

    async signInWithOidc() {
        window.location.href = '/auth/oidc/login';
    }

    forgotPassword() {
        this.$router.push('/login/request-password-change');
    }
}
