
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../../state';
import { PAGE_SIZE } from '../../../../../../../common/framework/constants';

import { deleteResource, getResourcePaging, getResources } from '../../../../../client/resource';
import Space from '../../../../../../../common/framework/model/Space';
import { getEnumerationOptions, getOptionLabel, getResourceOptions, OptionValue } from '../../../../../service/options';
import { AssetType } from '../../../../../../../common/framework/model/AssetType';
import { SpaceType } from '../../../../../../../common/framework/model/SpaceType';

@Component
export default class Spaces extends Vue {
    readonly resourceType = 'space';

    shared = sharedState;
    local = {
        rows: new Array<Space>(),
        total: 0,
        loading: false,
        page: 1,
        perPage: PAGE_SIZE,
        selected: undefined as Space | undefined,
        resourceOptions: new Map() as Map<string, Array<OptionValue>>,
        spaceTypeOptions: [] as { id: string | undefined; label: string }[],
        groupOptions: [] as OptionValue[],
    };

    async mounted() {
        this.local.resourceOptions.set('group', await getResourceOptions('group', 'name'));
        this.local.spaceTypeOptions = getEnumerationOptions('SpaceType', Object.keys(SpaceType), this);

        await this.loadAsyncData();
    }

    async onPageChange(page: number) {
        this.local.page = page;
        await this.loadAsyncData();
    }

    add() {
        this.$router.push('/space/add');
    }

    edit() {
        if (this.local.selected) {
            this.$router.push('/space/' + this.local.selected.id + '/edit');
        }
    }

    view() {
        if (this.local.selected) {
            this.$router.push('/space/' + this.local.selected.id + '/view');
        }
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    await deleteResource(this.resourceType, this.local.selected.id);
                    await this.loadAsyncData();
                }
            },
        });
    }

    async loadAsyncData() {
        this.local.loading = true;
        this.local.total = (await getResourcePaging(this.resourceType)).pageCount * PAGE_SIZE;
        this.local.rows = [];
        const rows = (await getResources(this.resourceType, this.local.page - 1)) as Array<Space>;
        rows.forEach((row) => {
            this.local.rows.push(row);
        });
        this.local.loading = false;
        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }
    }

    getResourceLabel(id: string, resource: string): string {
        return getOptionLabel(id, this.local.resourceOptions.get(resource)!!);
    }

    getSpaceTypeLabel(id: string): string {
        return getOptionLabel(id, this.local.spaceTypeOptions);
    }
}
