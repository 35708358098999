
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { sharedState } from '../../../../framework/state';
import { getResource, putResource } from '../../../../framework/client/resource';
import TextField from '../../../../framework/fields/TextField.vue';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';
import { Customer } from '../../../../../common/application/model/Customer';
import { Organization } from '../../../../../common/application/model/Organization';
import { Unit } from '../../../../../common/application/model/Unit';

@Component({
    components: { TextField, ValidationObserver },
})
export default class EditUnit extends Vue {
    readonly resourceType = ApplicationResource.UNIT;

    @Prop(String) readonly id!: string;

    shared = sharedState;
    local = {
        row: {} as Unit,
        customerLabel: '',
        organizationLabel: '',
        customers: new Map<string, Customer>(),
        organizations: new Map<string, Organization>(),
    };

    async mounted() {
        this.local.row = (await getResource<Unit>(this.resourceType, this.id))!!;

        const customer = (await getResource<Customer>(ApplicationResource.CUSTOMER, this.local.row.customerId))!!;
        this.local.customerLabel = customer.name;
        const organization = (await getResource<Organization>(
            ApplicationResource.ORGANIZATION,
            this.local.row.organizationId,
        ))!!;
        this.local.organizationLabel = organization.name;
    }

    async save() {
        await putResource(this.resourceType, this.id, this.local.row);
        this.$router.go(-1);
    }

    back() {
        this.$router.go(-1);
    }
}
