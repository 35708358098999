import { apiGet } from '../../framework/client';

import { ERROR_API_CALL_FAILED } from '../../../common/framework/constants';

import { VehicleMake } from '../../../common/application/model/erp/VehicleMake';

export async function getVehicleMakes(): Promise<VehicleMake[]> {
    const response = await apiGet('/api/configurer/vehicle-makes');
    if (response.status === 200) {
        return (await response.json()) as VehicleMake[];
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}
