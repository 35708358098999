
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../../state';
import { getResource, getResources } from '../../../client/resource';
import { PAGE_SIZE } from '../../../../../common/framework/constants';
import { FrameworkResource } from '../../../../../common/framework/enumeration/FrameworkResource';
import Space from '../../../../../common/framework/model/Space';
import { ValidationProvider } from 'vee-validate';
import { getFieldNameFromVModelProperty } from '../../../util/component_util';
import { getAccessibleSpaces } from '../../../service/resource_service';

@Component({
    components: { ValidationProvider },
})
export default class SpaceSelect extends Vue {
    @Prop(Boolean) readonly required!: boolean;
    @Prop(Boolean) readonly disabled!: string;
    @Prop(String) readonly value!: string;

    shared = sharedState;
    local = {
        rows: new Array<Space>(),
        total: 0,
        loading: false,
        page: 1,
        perPage: PAGE_SIZE,
        selected: undefined as Space | undefined,
        typedName: '',
        selectedId: undefined as string | undefined,
    };

    objects: Space[] = [];

    async mounted() {
        this.objects = await getAccessibleSpaces();
    }

    /*
        @Watch("spaceType")
        async spaceTypeChange(newValue: string) {
            console.log('type: ' + newValue);
            this.local.typedName = '';
            this.local.selectedId = '';
            if (newValue) {
                this.objects =  (await getResources(Resource.SPACE, -1, new Map([['type', this.spaceType]]))) as Space[];
            } else {
                this.objects = [];
            }
        }
        */

    @Watch('value')
    async valueChange(newValue: string) {
        this.local.selectedId = newValue;
        if (this.local.selectedId) {
            const space = await getResource<Space>(FrameworkResource.SPACE, this.local.selectedId);
            if (space) {
                this.local.typedName = space.name;
            }
        }
    }

    @Watch('local.selectedId')
    async selectedIChange(newValue: string) {
        console.log(newValue);
        this.$emit('input', newValue);
    }

    get filteredObjects() {
        return this.objects.filter((o) => {
            return o.name!!.toString().toLowerCase().indexOf(this.local.typedName.toLowerCase()) != -1;
        });
    }

    getFieldName() {
        return getFieldNameFromVModelProperty(this);
    }
}
