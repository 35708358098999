
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { sharedState } from '../../../../framework/state';
import {
    deleteResource,
    getResource,
    getResources,
    postResource,
    putResource,
} from '../../../../framework/client/resource';
import TextField from '../../../../framework/fields/TextField.vue';
import { NotificationSourceCustomer } from '../../../../../common/application/model/NotificationSourceCustomer';
import { NotificationSourceOrganization } from '../../../../../common/application/model/NotificationSourceOrganization';
import { NotificationRecipientEmail } from '../../../../../common/application/model/NotificationRecipientEmail';
import { NotificationType } from '../../../../../common/application/model/NotificationType';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';
import { NotificationGroup } from '../../../../../common/application/model/NotificationGroup';
import { Customer } from '../../../../../common/application/model/Customer';
import { Organization } from '../../../../../common/application/model/Organization';
import { NotificationLog } from '../../../../../common/application/model/NotificationLog';
import { User } from '../../../../../common/framework/model/User';
import SwitchField from '../../../../framework/fields/SwitchField.vue';
import { NotificationState } from '../../../../../common/application/model/enums/notification_state';

type Mutable<T> = {
    -readonly [k in keyof T]: T[k];
};

//  Remove on updating node version
declare function structuredClone(value: any): any;

@Component({
    components: { SwitchField, TextField, ValidationObserver },
})
export default class EditNotificationGroup extends Vue {
    readonly resourceType = ApplicationResource.NOTIFICATION_GROUP;
    @Prop(String) readonly id!: string;

    shared = sharedState;
    notificationStateKeys = Object.values(NotificationState);
    local = {
        id: '',
        name: '',
        email: '',
        created: new Date(),
        modified: new Date(),
    };
    deleteConfirmation: Record<string, boolean> = {};
    changeNameForm: Pick<Mutable<NotificationGroup>, 'name'> = {
        name: '',
    };
    addEmailForm: Pick<Mutable<NotificationRecipientEmail>, 'email'> = {
        email: '',
    };
    addTypeForm: Pick<Mutable<NotificationType>, 'state'> = {
        state: '',
    };
    resources = {
        users: [] as User[],
        customers: [] as Customer[],
        organizations: [] as Organization[],
        notificationGroup: null as NotificationGroup | null,
        notificationLogs: [] as NotificationLog[],
        notificationSourceCustomers: [] as NotificationSourceCustomer[],
        notificationSourceOrganizations: [] as NotificationSourceOrganization[],
        notificationRecipientEmails: [] as NotificationRecipientEmail[],
        notificationTypes: [] as NotificationType[],
    };

    async mounted() {
        await this.loadAsyncData();
        this.changeNameForm.name = this.resources.notificationGroup ? this.resources.notificationGroup.name : '';
    }

    get filteredNotificationSourceCustomers(): Customer[] {
        const notificationGroupId = this.$props.id;
        const { customers, notificationSourceCustomers } = this.resources;
        const includedCustomerIds = notificationSourceCustomers
            .filter((value) => value.notificationGroupId === notificationGroupId)
            .map((value) => value.customerId);

        return customers.filter((value) => !includedCustomerIds.includes(value.id));
    }

    get notificationRecipientEmails(): NotificationRecipientEmail[] {
        const notificationGroupId = this.$props.id;
        const { notificationRecipientEmails } = this.resources;
        return notificationRecipientEmails.filter((value) => value.notificationGroupId === notificationGroupId);
    }

    get notificationTypes(): NotificationType[] {
        const notificationGroupId = this.$props.id;
        const { notificationTypes } = this.resources;
        return notificationTypes.filter((value) => value.notificationGroupId === notificationGroupId);
    }

    filteredNotificationSourceOrganizations(customerId: string): Organization[] {
        const notificationGroupId = this.$props.id;
        const { notificationSourceOrganizations, organizations } = this.resources;
        const includedOrganizationIds = notificationSourceOrganizations
            .filter((value) => value.notificationGroupId === notificationGroupId)
            .map((value) => value.organizationId);
        return organizations.filter((value) => {
            return value.customerId === customerId && !includedOrganizationIds.includes(value.id);
        });
    }

    get notificationGroupCustomerSources(): NotificationSourceCustomer[] {
        const notificationGroupId = this.$props.id;
        return this.resources.notificationSourceCustomers.filter((item) => {
            return item.notificationGroupId === notificationGroupId;
        });
    }

    notificationGroupOrganizationSources(customerId: string): NotificationSourceOrganization[] {
        const notificationGroupId = this.$props.id;
        const customerOrganizationIds = this.resources.organizations
            .filter((value) => value.customerId === customerId)
            .map((value) => value.id);
        return this.resources.notificationSourceOrganizations
            .filter((value) => customerOrganizationIds.includes(value.organizationId))
            .filter((value) => value.notificationGroupId === notificationGroupId);
    }

    get notificationLogColumns() {
        return [
            {
                field: 'created',
                label: 'Date',
                centered: true,
            },
            {
                field: 'message',
                label: 'Message',
            },
        ];
    }
    get notificationLogs() {
        const notificationGroupId = this.$props.id;
        const { notificationLogs } = this.resources;
        return notificationLogs.filter((value) => value.notificationGroupId === notificationGroupId);
    }

    async saveChangeNameForm() {
        const row = (await getResource(this.resourceType, this.id))!!;
        await putResource(this.resourceType, this.id, Object.assign(row, this.changeNameForm));
        this.$router.go(-1);
    }

    async saveAddEmailForm() {
        const notificationGroupId = this.$props.id;
        const row: NotificationRecipientEmail = {
            email: this.addEmailForm.email,
            notificationGroupId: notificationGroupId,
            modified: new Date(),
            created: new Date(),
            active: true,
            id: '',
        };
        await postResource<NotificationRecipientEmail>(ApplicationResource.NOTIFICATION_RECIPIENT_EMAIL, row);
        this.addEmailForm = {
            email: '',
        };
        await this.loadAsyncData();
    }

    async saveAddTypeForm(state: string) {
        const notificationGroupId = this.$props.id;
        const row: NotificationType = {
            state,
            notificationGroupId: notificationGroupId,
            modified: new Date(),
            created: new Date(),
            active: true,
            id: '',
        };
        await postResource<NotificationType>(ApplicationResource.NOTIFICATION_TYPE, row);
        this.addTypeForm = {
            state: '',
        };
        await this.loadAsyncData();
    }

    async customerSourceActiveValueChange(source: NotificationSourceCustomer, active: boolean) {
        const row: NotificationSourceCustomer = {
            ...source,
            active,
        };
        await putResource(ApplicationResource.NOTIFICATION_SOURCE_CUSTOMER, row.id, row);
        await this.loadAsyncData();
    }

    async organizationSourceActiveValueChange(source: NotificationSourceOrganization, active: boolean) {
        const row: NotificationSourceOrganization = {
            ...source,
            active,
        };
        await putResource(ApplicationResource.NOTIFICATION_SOURCE_ORGANIZATION, row.id, row);
        await this.loadAsyncData();
    }

    async emailRecipientActiveValueChange(source: NotificationRecipientEmail, active: boolean) {
        const row: NotificationRecipientEmail = {
            ...source,
            active,
        };
        await putResource(ApplicationResource.NOTIFICATION_RECIPIENT_EMAIL, row.id, row);
        await this.loadAsyncData();
    }

    async notificationTypeActiveValueChange(state: string, active: boolean) {
        const source = this.getNotificationTypeByEnum(state);

        if (!source) {
            await this.saveAddTypeForm(state);
            await this.loadAsyncData();
            return;
        }

        const row: NotificationType = {
            ...source,
            active,
        };
        await putResource(ApplicationResource.NOTIFICATION_TYPE, row.id, row);
        await this.loadAsyncData(true);
    }

    getNotificationTypeByEnum(state: string): NotificationType | null {
        const item = this.notificationTypes.find((x) => x.state === state);
        return item ? item : null;
    }

    isNotificationTypeActive(item: NotificationType | null): boolean {
        return item ? item.active : false;
    }

    async notificationGroupActiveValueChange(source: NotificationGroup | null, active: boolean) {
        if (!source) {
            return;
        }

        const row: NotificationGroup = {
            ...source,
            active,
        };
        await putResource(ApplicationResource.NOTIFICATION_GROUP, row.id, row);
        await this.loadAsyncData();
    }

    isDeletionConfirmed(id: string, falseValue: string = 'is-light') {
        return this.deleteConfirmation[id] ? 'is-danger' : falseValue;
    }

    confirmDeletion(id: string): boolean {
        if (!this.deleteConfirmation[id]) {
            this.deleteConfirmation[id] = true;
            this.deleteConfirmation = structuredClone(this.deleteConfirmation);
            setTimeout(() => {
                this.deleteConfirmation[id] = false;
                this.deleteConfirmation = structuredClone(this.deleteConfirmation);
            }, 2000);
            return true;
        }
        return false;
    }

    async onDeleteRecipientEmailClick(item: NotificationRecipientEmail) {
        if (this.confirmDeletion(item.id)) {
            return;
        }
        await deleteResource(ApplicationResource.NOTIFICATION_RECIPIENT_EMAIL, item.id);
        await this.loadAsyncData();
    }

    async onDeleteOrganizationSourceClick(item: NotificationSourceOrganization) {
        if (this.confirmDeletion(item.id)) {
            return;
        }
        await deleteResource(ApplicationResource.NOTIFICATION_SOURCE_ORGANIZATION, item.id);
        await this.loadAsyncData();
    }

    async onDeleteCustomerSourceClick(item: NotificationSourceCustomer) {
        if (this.confirmDeletion(item.id)) {
            return;
        }
        await deleteResource(ApplicationResource.NOTIFICATION_SOURCE_CUSTOMER, item.id);
        await this.loadAsyncData();
    }

    getCustomer(id: string) {
        return this.resources.customers.find((value) => value.id === id);
    }

    getOrganization(id: string) {
        return this.resources.organizations.find((value) => value.id === id);
    }

    back() {
        this.$router.go(-1);
    }

    async customerSelected(option: Customer) {
        const notificationGroupId = this.$props.id;
        const f: NotificationSourceCustomer = {
            id: '',
            created: new Date(),
            modified: new Date(),
            customerId: option.id,
            notificationGroupId,
            active: true,
        };
        await postResource(ApplicationResource.NOTIFICATION_SOURCE_CUSTOMER, f);
        await this.loadAsyncData();
    }

    async organizationSelected(option: Organization) {
        const notificationGroupId = this.$props.id;
        const f: NotificationSourceOrganization = {
            id: '',
            created: new Date(),
            modified: new Date(),
            organizationId: option.id,
            notificationGroupId,
            active: true,
        };
        await postResource(ApplicationResource.NOTIFICATION_SOURCE_ORGANIZATION, f);
        await this.loadAsyncData();
    }

    async loadAsyncData(onlyNotificationTypes: boolean = false) {
        const parameters = new Map([['notificationGroupId', this.$props.id!!]]);

        if (onlyNotificationTypes) {
            this.resources = {
                ...this.resources,
                notificationTypes: await getResources(ApplicationResource.NOTIFICATION_TYPE, -1, parameters),
            };
            return;
        } else {
            const resources = {
                ...this.resources,
                customers: await getResources(ApplicationResource.CUSTOMER, -1, parameters),
                organizations: await getResources(ApplicationResource.ORGANIZATION, -1, parameters),
                notificationGroup: (await getResource<NotificationGroup>(this.resourceType, this.id)) ?? null,
                notificationLogs: await getResources(ApplicationResource.NOTIFICATION_LOG, -1, parameters),
                notificationSourceCustomers: await getResources(
                    ApplicationResource.NOTIFICATION_SOURCE_CUSTOMER,
                    -1,
                    parameters,
                ),
                notificationSourceOrganizations: await getResources(
                    ApplicationResource.NOTIFICATION_SOURCE_ORGANIZATION,
                    -1,
                    parameters,
                ),
                notificationRecipientEmails: await getResources(
                    ApplicationResource.NOTIFICATION_RECIPIENT_EMAIL,
                    -1,
                    parameters,
                ),
                notificationTypes: await getResources(ApplicationResource.NOTIFICATION_TYPE, -1, parameters),
            } satisfies typeof this.resources;

            this.resources = resources;
        }
    }
}
