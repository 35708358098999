
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';

import { sharedState } from '../../../../framework/state';
import { getResources } from '../../../../framework/client/resource';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';
import { CustomerDealer } from '../../../../../common/application/model/CustomerDealer';
import { PAGE_SIZE } from '../../../../../common/framework/constants';
import { Customer } from '../../../../../common/application/model/Customer';

@Component({
    components: { ValidationProvider },
})
export default class CustomerContextSelector extends Vue {
    readonly resourceType = ApplicationResource.CUSTOMER_DEALER;

    shared = sharedState;
    local = {
        rows: new Array<CustomerDealer>(),
        total: 0,
        loading: false,
        page: 1,
        perPage: PAGE_SIZE,
        selected: undefined as CustomerDealer | undefined,
        typedCustomerName: '',
    };

    customers: Customer[] = [];

    customerId = '';

    async mounted() {
        this.customers = await getResources<Customer>(ApplicationResource.CUSTOMER, -1);
        if (this.shared.customerId !== undefined) {
            const customers = this.customers.filter((c) => c.id === this.shared.customerId);
            if (customers.length > 0) {
                this.local.typedCustomerName = customers[0].name;
            }
        }
    }

    @Watch('customerId')
    async customerIdChange(customerId: string) {
        sharedState.customerId = this.customerId;
        document.dispatchEvent(new Event('selected-customer-changed'));
    }

    get filteredCustomers() {
        return this.customers.filter((customer) => {
            return customer.name!!.toString().toLowerCase().indexOf(this.local.typedCustomerName.toLowerCase()) != -1;
        });
    }
}
