import { EngineEntity } from '../model/EngineEntity';
import * as BABYLON from 'babylonjs';
import * as BABYLON_LOADERS from 'babylonjs-loaders';
import GLTFLoaderCoordinateSystemMode = BABYLON_LOADERS.GLTFLoaderCoordinateSystemMode;
import Mesh = BABYLON.Mesh;
import Entity from '../../../../../common/framework/model/Entity';
import Vector3 = BABYLON.Vector3;
import Quaternion = BABYLON.Quaternion;

// Required for loader to be imported
const gltfLoaderCoordinateSystemMode = GLTFLoaderCoordinateSystemMode;

export function setInstanceTransformation(instance: Mesh, entity: Entity) {
    instance.position.x = entity.x;
    instance.position.y = entity.y;
    instance.position.z = entity.z;
    instance.scaling.x *= entity.scale;
    instance.scaling.y *= entity.scale;
    instance.scaling.z *= entity.scale;
    instance.rotationQuaternion = BABYLON.Quaternion.Identity();
    instance.rotationQuaternion!!.x = entity.rx;
    instance.rotationQuaternion!!.y = entity.ry;
    instance.rotationQuaternion!!.z = entity.rz;
    instance.rotationQuaternion!!.w = entity.rw;
}

export function setEntityPositionAndRotation(entity: EngineEntity, position: Vector3, quaternion: Quaternion) {
    entity.x = position.x;
    entity.y = position.y;
    entity.z = position.z;
    entity.rx = quaternion.x;
    entity.ry = quaternion.y;
    entity.rz = quaternion.z;
    entity.rw = quaternion.w;
}

export function interpolateRotation(target: BABYLON.Quaternion, tracking: BABYLON.Quaternion, timeDeltaMillis: number) {
    const relaxationTimeMillis = 500;
    const rq3 = BABYLON.Quaternion.Slerp(tracking, target, timeDeltaMillis / relaxationTimeMillis);
    tracking.x = rq3.x;
    tracking.y = rq3.y;
    tracking.z = rq3.z;
    tracking.w = rq3.w;
    tracking.normalize();
}

export function interpolatePosition(tracking: Vector3, target: Vector3, timeDeltaMillis: number): void {
    //console.log(timeDeltaMillis);
    if (timeDeltaMillis > 50) {
        timeDeltaMillis = 50;
    }
    const delta = target.subtract(tracking);
    const distance = delta.length();

    // If distance is small enough then set p2 same as p1 and return.
    if (distance < 0.01) {
        tracking.x = target.x;
        tracking.y = target.y;
        tracking.z = target.z;
        return;
    }

    const relaxationTimeMillis = 500;
    const stepDistance = distance / (relaxationTimeMillis / timeDeltaMillis);
    const speed = stepDistance / timeDeltaMillis;
    const normal = delta.normalize();
    let correction = speed * timeDeltaMillis;
    if (correction < 0.003) {
        correction = 0.003;
    }
    const correctionVector = normal.scaleInPlace(correction);
    const correctedVector = correctionVector.addInPlace(tracking);
    tracking.x = correctedVector.x;
    tracking.y = correctedVector.y;
    tracking.z = correctedVector.z;
    return;
}

export function interpolateEntityPositionAndRotation(
    timeDeltaMillis: number,
    entity: EngineEntity,
    instance: Mesh | undefined,
): void {
    if (!instance) {
        return;
    }
    if (!entity.p1) {
        entity.p1 = new Vector3(entity.x, entity.y, entity.z);
    }
    const p1 = entity.p1;
    p1.x = entity.x;
    p1.y = entity.y;
    p1.z = entity.z;

    if (!entity.p2) {
        entity.p2 = p1.clone();
    }

    const p2 = entity.p2 as Vector3;
    const p3 = instance.position;

    interpolatePosition(p2, p1, timeDeltaMillis);
    interpolatePosition(p3, p2, timeDeltaMillis);

    if (!instance.rotationQuaternion) {
        instance.rotationQuaternion = Quaternion.Identity();
    }
    if (!entity.rq1) {
        entity.rq1 = Quaternion.Identity();
    }

    const rq1 = entity.rq1;
    rq1.x = entity.rx;
    rq1.y = entity.ry;
    rq1.z = entity.rz;
    rq1.w = entity.rw;

    const rq2 = instance.rotationQuaternion!!;
    interpolateRotation(rq1, rq2, timeDeltaMillis);
}
