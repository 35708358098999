import { sharedState } from '../../framework/state';
import { FrameworkUserRole } from '../../../common/framework/enumeration/FrameworkUserRole';
import { FrameworkResource } from '../../../common/framework/enumeration/FrameworkResource';
import Space from '../../../common/framework/model/Space';
import { getResources } from '../../framework/client/resource';
import { FrameworkFeature } from '../../../common/framework/enumeration/FrameworkFeature';

export async function getAccessibleSpaces(): Promise<Array<Space>> {
    if (sharedState.anonymous && !sharedState.enabled(FrameworkFeature.ANONYMOUS_ACCESS)) {
        return [];
    }
    if (sharedState.hasRole(FrameworkUserRole.ADMIN)) {
        return await getResources<Space>(FrameworkResource.SPACE, -1);
    } else {
        const parameters = new Map<string, string>();
        if (sharedState.authenticated) {
            if (
                sharedState.context.scopes.viewer.spaceId.length > 0 ||
                sharedState.context.scopes.editor.spaceId.length > 0 ||
                sharedState.context.scopes.manager.spaceId.length > 0
            ) {
                parameters.set(
                    'idIn',
                    sharedState.context.scopes.viewer.spaceId
                        .concat(sharedState.context.scopes.editor.spaceId)
                        .concat(sharedState.context.scopes.manager.spaceId)
                        .join(','),
                );
            } else {
                return [];
            }
        } else {
            parameters.set('public', 'true');
        }
        return (await getResources(FrameworkResource.SPACE, -1, parameters)) as Space[];
    }
}
