import { apiGet } from '../../framework/client';

import { ERROR_API_CALL_FAILED } from '../../../common/framework/constants';

import { VehicleModel } from '../../../common/application/model/erp/VehicleModel';
import { VehicleModelInfo } from '../../../common/application/model/erp/VehicleModelInfo';
import { ModelImage } from '../../../common/application/model/ModelImage';

export async function getVehicleModels(makeId: string): Promise<VehicleModel[]> {
    const response = await apiGet(`/api/configurer/vehicle-makes/${makeId}/vehicle-models`);
    if (response.status === 200) {
        return (await response.json()) as VehicleModel[];
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getVehicleModelInfo(modelId: string): Promise<VehicleModelInfo> {
    const response = await apiGet(`/api/configurer/vehicle-models/${modelId}`);
    if (response.status === 200) {
        return (await response.json()) as VehicleModelInfo;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getTailoredVehicleModelImages(tailoredVehicleModelId: string): Promise<ModelImage[]> {
    const response = await apiGet(`/api/user/tailored-vehicle-models/${tailoredVehicleModelId}/images`);
    if (response.status === 200) {
        return (await response.json()) as ModelImage[];
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}
