
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';

import { sharedState } from '../../../../../framework/state';
import {
    deleteResource,
    getResourcePaging,
    getResources,
    postResource,
} from '../../../../../framework/client/resource';
import { getCustomerOptions } from '../../../../service/options';
import { getDealerOptions } from '../../../../service/options';
import CustomerContextSelector from '../../context/CustomerContextSelector.vue';
import { ApplicationResource } from '../../../../../../common/application/enumeration/ApplicationResource';
import { Dealer } from '../../../../../../common/application/model/Dealer';
import { getOptionLabel, OptionValue } from '../../../../../framework/service/options';
import { CustomerDealer } from '../../../../../../common/application/model/CustomerDealer';
import { PAGE_SIZE } from '../../../../../../common/framework/constants';

@Component({
    components: { ValidationProvider, CustomerContextSelector },
})
export default class CustomerDealers extends Vue {
    readonly resourceType = ApplicationResource.CUSTOMER_DEALER;

    shared = sharedState;
    local = {
        rows: new Array<CustomerDealer>(),
        total: 0,
        loading: false,
        page: 1,
        perPage: PAGE_SIZE,
        selected: undefined as CustomerDealer | undefined,
        dealerId: '',
        dealers: [] as Dealer[],
        typedDealerName: '',
        customerOptions: [] as OptionValue[],
        dealerOptions: [] as OptionValue[],
    };

    async mounted() {
        this.local.dealers = (await getResources(ApplicationResource.DEALER, -1)) as Dealer[];
        this.local.customerOptions = await getCustomerOptions();
        this.local.dealerOptions = await getDealerOptions();
        await this.loadAsyncData();
        document.addEventListener('selected-customer-changed', this.selectedCustomerChanged);
    }

    async destroyed() {
        document.removeEventListener('selected-customer-changed', this.selectedCustomerChanged);
    }

    async selectedCustomerChanged() {
        await this.loadAsyncData();
    }

    async onPageChange(page: number) {
        this.local.page = page;
        await this.loadAsyncData();
    }

    @Watch('customerId')
    async customerIdChange() {
        await this.loadAsyncData();
    }

    @Watch('dealerId')
    async dealerIdChange() {
        await this.loadAsyncData();
    }

    async add() {
        const object: CustomerDealer = {
            id: '',
            customerId: this.shared.customerIdFilter!!,
            dealerId: this.local.dealerId,
            created: new Date(),
            modified: new Date(),
        };
        await postResource(this.resourceType, object);
        this.local.dealerId = '';
        this.local.typedDealerName = '';
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    await deleteResource(this.resourceType, this.local.selected.id);
                    await this.loadAsyncData();
                }
            },
        });
    }

    async loadAsyncData() {
        this.local.loading = true;
        this.local.total = (await getResourcePaging(this.resourceType, this.getWhereCriteria())).rowCount;
        const rows = await getResources<CustomerDealer>(
            this.resourceType,
            this.local.page - 1,
            this.getWhereCriteria(),
        );
        this.local.rows = [];
        rows.forEach((row) => {
            this.local.rows.push(row);
        });
        this.local.loading = false;
        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }
    }

    getWhereCriteria(): Map<string, any> | undefined {
        if (this.shared.customerIdFilter && this.local.dealerId) {
            return new Map([
                ['customerId', this.shared.customerIdFilter],
                ['dealerId', this.local.dealerId],
            ]);
        } else if (this.shared.customerIdFilter) {
            return new Map([['customerId', this.shared.customerIdFilter]]);
        } else if (this.local.dealerId) {
            return new Map([['dealerId', this.local.dealerId]]);
        } else {
            return undefined;
        }
    }

    getLabel(id: string, options: { id: string | undefined; label: string }[]) {
        return getOptionLabel(id, options);
    }

    get filteredDealers() {
        return this.local.dealers
            .filter((dealer) => !!dealer.id)
            .filter((dealer) => {
                return (
                    dealer.name!!.toString().toLowerCase().indexOf(this.local.typedDealerName.toLowerCase()) != -1 ||
                    (dealer.customerNumber &&
                        dealer
                            .customerNumber!!.toString()
                            .toLowerCase()
                            .indexOf(this.local.typedDealerName.toLowerCase()) != -1)
                );
            });
    }

    onRowClick(row: CustomerDealer) {
        if (this.local.selected && row.id === this.local.selected.id) {
            this.local.selected = undefined;
        }
    }
}
