import { apiGet } from './index';
import { BrowserContext } from '../../../common/framework/model/BrowserContext';

export async function getContext(): Promise<BrowserContext | undefined> {
    try {
        const response = await apiGet('/api/context');
        if (response.status == 200) {
            return (await response.json()) as BrowserContext;
        } else {
            return undefined;
        }
    } catch (e: any) {
        console.error('Error occurred when getting context: ', e);
    }
}
