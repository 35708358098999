
import { Component, Prop, Vue } from 'vue-property-decorator';
import TailoredVehicleModelImage from './TailoredVehicleModelImage.vue';
import { getTailoredVehicleModelImages } from '../../../client/vehicle_model_client';
import { sharedState } from '../../../../framework/state';
import { ModelImage } from '../../../../../common/application/model/ModelImage';
import { ImageCategory } from '../../../../../common/application/model/ImageCategory';

@Component({
    components: { TailoredVehicleModelImage },
})
export default class TailoredVehicleModelImages extends Vue {
    @Prop(String) readonly tailoredVehicleModelId!: string;

    shared = sharedState;
    local = {
        tailoredVehicleModelImages: [] as ModelImage[],
        exteriorImageId: undefined as string | undefined,
        interiorImageId: undefined as string | undefined,
    };

    async mounted() {
        this.local.tailoredVehicleModelImages = await getTailoredVehicleModelImages(this.tailoredVehicleModelId);

        for (const image of this.local.tailoredVehicleModelImages) {
            if (image.category === ImageCategory.EXTERIOR) {
                this.local.exteriorImageId = image.id;
            }
            if (image.category === ImageCategory.INTERIOR) {
                this.local.interiorImageId = image.id;
            }
        }
    }
}
