
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { sharedState } from '../../../../framework/state';
import { getResource, putResource } from '../../../../framework/client/resource';
import CustomerOrganizations from './CustomerOrganizations.vue';
import { Customer } from '../../../../../common/application/model/Customer';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';

import TextField from '../../../../framework/fields/TextField.vue';

@Component({
    components: {
        CustomerOrganizations,
        ValidationObserver,
        TextField,
    },
})
export default class EditCustomer extends Vue {
    readonly resourceType = ApplicationResource.CUSTOMER;

    @Prop(String) readonly id!: string;

    shared = sharedState;
    local = {
        row: {} as Customer,
    };

    async mounted() {
        this.local.row = (await getResource(this.resourceType, this.id))!! as Customer;
    }

    async save() {
        await putResource(this.resourceType, this.id, this.local.row);
        this.$router.go(-1);
    }

    back() {
        this.$router.go(-1);
    }
}
