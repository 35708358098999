
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../../state';
import { postResource } from '../../../../../client/resource';
import TextField from '../../../../../fields/TextField.vue';
import Entity from '../../../../../../../common/framework/model/Entity';
import NumberField from '../../../../../fields/NumberField.vue';
import SwitchField from '../../../../../fields/SwitchField.vue';
import { ValidationObserver } from 'vee-validate';
import TextAreaField from '../../../../../fields/TextAreaField.vue';
import AssetSelect from '../../../../../fields/AssetSelect.vue';
import AutoCompleteField from '../../../../../fields/AutoCompleteField.vue';
import ParentSelect from '../../../fields/ParentEntitySelect.vue';
import SpaceSelect from '../../../fields/SpaceSelect.vue';
import JsonField from '../../../../../fields/JsonField.vue';

@Component({
    components: {
        JsonField,
        SwitchField,
        TextField,
        TextAreaField,
        ParentSelect,
        AssetSelect,
        SpaceSelect,
        NumberField,
        AutoCompleteField,
        ValidationObserver,
    },
})
export default class AddEntity extends Vue {
    // Constants
    readonly resourceType = 'entity';

    // Properties

    // State
    shared = sharedState;
    local = {
        row: {
            id: '',
            x: 0,
            y: 0,
            z: 0,
            rx: 0,
            ry: 0,
            rz: 0,
            rw: 1,
            scale: 1,
            radius: 1,
            zone: false,
            anchor: false,
            visible: true,
            public: false,
            metadata: {},
            created: undefined as any as Date,
            modified: undefined as any as Date,
        } as Entity,
        typeOptions: [] as { id: string | undefined; label: string }[],
    };

    // Functions
    async mounted() {
        //this.local.
        /*this.local.typeOptions = getEnumOptions('EntityType',
                Object.keys(EntityType)).map((o) => {
                return { id: o.id, label: this.$t(o.labelKey).toString()}; });*/
    }

    async add() {
        const addedRow = await postResource<Entity>(this.resourceType, this.local.row);
        this.back();
    }

    back() {
        this.$router.go(-1);
    }
}
