
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../framework/state';
import { getFieldNameFromVModelProperty } from '../util/component_util';
import { ValidationProvider } from 'vee-validate';

@Component({
    components: { ValidationProvider },
})
export default class TextAreaField extends Vue {
    @Prop(String) readonly name!: string;
    @Prop(String) readonly value!: string;
    @Prop(Number) readonly maxlength!: number;
    @Prop(Boolean) readonly readonly!: string;
    @Prop(Boolean) readonly required!: boolean;
    @Prop(Boolean) readonly horizontal!: boolean;
    @Prop(Boolean) readonly disabled!: string;

    shared = sharedState;
    local = {
        regex: /^[a-zåäöA-ZÅÄÖ0-9 ^,.;:!?&%'`"@|+*=/\\\-_~#()${}<>\[\]\n]+$/,
        value: undefined as string | undefined,
    };

    async mounted() {
        await this.modelValueChange(this.value);
    }

    @Watch('value')
    async modelValueChange(newValue: string) {
        this.local.value = newValue;
    }

    @Watch('local.value')
    async inputValueChange(newValue: string) {
        this.$emit('input', newValue);
    }

    getFieldName() {
        return getFieldNameFromVModelProperty(this);
    }
}
