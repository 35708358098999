
import { Component, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../../state';
import { P_SORT_FIELDS, P_SORT_ORDERS, PAGE_SIZE } from '../../../../../common/framework/constants';

import { deleteResource, getResourcePaging, getResources } from '../../../client/resource';
import { Option } from '../../../../../common/framework/model/Option';
import { OptionValue } from '../../../service/options';
import SelectComponent from '../../../fields/SelectComponent.vue';
import { FrameworkResource } from '../../../../../common/framework/enumeration/FrameworkResource';

@Component({
    components: { SelectComponent },
})
export default class Options extends Vue {
    readonly resourceType = FrameworkResource.OPTION;

    shared = sharedState;
    local = {
        rows: new Array<Option>(),
        total: 0,
        loading: false,
        page: 1,
        perPage: PAGE_SIZE,
        selectedOptionType: undefined as string | undefined,
        selected: undefined as Option | undefined,
        typeOptions: [] as OptionValue[],
    };

    async mounted() {
        await this.loadAsyncData();
    }

    async onPageChange(page: number) {
        this.local.page = page;
        await this.loadAsyncData();
    }

    add() {
        this.$router.push('/option/add');
    }

    edit() {
        if (this.local.selected) {
            this.$router.push('/option/' + this.local.selected.id + '/edit');
        }
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    await deleteResource(this.resourceType, this.local.selected.id);
                    await this.loadAsyncData();
                }
            },
        });
    }

    async loadAsyncData() {
        const typeOptions = (await getResources<Option>(this.resourceType, -1)).map((o) => o.type);
        this.local.typeOptions = typeOptions
            .filter((t, index) => typeOptions.indexOf(t) == index)
            .sort((a, b) => a.localeCompare(b))
            .map((t) => {
                return {
                    id: t,
                    label: t,
                };
            });
        this.local.typeOptions.unshift({ id: undefined, label: ' ' });

        const parameters = new Map<string, string>();
        if (typeof this.local.selectedOptionType !== 'undefined') {
            parameters.set('type', this.local.selectedOptionType);
        }
        parameters.set(P_SORT_FIELDS, 'type,index,key');
        parameters.set(P_SORT_ORDERS, 'asc,asc,asc');

        this.local.loading = true;
        this.local.total = (await getResourcePaging(this.resourceType, parameters)).pageCount * PAGE_SIZE;
        this.local.rows = [];
        const rows = (await getResources(this.resourceType, this.local.page - 1, parameters)) as Array<Option>;
        rows.forEach((row) => {
            this.local.rows.push(row);
        });
        this.local.loading = false;
        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }
    }

    @Watch('local.selectedOptionType')
    async selectedOptionTypeChanged(newSelectedOptionType: string) {
        await this.loadAsyncData();
    }

    /*getResourceLabel(id: string, resource: string): string {
            return getOptionLabel(id, this.local.resourceOptions.get(resource)!!);
        }*/
}
