import { sharedState } from './state';
import { FrameworkFeature } from '../../common/framework/enumeration/FrameworkFeature';
import { loadSpaceNavigationItems } from './module/space/space_navigation_service';
import { invokeUiModuleOnLoggedIn, invokeUiModuleOnLoggedOut } from '../../common/framework/service/module_service';

export async function frameworkLoggedIn() {
    await loadSpaceNavigationItems();
}

export async function frameworkLoggedOut() {
    await loadSpaceNavigationItems();
}
