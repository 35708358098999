import { MessageType } from './model/MessageType';
import { marshal, unmarshal } from './marshal';

interface WebSocketFactory {
    (url: string, protocol: string | undefined): WebSocket;
}

interface OnClose {
    (): void;
}

interface OnReceive {
    (messageType: MessageType, message: any): void;
}

export class NexusClient {
    public url: string;
    public connected: boolean = false;
    public webSocketFactory: WebSocketFactory;
    public ws: WebSocket | undefined = undefined;

    constructor(url: string, webSocketFactory: WebSocketFactory) {
        this.url = url;
        this.webSocketFactory = webSocketFactory;
    }

    connect(): Promise<void> {
        if (this.connected) {
            throw new Error('nexus client - error already connected.');
        }
        this.connected = false;
        return new Promise((resolve, reject) => {
            try {
                this.ws = this.webSocketFactory(this.url, undefined);
                this.ws.binaryType = 'arraybuffer';
                this.ws.onerror = (error) => {
                    this.connected = false;
                    console.warn('nexus client - error in client ws connection', error);
                    reject(new Error('nexus client - error in client ws connection'));
                    if (this.ws) {
                        this.ws.close();
                    }
                };
                this.ws.onclose = () => {
                    console.log('nexus client - disconnected');
                    this.connected = false;
                    this.onClose();
                };
                this.ws.onopen = async () => {
                    console.log('nexus client - connected');
                    this.connected = true;
                    resolve();
                };
                this.ws.onmessage = async (messageEvent) => {
                    //console.log("nexus client - received message");
                    try {
                        const data = new Uint8Array(messageEvent.data);
                        const unmarshalResult = unmarshal(data);
                        this.onReceive(unmarshalResult.messageType, unmarshalResult.message);
                        //console.log("received: " + unmarshalResult.messageType);
                    } catch (e: any) {
                        console.log('nexus client error on processing on message: ' + e);
                    }
                };
            } catch (error) {
                console.warn('nexus client - error in client ws connect', error);
                reject(error);
            }
        });
    }

    disconnect() {
        if (typeof this.ws !== 'undefined') {
            this.ws.close();
        }
    }

    onClose: OnClose = () => {};

    send<T>(messageType: MessageType, message: T) {
        try {
            if (this.connected) {
                //console.log('sending message type: ' + messageType);
                const data = marshal(messageType, message);
                this.ws!!.send(data);
            }
        } catch (e: any) {
            console.log('nexus client error sending data: ' + e);
        }
    }

    onReceive: OnReceive = (messageType: MessageType, message: any) => {};
}
