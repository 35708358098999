import { apiDelete, apiGet } from '../../framework/client';
import { ERROR_API_CALL_FAILED } from '../../../common/framework/constants';
import AssetBinary from '../../../common/framework/model/AssetBinary';

export async function getAssetBinaryMeta(assetId: string, type: string): Promise<AssetBinary | undefined> {
    const response = await apiGet(`/api/resource/asset/${assetId}/meta/${type}`);
    if (response.status === 200) {
        return (await response.json()) as AssetBinary;
    } else if (response.status === 404) {
        return undefined;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function deleteAssetBinary(assetId: string, type: string): Promise<void> {
    const response = await apiDelete(`/api/resource/asset/${assetId}/bytes/${type}`);
    if (response.status === 200) {
        return;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}
