import { FrameworkFeature } from '../../../../common/framework/enumeration/FrameworkFeature';
import { Feature } from '../../../../common/framework/enumeration/Feature';
import { FrameworkModule } from '../../../../common/framework/enumeration/FrameworkModule';
import { ModuleType } from '../../../../common/framework/enumeration/ModuleType';
import { Module } from '../../../../common/framework/model/Module';
import { onMediaStreamMessage, startRecording, startVoice, stopRecording, stopVoice } from './service/voice_service';
import {
    addGlobalStateRemoveListener,
    addGlobalStateSetListener,
    getGlobalState,
    removeGlobalState,
    removeGlobalStateRemoveListener,
    removeGlobalStateSetListener,
    setGlobalState,
} from '../../service/global_state_service';
import { CommunicationState } from './model/CommunicationState';
import { FrameworkStateType } from '../../../../common/framework/enumeration/FrameworkStateType';
import { sharedState } from '../../state';
import { StateType } from '../../../../common/framework/enumeration/StateType';
import { EngineContext } from '../xr/model/EngineContext';
import {
    stopNetwork,
    startNetwork,
    addNetworkMessageListener,
    removeNetworkMessageListener,
} from './service/network_service';
import { MessageType } from '../../../../common/framework/module/xr/network/model/MessageType';
import { onChatMessage, onJoinResponseMessage, onLeaveResponseMessage } from './service/channel_service';
import { UiModule } from '../../../../common/framework/model/UiModule';

export class CommunicationModule implements UiModule {
    pressedKeys = new Map<String, boolean>();
    get type(): ModuleType {
        return FrameworkModule.COMMUNICATION_UI;
    }

    get features(): Array<Feature> {
        return [FrameworkFeature.VOICE_CHAT];
    }

    async initialize() {
        await setGlobalState<CommunicationState>(FrameworkStateType.COMMUNICATION_STATE, {
            get userId() {
                return sharedState.context.userId;
            },
            get authenticationToken() {
                return sharedState.context.idToken;
            },
            spaceChannel: undefined,
            channels: [],
            lastNexusConnect: undefined,
        });

        await addGlobalStateSetListener(FrameworkStateType.XR_ENGINE_CONTEXT, this.onXrEngineOnline);
        addGlobalStateRemoveListener(FrameworkStateType.XR_ENGINE_CONTEXT, this.onXrEngineOffline);
    }

    async dispose() {
        removeGlobalStateSetListener(FrameworkStateType.XR_ENGINE_CONTEXT, this.onXrEngineOnline);
        removeGlobalStateRemoveListener(FrameworkStateType.XR_ENGINE_CONTEXT, this.onXrEngineOffline);

        await removeGlobalState<CommunicationState>(FrameworkStateType.COMMUNICATION_STATE);
    }

    onXrEngineOnline = async (type: StateType, engineContext: EngineContext) => {
        addNetworkMessageListener(MessageType.JOIN_RESPONSE, onJoinResponseMessage);
        addNetworkMessageListener(MessageType.LEAVE_RESPONSE, onLeaveResponseMessage);
        addNetworkMessageListener(MessageType.CHAT, onChatMessage);
        await startNetwork();
        await startVoice();
        window.addEventListener('keydown', this.keyDown, false);
        window.addEventListener('keyup', this.keyUp, false);
    };

    onXrEngineOffline = async (type: StateType, engineContext: EngineContext) => {
        window.removeEventListener('keydown', this.keyDown);
        window.removeEventListener('keyup', this.keyUp);
        await stopVoice();
        await stopNetwork();
        removeNetworkMessageListener(MessageType.CHAT, onChatMessage);
        removeNetworkMessageListener(MessageType.LEAVE_RESPONSE, onLeaveResponseMessage);
        removeNetworkMessageListener(MessageType.JOIN_RESPONSE, onJoinResponseMessage);
    };

    keyDown = async (event: KeyboardEvent) => {
        const engineContext = getGlobalState<EngineContext>(FrameworkStateType.XR_ENGINE_CONTEXT);
        if (engineContext && !engineContext.state.inputFocus) {
            if (!this.pressedKeys.has(event.code)) {
                await this.keyPressed(event);
                this.pressedKeys.set(event.code, true);
            }
        }
    };

    keyUp = async (event: KeyboardEvent) => {
        const engineContext = getGlobalState<EngineContext>(FrameworkStateType.XR_ENGINE_CONTEXT);
        if (engineContext && !engineContext.state.inputFocus) {
            if (this.pressedKeys.has(event.code)) {
                await this.keyLifted(event);
                this.pressedKeys.delete(event.code);
            }
        }
    };

    private async keyPressed(event: KeyboardEvent) {
        if (event.code === 'KeyR') {
            await startRecording();
        }
    }

    private async keyLifted(event: KeyboardEvent) {
        if (event.code === 'KeyR') {
            await stopRecording();
        }
    }

    async onLoggedIn() {}

    async onLoggedOut() {}
}
