
import { Component, Prop, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../../../framework/state';
import {
    deleteResource,
    getResource,
    getResourcePaging,
    getResources,
} from '../../../../../../framework/client/resource';
import { ApplicationResource } from '../../../../../../../common/application/enumeration/ApplicationResource';
import { Customer } from '../../../../../../../common/application/model/Customer';
import { TailoredVehicleModel } from '../../../../../../../common/application/model/TailoredVehicleModel';
import { TailoredRetrofittedAccessory } from '../../../../../../../common/application/model/TailoredRetrofittedAccessory';
import { PAGE_SIZE } from '../../../../../../../common/framework/constants';

@Component
export default class TailoredRetrofittedAccessories extends Vue {
    readonly resourceType = ApplicationResource.TAILORED_RETROFITTED_ACCESSORY;

    @Prop(String) readonly tailoredVehicleModelId!: string;

    shared = sharedState;
    local = {
        rows: new Array<TailoredRetrofittedAccessory>(),
        customers: new Map<string, Customer>(),
        customerId: undefined as undefined | string,
        total: 0,
        loading: false,
        page: 1,
        perPage: PAGE_SIZE,
        selected: undefined as TailoredRetrofittedAccessory | undefined,
    };

    async mounted() {
        const tailoredVehicleModel = (await getResource<TailoredVehicleModel>(
            ApplicationResource.TAILORED_VEHICLE_MODEL,
            this.tailoredVehicleModelId,
        ))!!;
        this.local.customerId = tailoredVehicleModel.customerId;
        await this.loadAsyncData();
    }

    async onPageChange(page: number) {
        this.local.page = page;
        await this.loadAsyncData();
    }

    add() {
        this.$router.push(
            '/tailored-vehicle-models/' + this.tailoredVehicleModelId + '/tailored-retrofitted-accessories/add',
        );
    }

    edit() {
        if (this.local.selected) {
            this.$router.push('/tailored-retrofitted-accessories/edit/' + this.local.selected.id);
        }
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    await deleteResource(this.resourceType, this.local.selected.id);
                    await this.loadAsyncData();
                }
            },
        });
    }

    async loadAsyncData() {
        this.local.loading = true;
        this.local.total = (
            await getResourcePaging(
                this.resourceType,
                new Map([
                    ['customerId', this.local.customerId!!],
                    ['tailoredVehicleModelId', this.tailoredVehicleModelId],
                ]),
            )
        ).rowCount;

        this.local.rows = [];
        const rows = (await getResources(
            this.resourceType,
            this.local.page - 1,
            new Map([
                ['customerId', this.local.customerId!!],
                ['tailoredVehicleModelId', this.tailoredVehicleModelId],
            ]),
        )) as Array<TailoredRetrofittedAccessory>;

        rows.forEach((row) => {
            this.local.rows.push(row);
        });

        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }

        this.local.loading = false;
    }

    onRowClick(row: TailoredRetrofittedAccessory) {
        if (this.local.selected && row.id === this.local.selected.id) {
            this.local.selected = undefined;
        }
    }
}
