// index.js
import en from './locale-en.json';
import fi from './locale-fi.json';
import appEn from './application/locale-en.json';
import appFi from './application/locale-fi.json';
import { localize } from 'vee-validate';

import { hyphenatedToCamel, upperCamelToLowerCamel } from '../framework/util/convert';

const veeValidateEn = require('vee-validate/dist/locale/en.json');
const veeValidateFi = require('vee-validate/dist/locale/fi.json');

export const languages = {
    en: mergeDeep(en, appEn),
    fi: mergeDeep(fi, appFi),
};

const dictionary = {
    en: {
        names: mergeDeep(en.field, (appEn as any).field),
        fields: mergeDeep(en.custom, (appEn as any).custom),
    },
    fi: {
        names: mergeDeep(fi.field, (appFi as any).field),
        fields: mergeDeep(fi.custom, (appFi as any).custom),
    },
};

localize(dictionary as any);
localize('en', veeValidateEn);
localize('fi', veeValidateFi);

export function localizeVeeValidate(lang: string) {
    localize(lang);
}

export function getEnumValueLocalizationKey(enumName: string, enumValue: string) {
    if (enumValue === undefined || enumValue === null) {
        return undefined;
    }
    return 'enum.' + upperCamelToLowerCamel(enumName) + '.' + hyphenatedToCamel(enumValue.toLowerCase());
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item: any) {
    return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target: any, ...sources: any): any {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}
