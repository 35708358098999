
import { Component, Prop, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../framework/state';
import { deleteResource, getResourcePaging, getResources } from '../../../../framework/client/resource';
import { Unit } from '../../../../../common/application/model/Unit';
import { Customer } from '../../../../../common/application/model/Customer';
import { Organization } from '../../../../../common/application/model/Organization';
import { PAGE_SIZE } from '../../../../../common/framework/constants';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';

@Component
export default class Units extends Vue {
    readonly resourceType = ApplicationResource.UNIT;

    @Prop(String) readonly organizationId!: string;

    shared = sharedState;
    local = {
        rows: new Array<Unit>(),
        customers: new Map<string, Customer>(),
        organizations: new Map<string, Organization>(),
        customerId: '',
        total: 0,
        loading: false,
        page: 1,
        perPage: PAGE_SIZE,
        selected: undefined as Unit | undefined,
    };

    async mounted() {
        const organizations = (await getResources(
            'organization',
            0,
            new Map([['id', this.organizationId]]),
        )) as Array<Organization>;
        const organization = organizations[0];
        this.local.customerId = organization.customerId;
        this.local.organizations = new Map(organizations.map((o) => [o.id, o] as any)) as Map<string, Organization>;

        const customers = (await getResources(
            'customer',
            0,
            new Map([['id', this.local.customerId]]),
        )) as Array<Customer>;
        this.local.customers = new Map(customers.map((c) => [c.id, c] as any)) as Map<string, Customer>;

        await this.loadAsyncData();
    }

    async onPageChange(page: number) {
        this.local.page = page;
        await this.loadAsyncData();
    }

    add() {
        this.$router.push('/organizations/' + this.organizationId + '/units/add');
    }

    edit() {
        if (this.local.selected) {
            this.$router.push('/units/edit/' + this.local.selected.id);
        }
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    await deleteResource(this.resourceType, this.local.selected.id);
                    await this.loadAsyncData();
                }
            },
        });
    }

    async loadAsyncData() {
        this.local.loading = true;
        this.local.total = (
            await getResourcePaging(
                this.resourceType,
                new Map([
                    ['customerId', this.local.customerId],
                    ['organizationId', this.organizationId],
                ]),
            )
        ).rowCount;
        this.local.rows = [];
        const rows = (await getResources(
            this.resourceType,
            this.local.page - 1,
            new Map([
                ['customerId', this.local.customerId],
                ['organizationId', this.organizationId],
            ]),
        )) as Array<Unit>;
        rows.forEach((row) => {
            this.local.rows.push(row);
        });
        this.local.loading = false;
        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }
    }

    onRowClick(row: Unit) {
        if (this.local.selected && row.id === this.local.selected.id) {
            this.local.selected = undefined;
        }
    }
}
