
import { Component, Prop, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../framework/state';
import { deleteResource, getResources, postResource } from '../../../../framework/client/resource';
import { Role } from '../../../../../common/framework/model/Role';
import { UserRole } from '../../../../../common/framework/model/UserRole';

@Component
export default class UserRoles extends Vue {
    @Prop(String) readonly id!: string;

    shared = sharedState;
    local = {
        roles: [] as Role[],
        userRoles: [] as UserRole[],
        selectedUserRoleIds: {} as any,
    };

    async mounted() {
        const roles = await getResources<Role>('role');

        for (const role of roles) {
            this.local.selectedUserRoleIds[role.id] = false;
        }

        this.local.userRoles = this.id
            ? await getResources<UserRole>('user-role', -1, new Map([['userId', this.id]]))
            : [];

        for (const userRole of this.local.userRoles) {
            this.local.selectedUserRoleIds[userRole.roleId] = true;
        }

        this.local.roles = roles;
    }

    async submit() {
        await this.save();
    }

    async saveWithUserId(userId: string) {
        const selectedRoleIds = [] as string[];
        for (const selectedRoleId in this.local.selectedUserRoleIds) {
            if (this.local.selectedUserRoleIds[selectedRoleId]) {
                selectedRoleIds.push(selectedRoleId);
            }
        }

        const existingRoleIds = this.local.userRoles.map((ug) => ug.roleId);

        for (const selectedRoleId of selectedRoleIds) {
            if (existingRoleIds.indexOf(selectedRoleId) === -1) {
                await postResource<UserRole>('user-role', {
                    id: '',
                    userId: userId,
                    roleId: selectedRoleId,
                    created: new Date(),
                    modified: new Date(),
                });
            }
        }

        for (const existingRoleId of existingRoleIds) {
            if (selectedRoleIds.indexOf(existingRoleId) === -1) {
                const userRole = this.local.userRoles.filter((ug) => ug.roleId === existingRoleId)[0];
                await deleteResource('user-role', userRole.id);
            }
        }

        this.local.userRoles = await getResources<UserRole>('user-role', -1, new Map([['userId', userId]]));
    }

    async save() {
        await this.saveWithUserId(this.id);
    }
}
