export enum NotificationState {
    DRAFT = 'draft',
    CREATED = 'created',
    APPROVED = 'approved',
    REJECTED = 'rejected',
    CONFIRMED = 'confirmed',
    INDELIVERY = 'indelivery',
    DELIVERED = 'delivered',
    CANCELED = 'canceled',
    IMPORTER_ORDER_CONFIRMED = 'importerorder-confirmed',
    LICENSE_PLATE_CREATED = 'license-plate-created',
}
