import { apiDelete, apiGet, apiPost, apiPut } from './index';
import { PagingInfo } from '../../../common/framework/model/PagingInfo';
import { ERROR_API_CALL_FAILED } from '../../../common/framework/constants';
import { User } from '../../../common/framework/model/User';
import { UserInfo } from '../../../common/framework/model/UserInfo';
import { UserIdentity } from '../../../common/framework/model/UserIdentity';
import { UserName } from '../../../common/framework/model/UserName';

export async function getUser(id: string): Promise<User> {
    const response = await apiGet('/api/admin/users/' + id);
    if (response.status === 200) {
        return (await response.json()) as User;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getUserPaging(locked?: boolean): Promise<PagingInfo> {
    const response = await apiGet('/api/admin/users/paging', {
        locked: locked !== undefined ? '' + !!locked : undefined,
    });
    if (response.status === 200) {
        return (await response.json()) as PagingInfo;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getUsers(page: number, locked?: boolean): Promise<User[]> {
    const response = await apiGet('/api/admin/users', {
        page,
        locked: locked !== undefined ? '' + !!locked : undefined,
    });
    if (response.status === 200) {
        return (await response.json()) as User[];
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function deleteUser(id: string): Promise<void> {
    const response = await apiDelete('/api/admin/users/' + id);
    if (response.status === 200) {
        return;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function postUser(newUser: UserInfo): Promise<User> {
    const response = await apiPost('/api/admin/users', newUser);
    if (response.status === 200) {
        return (await response.json()) as User;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function putUser(userId: string, userInfo: UserInfo): Promise<User> {
    const response = await apiPut('/api/admin/users/' + userId, userInfo);
    if (response.status === 200) {
        return (await response.json()) as User;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getGroupUsersPaging(groupId: string): Promise<PagingInfo> {
    const response = await apiGet('/api/admin/groups/' + groupId + '/users/paging');
    if (response.status === 200) {
        return (await response.json()) as PagingInfo;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getGroupUsers(groupId: string, page: number): Promise<UserIdentity[]> {
    const response = await apiGet('/api/admin/groups/' + groupId + '/users', { page });
    if (response.status === 200) {
        return (await response.json()) as UserIdentity[];
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getRoleUsersPaging(roleId: string): Promise<PagingInfo> {
    const response = await apiGet('/api/admin/roles/' + roleId + '/users/paging');
    if (response.status === 200) {
        return (await response.json()) as PagingInfo;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getRoleUsers(roleId: string, page: number): Promise<UserIdentity[]> {
    const response = await apiGet('/api/admin/roles/' + roleId + '/users', { page });
    if (response.status === 200) {
        return (await response.json()) as UserIdentity[];
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getUserName(id: string): Promise<UserName> {
    const response = await apiGet('/api/username/' + id);
    if (response.status === 200) {
        return (await response.json()) as UserName;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function managerGetUser(id: string): Promise<User> {
    const response = await apiGet('/api/manager/users/' + id);
    if (response.status === 200) {
        return (await response.json() as User);
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function managerGetUserPaging(): Promise<PagingInfo> {
    const response = await apiGet('/api/manager/users/paging');
    if (response.status === 200) {
        return (await response.json()) as PagingInfo;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function managerGetUsers(page: number): Promise<User[]> {
    const response = await apiGet('/api/manager/users', { page });
    if (response.status === 200) {
        return (await response.json()) as User[];
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function managerDeleteUser(id: string): Promise<void> {
    const response = await apiDelete('/api/manager/users/' + id);
    if (response.status === 200) {
        return
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function managerPostUser(newUser: UserInfo): Promise<User> {
    const response = await apiPost('/api/manager/users', newUser);
    if (response.status === 200) {
        return (await response.json() as User);
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function managerPutUser(userId: string, userInfo: UserInfo): Promise<User> {
    const response = await apiPut('/api/manager/users/' + userId, userInfo);
    if (response.status === 200) {
        return (await response.json() as User);
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}
