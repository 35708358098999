
import { Component, Prop, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../../state';
import { getResource, putResource } from '../../../../../client/resource';
import TextField from '../../../../../fields/TextField.vue';
import SelectField from '../../../../../fields/SelectField.vue';
import { getEnumOptions } from '../../../../../service/options';
import { ValidationObserver } from 'vee-validate';
import { BakeType } from '../../../../../../../common/framework/model/BakeType';
import BakeInfo from '../../../../../../../common/framework/model/BakeInfo';
import IntegerField from '../../../../../fields/IntegerField.vue';
import NumberField from '../../../../../fields/NumberField.vue';

@Component({
    components: { SelectField, TextField, ValidationObserver, NumberField, IntegerField },
})
export default class EditBake extends Vue {
    // Constants
    readonly resourceType = 'bake-info';

    // Properties
    @Prop(String) readonly id!: string;

    // State
    shared = sharedState;
    local = {
        row: {} as BakeInfo,
        typeOptions: [] as { id: string | undefined; label: string }[],
    };

    // Functions
    async mounted() {
        this.local.typeOptions = getEnumOptions('BakeType', Object.keys(BakeType)).map((o) => {
            return { id: o.id, label: this.$t(o.labelKey).toString() };
        });
        this.local.row = (await getResource<BakeInfo>(this.resourceType, this.id))!!;
    }

    async save() {
        await putResource(this.resourceType, this.id, this.local.row);
        this.back();
    }

    back() {
        this.$router.go(-1);
    }
}
