import { Credentials } from '../../../common/framework/model/Credentials';
import { apiPost } from './index';
import { sharedState } from '../state';
import { BrowserContext } from '../../../common/framework/model/BrowserContext';
import { FrameworkFeature } from '../../../common/framework/enumeration/FrameworkFeature';
import { getAnonymousContext } from '../../../common/framework/service/browser_context_service';
import { VueRouter } from 'vue-router/types/router';

export async function postLogin(email: string, password: string): Promise<boolean> {
    const response = await apiPost('/api/login', new Credentials(email, password));

    if (response.status == 200) {
        sharedState.context = (await response.json()) as BrowserContext;
        if (typeof sessionStorage != 'undefined') {
            console.log('saved id-token to session storage.');
            sessionStorage.setItem('id-token', sharedState.context.idToken);
        }
        if (typeof document != 'undefined') {
            document.dispatchEvent(new Event('login'));
        }
        return true;
    } else {
        return false;
    }
}

function clearContextAndSessionStorage() {
    sharedState.context = getAnonymousContext();
    if (typeof sessionStorage != 'undefined') {
        sessionStorage.clear();
        console.log('cleared session storage.');
    }
}

export async function postLogout(router: VueRouter): Promise<void> {
    if (sharedState.enabled(FrameworkFeature.SAML_AUTHENTICATION)) {
        clearContextAndSessionStorage();
        window.location.href = '/saml/logout';
    } else {
        await apiPost('/api/logout', undefined);
        clearContextAndSessionStorage();
        await router.replace('/logged-out').catch(()=>{});
    }
}
