
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../state';
import { postResource } from '../../../client/resource';
import TextField from '../../../fields/TextField.vue';
import SelectField from '../../../fields/SelectField.vue';
import { ValidationObserver } from 'vee-validate';
import AutoCompleteField from '../../../fields/AutoCompleteField.vue';
import SwitchField from '../../../fields/SwitchField.vue';
import { Option } from '../../../../../common/framework/model/Option';
import NumberField from '../../../fields/NumberField.vue';
import TextAreaField from '../../../fields/TextAreaField.vue';
import DefaultOptionJsonDataField from '../../../fields/DefaultOptionJsonDataField.vue';

@Component({
    components: {
        NumberField,
        SwitchField,
        AutoCompleteField,
        SelectField,
        TextField,
        TextAreaField,
        DefaultOptionJsonDataField,
        ValidationObserver,
    },
})
export default class AddOption extends Vue {
    // Constants
    readonly resourceType = 'option';

    // Properties

    // State
    shared = sharedState;
    local = {
        row: {} as Option,
    };

    // Functions
    async mounted() {}

    async add() {
        const addedRow = await postResource<Option>(this.resourceType, this.local.row);
        this.back();
    }

    back() {
        this.$router.go(-1);
    }
}
