
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../../../state';
import { EngineState } from '../../model/EngineState';
import { EngineEntity } from '../../model/EngineEntity';
import { EngineContext } from '../../model/EngineContext';
import { addEntities } from '../../service/entity_service';
import { NexusClient } from '../../../../../../common/framework/module/xr/network/NexusClient';
import { SceneRenderer } from '../../render/SceneRenderer';
import { BabylonSceneRenderer } from '../../render/BabylonSceneRenderer';

@Component
export default class AssetRendererView extends Vue {
    @Prop(String) readonly assetId!: string | undefined;

    shared = sharedState;
    local = {
        renderCanvasClass: 'render-canvas disable-mobile-select box',
        renderer: undefined as SceneRenderer | undefined,
        assetId: undefined as undefined | string,
    };

    renderer: SceneRenderer | undefined;
    context: EngineContext | undefined;
    canvas: undefined | HTMLCanvasElement;

    async mounted() {
        this.canvas = (document.getElementById('render-canvas') as HTMLCanvasElement)!!;
        this.local.assetId = this.assetId;

        document.onkeydown = async (evt) => {
            evt = evt || window.event;
            var isEscape = false;
            if ('key' in evt) {
                isEscape = evt.key === 'Escape' || evt.key === 'Esc';
            } else {
                isEscape = (evt as any).keyCode === 27;
            }
            if (isEscape) {
                await this.setFullScreen(false);
            }
        };
        document.onfullscreenchange = async (e) => {
            console.log('full screen change:' + JSON.stringify(e));
            if (!document.fullscreenElement) {
                await this.setFullScreen(false);
            } else {
                (document.fullscreenElement as HTMLElement).focus();
                await this.setFullScreen(true);
            }
        };
        window.scrollTo(0, 0);

        const state: EngineState = {
            userId: sharedState.context.userId,
            authenticationToken: sharedState.context.idToken,
            engineTimer: undefined,
            engineRunning: false,
            inputFocus: false,
            lastNexusConnect: undefined,
            avatarHead: undefined,
            avatarLeftHand: undefined,
            avatarRightHand: undefined,
            avatarTorso: undefined,
            entities: new Map<string, EngineEntity>(),
            ownEntityIds: new Set<string>(),
            assets: new Map(),
            loadingEntityIds: new Map(),
        };

        let context = {
            state: state,
            renderer: undefined as SceneRenderer | undefined,
            client: undefined as NexusClient | undefined,
        };
        context.renderer = new BabylonSceneRenderer(this.canvas!!, context as EngineContext);
        this.context = context as EngineContext;

        this.renderer = context.renderer;
        this.local.renderer = context.renderer;
        await this.renderer.startRenderer();

        if (this.local.assetId) {
            await this.loadAsset();
        }

        await this.renderer.buildRayIntersectBvh();
    }

    private async loadAsset() {
        if (this.context && this.local.assetId) {
            await addEntities(
                [
                    {
                        id: '',
                        spaceId: '',
                        assetId: this.local.assetId,
                        parentId: null,
                        name: 'asset entity',
                        x: 0,
                        y: 0,
                        z: 5,
                        rx: 0,
                        ry: 0,
                        rz: 0,
                        rw: 0,
                        scale: 1,
                        radius: 1,
                        zone: false,
                        anchor: false,
                        visible: true,
                        dynamic: false,
                        metadata: {},
                        created: new Date(),
                        modified: new Date(),
                    },
                ] as EngineEntity[],
                this.context,
            );
        }
    }

    async destroyed() {
        console.log('destroyed');
        if (this.renderer) {
            this.renderer.stopRenderer();
        }
        document.onkeydown = null;
        document.onfullscreenchange = null;
        window.onbeforeunload = null;
    }

    async setFullScreen(fullScreen: boolean) {
        if (fullScreen) {
            this.local.renderCanvasClass = 'render-canvas-overlay disable-mobile-select';
        } else {
            this.local.renderCanvasClass = 'render-canvas disable-mobile-select';
        }

        if (this.renderer) {
            this.renderer!!.setFullScreen(fullScreen);
        }
    }

    @Watch('local.value')
    async inputValueChange(newValue: string) {
        this.$emit('input', newValue);
    }

    back() {
        this.$router.go(-1);
    }

    async enterFullScreen() {
        if (!document.fullscreenElement) {
            this.canvas!.requestFullscreen();
        }
    }

    async enterVr() {
        if (this.renderer) {
            await this.renderer.setVR(true);
        }
    }
}
