export enum MessageType {
    LOGIN_REQUEST = 'login-request',
    LOGIN_RESPONSE = 'login-response',
    JOIN_REQUEST = 'join-request',
    JOIN_RESPONSE = 'join-response',
    LEAVE_REQUEST = 'leave-request',
    LEAVE_RESPONSE = 'leave-response',
    CHAT = 'chat',
    MEDIA_STREAM = 'ms',
    ENTITY_INSERT = 'ei',
    ENTITY_MOVE = 'em',
    ENTITY_ACTION = 'ea',
    ENTITY_DELETE = 'ed',
}
