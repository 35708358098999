
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../state';

const localState = {
    email: '',
    password: '',
};

@Component
export default class LoginErrorView extends Vue {
    data() {
        return {
            shared: sharedState,
            local: localState,
        };
    }
    async login() {
        await this.$router.replace('/login');
    }
}
