
import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import TextField from '../../../../framework/fields/TextField.vue';

import { sharedState } from '../../../../framework/state';
import { postResource } from '../../../../framework/client/resource';
import { Customer } from '../../../../../common/application/model/Customer';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';

@Component({
    components: {
        ValidationObserver,
        TextField,
    },
})
export default class AddCustomer extends Vue {
    readonly resourceType = ApplicationResource.CUSTOMER;

    shared = sharedState;
    local = {
        row: {
            defaultDeliveryAddressCountryCode: 'FI',
        } as Customer,
    };

    async add() {
        const addedObject: Customer = await postResource(this.resourceType, this.local.row);
        await this.$router.replace('/customers/edit/' + addedObject.id);
    }

    back() {
        this.$router.go(-1);
    }
}
