import { getGlobalState } from '../../../service/global_state_service';
import { SpaceState } from '../../space/SpaceState';
import { FrameworkStateType } from '../../../../../common/framework/enumeration/FrameworkStateType';
import { EngineEntity } from '../../xr/model/EngineEntity';
import { MessageType } from '../../../../../common/framework/module/xr/network/model/MessageType';
import { JoinResponse } from '../../../../../common/framework/module/xr/network/model/JoinResponse';
import { getResource, getResources } from '../../../client/resource';
import { FrameworkResource } from '../../../../../common/framework/enumeration/FrameworkResource';
import ChannelUser from '../../../../../common/framework/model/ChannelUser';
import { UserIdentity } from '../../../../../common/framework/model/UserIdentity';
import { CommunicationState } from '../model/CommunicationState';
import { LeaveResponse } from '../../../../../common/framework/module/xr/network/model/LeaveResponse';
import { ChatMessage } from '../../../../../common/framework/module/xr/network/model/ChatMessage';
import { MessagePayload } from '../model/MessagePayload';
import { ChannelState } from '../model/ChannelState';
import { UserState } from '../model/UserState';
import { P_PAGE_SIZE, P_SORT_FIELD, P_SORT_ORDER } from '../../../../../common/framework/constants';
import { SortOrder } from '../../../../../common/framework/model/SortOrder';
import { ChatPayload } from '../model/ChatPayload';
import { isNil } from 'lodash';
import { dateToUiDateTimeString } from '../../../../../common/framework/util/convert';
import ChannelMessage from '../../../../../common/framework/model/ChannelMessage';

export function getSpaceChannelId() {
    return getGlobalState<SpaceState>(FrameworkStateType.SPACE_STATE).space.id;
}

/**
 * Get closest channel by entity loation.
 */
export function getLocalChannelId(entity: EngineEntity) {
    throw new Error('Not implemented.');
}

export async function onJoinResponseMessage(messageType: MessageType, message: JoinResponse) {
    console.log('joined: ' + message.ChannelName);
    const communicationState = getGlobalState<CommunicationState>(FrameworkStateType.COMMUNICATION_STATE);

    const channelId = message.ChannelId;
    const channelIndex = communicationState.channels.map((c) => c.id).indexOf(message.ChannelId);
    if (channelIndex !== -1) {
        console.warn('Channel already exists. Skipping channel add, user load and message load actions.');
        return;
    }

    //const channel = (await getResource<Channel>(FrameworkResource.CHANNEL, channelId))!!;

    const spaceState = getGlobalState<SpaceState>(FrameworkStateType.SPACE_STATE);
    const channelState: ChannelState = {
        id: channelId,
        name: spaceState.space.id === channelId ? spaceState.space.name : message.ChannelName,
        messages: [],
        users: [],
    };
    communicationState.channels.push(channelState);
    if (spaceState.space.id === channelId) {
        communicationState.spaceChannel = channelState;
    }

    const channelUsers = await getResources<ChannelUser>(
        FrameworkResource.CHANNEL_USER,
        -1,
        new Map([['channelId', channelId]]),
    );
    const userIds = [...new Set(channelUsers.map((cu) => cu.userId))];
    const users =
        userIds.length > 0
            ? await getResources<UserIdentity>(FrameworkResource.USER, -1, new Map([['idIn', userIds.join(',')]]))
            : [];
    for (const user of users) {
        addChannelUser(channelState, newUserState(user));
    }
    sortChannelUsers(channelState);

    const chatMessages = await getResources<ChannelMessage>(
        FrameworkResource.CHANNEL_MESSAGE,
        -1,
        new Map([
            ['channelId', channelId],
            [P_SORT_FIELD, 'created'],
            [P_SORT_ORDER, SortOrder.ASC],
            [P_PAGE_SIZE, '512'],
        ]),
    );
    const messageUserIds = [...new Set(chatMessages.map((m) => m.userId))];
    const messageUsers =
        messageUserIds.length > 0
            ? await getResources<UserIdentity>(
                  FrameworkResource.USER,
                  -1,
                  new Map([['idIn', messageUserIds.join(',')]]),
              )
            : [];
    for (const channelMessage of chatMessages) {
        try {
            const payload = JSON.parse(channelMessage.message);
            channelState.messages.push({
                timestamp: dateToUiDateTimeString(channelMessage.created),
                user: newUserState(messageUsers.filter((u) => u.id === channelMessage.userId)[0]),
                content: payload.message,
                payload: payload,
                message: {
                    ChannelId: channelMessage.channelId,
                    UserId: channelMessage.userId,
                    Message: channelMessage.message,
                },
            });
        } catch (e: any) {
            //console.log("Error parsing channel message: " + JSON.stringify(channelMessage, null, 2));
        }
    }
    //console.log(chatMessages);
}

export async function onLeaveResponseMessage(messageType: MessageType, message: LeaveResponse) {
    console.log('left: ' + message.ChannelId);
    const communicationState = getGlobalState<CommunicationState>(FrameworkStateType.COMMUNICATION_STATE);
    const channelIndex = communicationState.channels.map((c) => c.id).indexOf(message.ChannelId);
    if (channelIndex !== -1) {
        communicationState.channels.splice(channelIndex, 1);
    }
}

export async function onChatMessage(messageType: MessageType, message: ChatMessage) {
    //console.log("chat: " + JSON.stringify(message, null, 2))
    const communicationState = getGlobalState<CommunicationState>(FrameworkStateType.COMMUNICATION_STATE);
    const payload = JSON.parse(message.Message) as MessagePayload;
    //console.log(payload.type);

    if (payload.type === 'join') {
        const user = await getResource<UserIdentity>(FrameworkResource.USER, message.UserId);
        const channelState = communicationState.channels.filter((c) => c.id === message.ChannelId)[0];
        if (user && channelState) {
            const userState = addChannelUser(channelState, newUserState(user));
            if (!isNil(userState)) {
                sortChannelUsers(channelState);
            }
            channelState.messages.push({
                timestamp: dateToUiDateTimeString(new Date()),
                user: getChannelUser(channelState, message),
                content: 'joined.',
                payload: payload,
                message: message,
            });
        }
    }

    if (payload.type === 'leave') {
        const communicationState = getGlobalState<CommunicationState>(FrameworkStateType.COMMUNICATION_STATE);
        const channelState = communicationState.channels.filter((c) => c.id === message.ChannelId)[0];
        if (channelState) {
            const userState = removeChannelUser(channelState, message);
            if (!isNil(userState)) {
                channelState.messages.push({
                    timestamp: dateToUiDateTimeString(new Date()),
                    user: userState,
                    content: 'left.',
                    payload: payload,
                    message: message,
                });
            }
        }
    }

    if (payload.type === 'chat') {
        const chatPayload = payload as ChatPayload;
        const channelState = communicationState.channels.filter((c) => c.id === message.ChannelId)[0];
        const userState = getChannelUser(channelState, message);
        if (!isNil(channelState) && !isNil(userState)) {
            channelState.messages.push({
                timestamp: dateToUiDateTimeString(new Date(chatPayload.timestamp)),
                user: userState,
                content: chatPayload.message,
                payload: payload,
                message: message,
            });
        }
    }
}

function addChannelUser(channelState: ChannelState, user: UserState) {
    const matchingUsers = channelState.users.filter((u) => u.id === user.id);
    if (matchingUsers.length === 0) {
        channelState.users.push(user);
        return undefined;
    } else {
        return matchingUsers[0];
    }
}

function removeChannelUser(channelState: ChannelState, message: ChatMessage) {
    const userIndex = channelState.users.map((u) => u.id).indexOf(message.UserId);
    if (userIndex !== -1) {
        const userState = channelState.users[userIndex];
        channelState.users.splice(userIndex, 1);
        return userState;
    } else {
        return undefined;
    }
}

function getChannelUser(channelState: ChannelState, message: ChatMessage) {
    const userIndex = channelState.users.map((u) => u.id).indexOf(message.UserId);
    return channelState.users[userIndex];
}

function sortChannelUsers(channelState: ChannelState) {
    channelState.users.sort((a, b) => {
        return a.name.localeCompare(b.name);
    });
}

function newUserState(user: UserIdentity): UserState {
    return {
        get id() {
            return user.id;
        },
        get name() {
            return user.firstName + ' ' + user.lastName;
        },
        get user() {
            return user;
        },
        voiceTransmission: false,
    };
}
