
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { sharedState } from '../../../../../../framework/state';
import { getResource, putResource } from '../../../../../../framework/client/resource';
import { getRetrofittedAccessoryOptions } from '../../../../../service/options';
import { getVehicleModelInfo } from '../../../../../client/vehicle_model_client';
import { ApplicationResource } from '../../../../../../../common/application/enumeration/ApplicationResource';
import { TailoredVehicleModel } from '../../../../../../../common/application/model/TailoredVehicleModel';
import { TailoredRetrofittedAccessory } from '../../../../../../../common/application/model/TailoredRetrofittedAccessory';
import { getOptionLabel, OptionValue } from '../../../../../../framework/service/options';
import TextField from '../../../../../../framework/fields/TextField.vue';
import SelectField from '../../../../../../framework/fields/SelectField.vue';
import NumberField from '../../../../../../framework/fields/NumberField.vue';

@Component({
    components: {
        NumberField,
        SelectField,
        ValidationObserver,
        TextField,
    },
})
export default class EditTailoredRetrofittedAccessory extends Vue {
    readonly resourceType = ApplicationResource.TAILORED_RETROFITTED_ACCESSORY;

    @Prop(String) readonly id!: string;

    shared = sharedState;
    local = {
        row: {} as TailoredRetrofittedAccessory,
        tailoredVehicleModel: undefined as TailoredVehicleModel | undefined,
        accessoryOptions: [] as OptionValue[],
    };

    async mounted() {
        this.local.row = (await getResource<TailoredRetrofittedAccessory>(this.resourceType, this.id))!!;
        this.local.tailoredVehicleModel = (await getResource<TailoredVehicleModel>(
            ApplicationResource.TAILORED_VEHICLE_MODEL,
            this.local.row.tailoredVehicleModelId,
        ))!!;

        this.local.accessoryOptions = await getRetrofittedAccessoryOptions(
            this.local.tailoredVehicleModel.modelExternalId,
        );
    }

    @Watch('local.row.externalId')
    async externalIdChanged(externalId: string) {
        if (externalId) {
            const vehicleModelInfo = await getVehicleModelInfo(this.local.tailoredVehicleModel!!.modelExternalId);
            const factoryAccessory = vehicleModelInfo.factoryAccessories.filter(
                (o) => o.oid.toString() === externalId,
            )[0];

            this.local.row = {
                ...this.local.row,
                name: getOptionLabel(externalId, this.local.accessoryOptions),
                vendorAccessoryCode: factoryAccessory.code,
                description: factoryAccessory.description,
                netPrice: factoryAccessory.netPrice ? factoryAccessory.netPrice : 0,
            };
        } else {
            this.local.row = {
                ...this.local.row,
                name: '',
                vendorAccessoryCode: '',
                description: '',
                netPrice: 0,
            };
        }
    }

    async save() {
        await putResource(this.resourceType, this.id, this.local.row);
        this.$router.go(-1);
    }

    back() {
        this.$router.go(-1);
    }
}
