
import { Component, Prop, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../framework/state';
import { getResource, putResource } from '../../../../framework/client/resource';
import { getEnumOptions, getGroupOptions } from '../../../service/options';
import TextField from '../../../../framework/fields/TextField.vue';
import SelectField from '../../../../framework/fields/SelectField.vue';
import Asset from '../../../../../common/framework/model/Asset';
import { AssetType } from '../../../../../common/framework/model/AssetType';
import AssetBinaryComponent from './AssetBinaryComponent.vue';
import { getAssetBinaryMeta } from '../../../client/asset_binary_client';
import { ValidationObserver } from 'vee-validate';
import TextAreaField from '../../../fields/TextAreaField.vue';
import AssetLinks from './link/AssetLinks.vue';
import AssetRendererView from '../../../module/xr/components/shared/AssetRenderView.vue';
import SwitchField from '../../../fields/SwitchField.vue';

@Component({
    components: {
        AssetLinks,
        TextAreaField,
        AssetBinaryComponent,
        SelectField,
        TextField,
        SwitchField,
        ValidationObserver,
        AssetRendererView,
    },
})
export default class EditAsset extends Vue {
    // Constants
    readonly resourceType = 'asset';

    // Properties
    @Prop(String) readonly id!: string;

    // State
    shared = sharedState;
    local = {
        row: {
            id: '',
            type: '' as AssetType,
            category: '',
            name: '',
            key: '',
            description: '',
            public: true,
            created: undefined as any as Date,
            modified: undefined as any as Date,
        } as Asset,
        assetBinaryId: undefined as any as string,
        typeOptions: [] as { id: string | undefined; label: string }[],
    };

    // Functions
    async mounted() {
        this.local.typeOptions = getEnumOptions('AssetType', Object.keys(AssetType)).map((o) => {
            return { id: o.id, label: this.$t(o.labelKey).toString() };
        });
        this.local.row = (await getResource<Asset>(this.resourceType, this.id))!!;
    }

    async save() {
        await putResource(this.resourceType, this.id, this.local.row);
        this.back();
    }

    back() {
        this.$router.go(-1);
    }
}
