
import { Component, Prop, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../state';
import TextField from '../../../../fields/TextField.vue';
import SelectField from '../../../../fields/SelectField.vue';
import { SpaceType } from '../../../../../../common/framework/model/SpaceType';
import Space from '../../../../../../common/framework/model/Space';
import SpaceRenderView from '../shared/SpaceRenderView.vue';

@Component({
    components: { SpaceRenderView, SelectField, TextField },
})
export default class SpaceView extends Vue {
    // Constants
    readonly resourceType = 'space';

    // Properties
    @Prop(String) readonly id!: string;

    // State
    shared = sharedState;
    local = {
        row: {
            id: '',
            type: '' as SpaceType,
            category: '',
            name: '',
            key: '',
            description: '',
            created: undefined as any as Date,
            modified: undefined as any as Date,
        } as Space,
    };

    // Functions
    async mounted() {
        /*if (this.id) {
            this.local.row = (await getResource<Space>(this.resourceType, this.id))!!;
            if (!_.isNil(this.local.row)) {
                await setSpace(this.local.row);
            }
        }*/
    }

    async destroyed() {}

    back() {
        this.$router.go(-1);
    }
}
