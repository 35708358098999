
import { Component, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../../../../framework/state';
import { managerPostUser } from '../../../../../framework/client/users';
import { postRequestTokenPasswordChange } from '../../../../../framework/client/password';
import { getOrganizationOptions, getUnitOptions } from '../../../../service/options';
import { UserInfo } from '../../../../../../common/framework/model/UserInfo';
import { OptionValue } from '../../../../../framework/service/options';
import TextField from '../../../../../framework/fields/TextField.vue';
import SelectField from '../../../../../framework/fields/SelectField.vue';
import EmailField from '../../../../../framework/fields/EmailField.vue';
import PhoneField from '../../../../../framework/fields/PhoneField.vue';
import { ValidationObserver } from 'vee-validate';

@Component({
    components: { SelectField, TextField, EmailField, PhoneField, ValidationObserver },
})
export default class AddCustomerUser extends Vue {
    shared = sharedState;
    local = {
        row: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            importerId: undefined as undefined | string,
            dealerId: undefined as undefined | string,
            customerId: sharedState.context.customerId,
            organizationId: undefined as undefined | string,
            unitId: undefined as undefined | string,
            admin: false,
            manager: false,
            configurer: false,
            orderer: false,
            approver: false,
            dealer: false,
            locale: '',
        } as UserInfo,
        localeOptions: [
            { key: 'fi', label: 'FI' },
            { key: 'en', label: 'EN' },
        ],
        organizationOptions: [] as OptionValue[],
        unitOptions: [] as OptionValue[],
    };

    async mounted() {
        //
    }

    @Watch('local.row.customerId')
    public async onCustomerIdChange(newCustomerId: string) {
        if (newCustomerId) {
            this.local.organizationOptions = await getOrganizationOptions(newCustomerId);
            if (this.local.organizationOptions.filter((o) => o.id === this.local.row.organizationId).length === 0) {
                this.local.row = {
                    ...this.local.row,
                    organizationId: undefined,
                };
            }
        } else {
            this.local.organizationOptions = [{ id: undefined, label: '' }];
            this.local.row = {
                ...this.local.row,
                organizationId: undefined,
            };
        }
    }

    @Watch('local.row.organizationId')
    public async onOrganizationIdChange(newOrganizationId: string) {
        if (newOrganizationId) {
            this.local.unitOptions = await getUnitOptions(newOrganizationId);
            if (this.local.unitOptions.filter((o) => o.id === this.local.row.unitId).length === 0) {
                this.local.row = {
                    ...this.local.row,
                    unitId: undefined,
                };
            }
        } else {
            this.local.unitOptions = [{ id: undefined, label: '' }];
            this.local.row = {
                ...this.local.row,
                unitId: undefined,
            };
        }
    }

    async add() {
        await managerPostUser(
            new UserInfo(
                this.local.row.firstName,
                this.local.row.lastName,
                this.local.row.email,
                this.local.row.phone,
                this.local.row.admin,
                this.local.row.manager,
                this.local.row.configurer,
                this.local.row.dealer,
                this.local.row.orderer,
                this.local.row.approver,
                this.local.row.locale,
                false,
                this.local.row.importerId,
                this.local.row.dealerId,
                this.local.row.customerId,
                this.local.row.organizationId,
                this.local.row.unitId,
            ),
        );
        await this.$router.push('/customers/users');

        if (await postRequestTokenPasswordChange(this.local.row.email)) {
            this.$buefy.toast.open({
                message: 'User added and invited.',
                type: 'is-success',
                position: 'is-top',
            });
        } else {
            this.$buefy.toast.open({
                message: 'User added but invitation failed.',
                type: 'is-warning',
                position: 'is-top',
            });
        }

        this.local.row = {
            ...this.local.row,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            admin: false,
            manager: false,
            configurer: false,
            dealer: false,
            orderer: false,
            approver: false,
            importerId: undefined as undefined | string,
            dealerId: undefined as undefined | string,
            customerId: undefined,
            organizationId: undefined as undefined | string,
            unitId: undefined as undefined | string,
        };
    }

    back() {
        this.$router.go(-1);
    }
}
