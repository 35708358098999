import { getResources } from '../client/resource';
import { FrameworkResource } from '../../../common/framework/enumeration/FrameworkResource';
import { Group } from '../../../common/framework/model/Group';
import {
    camelToHyphenated,
    camelToUnderscore,
    lowerCamelToUpperCamel,
    underscoreToCamel,
    underscoreToHyphenated,
    upperCamelToLowerCamel,
} from '../../../common/framework/util/convert';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { Cache, cachedLoad, ValueLoadedToCacheCallback } from '../../framework/service/cache_service';
import { Option } from '../../../common/framework/model/Option';
import { sharedState } from '../state';

export interface OptionValue {
    id: string | undefined;
    label: string;
}

const optionsCache: Cache<OptionValue[]> = {
    values: new Map<string, OptionValue[]>(),
    callbacks: new Map<string, ValueLoadedToCacheCallback<OptionValue[]>[]>(),
};

export async function getResourceOptions(resource: string, field: string) {
    return await cachedLoad(
        resource + '-options',
        async () => {
            return [{ id: undefined as string | undefined, label: '-' }].concat(
                (await getResources<any>(resource, -1)).map((o) => {
                    return { id: o.id, label: o[field] };
                }),
            );
        },
        optionsCache,
    );
}

export async function getGroupOptions() {
    return getResourceOptions('group', 'name');
}

export function getOptionLabel(id: string, options: Array<{ id: string | undefined; label: string }>) {
    const filteredOptions = options.filter((o) => o.id == id);
    if (filteredOptions.length >= 1) {
        return options.filter((o) => o.id == id)[0].label;
    } else {
        return id;
    }
}

export function getOptionLabelKey(id: string, options: Array<{ id: string | undefined; labelKey: string }>) {
    const filteredOptions = options.filter((o) => o.id == id);
    if (filteredOptions.length >= 1) {
        return options.filter((o) => o.id == id)[0].labelKey;
    } else {
        return id;
    }
}

export function getEnumOptions(enumName: string, enumKeys: string[]) {
    return [{ id: undefined as string | undefined, labelKey: '' }].concat(
        enumKeys.map((key) => {
            return {
                id: underscoreToHyphenated(key),
                labelKey: 'enum.' + upperCamelToLowerCamel(enumName) + '.' + underscoreToCamel(key.toLowerCase()),
            };
        }),
    );
}

export function getEnumerationOptions(enumName: string, keys: string[], component: Vue) {
    return getEnumOptions(enumName, keys).map((o) => {
        const localizedValue = component.$t(o.labelKey).toString();
        if (localizedValue === o.labelKey && o.labelKey !== '') {
            if (typeof sessionStorage != 'undefined') {
                console.log('writing missing localization key to session storage: ' + o.labelKey);
                if (!sessionStorage.getItem('missingLocalizationKeys')) {
                    sessionStorage.setItem('missingLocalizationKeys', JSON.stringify({}));
                }
                const missingLocalizationKeys = JSON.parse(sessionStorage.getItem('missingLocalizationKeys')!!);
                const fields = o.labelKey.split('.');
                if (fields.length !== 0 && fields[0] !== '') {
                    let obj = missingLocalizationKeys;
                    for (let i = 0; i < fields.length; i++) {
                        const field = fields[i];
                        const last = i === fields.length - 1;
                        if (typeof obj[field] == 'undefined') {
                            if (!last) {
                                obj[field] = {};
                            } else {
                                obj[field] = '';
                            }
                        }
                        obj = obj[field]!!;
                    }
                    sessionStorage.setItem('missingLocalizationKeys', JSON.stringify(missingLocalizationKeys, null, 2));
                }
            }
        }
        return { id: o.id, label: localizedValue };
    });
}

export async function getDatabaseOptions(typeName: string, component: Vue) {
    const type = camelToHyphenated(typeName);
    return await cachedLoad(
        type + '-database-options',
        async () => {
            console.log('loading database options: ' + type);
            const parameters = new Map();
            parameters.set('type', type);
            parameters.set('sortField', 'index');
            parameters.set('sortOrder', 'asc');
            const options = await getResources<Option>(FrameworkResource.OPTION, -1, parameters);
            return [{ id: undefined as string | undefined, label: '-' }].concat(
                options
                    .sort((a, b) => a.index - b.index)
                    .map((o) => {
                        const data = o.metadata as any;
                        return { id: o.key, label: component.$i18n.locale === 'fi' ? data.labelFi : data.labelEn };
                    }),
            );
        },
        optionsCache,
    );
}
