
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../state';
import { postResource } from '../../../client/resource';
import { getEnumOptions } from '../../../service/options';
import TextField from '../../../../framework/fields/TextField.vue';
import SelectField from '../../../../framework/fields/SelectField.vue';
import Asset from '../../../../../common/framework/model/Asset';
import { AssetType } from '../../../../../common/framework/model/AssetType';
import { ValidationObserver } from 'vee-validate';
import TextAreaField from '../../../fields/TextAreaField.vue';
import SwitchField from '../../../fields/SwitchField.vue';

@Component({
    components: { TextAreaField, SelectField, TextField, SwitchField, ValidationObserver },
})
export default class AddAsset extends Vue {
    // Constants
    readonly resourceType = 'asset';

    // Properties

    // State
    shared = sharedState;
    local = {
        row: {
            id: '',
            type: '' as AssetType,
            category: '',
            name: '',
            key: '',
            description: '',
            public: true,
            created: undefined as any as Date,
            modified: undefined as any as Date,
        } as Asset,
        typeOptions: [] as { id: string | undefined; label: string }[],
    };

    // Functions
    async mounted() {
        this.local.typeOptions = getEnumOptions('AssetType', Object.keys(AssetType)).map((o) => {
            return { id: o.id, label: this.$t(o.labelKey).toString() };
        });
    }

    async add() {
        const addedRow = await postResource<Asset>(this.resourceType, this.local.row);
        await this.$router.replace('/asset/' + addedRow.id + '/edit');
    }

    back() {
        this.$router.go(-1);
    }
}
