import { apiGet } from './index';
import { SoftwareVersion } from '../../../common/framework/model/SoftwareVersion';
import { sharedState } from '../state';

export async function getVersion(): Promise<SoftwareVersion | undefined> {
    try {
        let urlParams = new URLSearchParams(window.location.search);

        const response = await apiGet(
            '/api/version' + (urlParams.has('token') ? '?token=' + urlParams.get('token') : ''),
        );
        if (response.status === 200) {
            sharedState.connected = true;
            return (await response.json()) as SoftwareVersion;
        } else {
            return undefined;
        }
    } catch (e: any) {
        console.error('Error occurred when getting version: ', e);
    }
}
