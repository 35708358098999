
import { Component, Prop, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../framework/state';
import { deleteResource, getResources, postResource } from '../../../../framework/client/resource';
import { Group } from '../../../../../common/framework/model/Group';
import { UserGroup } from '../../../../../common/framework/model/UserGroup';

@Component
export default class UserGroups extends Vue {
    @Prop(String) readonly id!: string;

    shared = sharedState;
    local = {
        groups: [] as Group[],
        userGroups: [] as UserGroup[],
        selectedUserGroupIds: {} as any,
    };

    async mounted() {
        const groups = await getResources<Group>('group');

        for (const group of groups) {
            this.local.selectedUserGroupIds[group.id] = false;
        }

        this.local.userGroups = this.id
            ? await getResources<UserGroup>('user-group', -1, new Map([['userId', this.id]]))
            : [];

        for (const userGroup of this.local.userGroups) {
            this.local.selectedUserGroupIds[userGroup.groupId] = true;
        }

        this.local.groups = groups;
    }

    async submit() {
        await this.save();
    }

    async saveWithUserId(userId: string) {
        const selectedGroupIds = [] as string[];
        for (const selectedGroupId in this.local.selectedUserGroupIds) {
            if (this.local.selectedUserGroupIds[selectedGroupId]) {
                selectedGroupIds.push(selectedGroupId);
            }
        }

        const existingGroupIds = this.local.userGroups.map((ug) => ug.groupId);

        for (const selectedGroupId of selectedGroupIds) {
            if (existingGroupIds.indexOf(selectedGroupId) === -1) {
                await postResource<UserGroup>('user-group', {
                    id: '',
                    userId: userId,
                    groupId: selectedGroupId,
                    created: new Date(),
                    modified: new Date(),
                });
            }
        }

        for (const existingGroupId of existingGroupIds) {
            if (selectedGroupIds.indexOf(existingGroupId) === -1) {
                const userGroup = this.local.userGroups.filter((ug) => ug.groupId === existingGroupId)[0];
                await deleteResource('user-group', userGroup.id);
            }
        }

        this.local.userGroups = await getResources<UserGroup>('user-group', -1, new Map([['userId', userId]]));
    }

    async save() {
        await this.saveWithUserId(this.id);
    }
}
