
import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { sharedState } from '../../../../framework/state';
import { postResource } from '../../../../framework/client/resource';
import { Dealer } from '../../../../../common/application/model/Dealer';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';
import TextField from '../../../../framework/fields/TextField.vue';

@Component({
    components: { TextField, ValidationObserver },
})
export default class AddDealer extends Vue {
    readonly resourceType = ApplicationResource.DEALER;

    shared = sharedState;
    local = {
        row: {} as Dealer,
    };

    clear() {
        this.local.row = {} as Dealer;
    }

    async add() {
        await postResource(this.resourceType, this.local.row);

        this.$router.go(-1);
        this.clear();
    }

    back() {
        this.$router.go(-1);
    }
}
