
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { sharedState } from '../../../../../../framework/state';
import { getResource, postResource } from '../../../../../../framework/client/resource';
import { getFactoryAccessoryCategoryOptions, getFactoryAccessoryOptions } from '../../../../../service/options';
import { getVehicleModelInfo } from '../../../../../client/vehicle_model_client';
import TextField from '../../../../../../framework/fields/TextField.vue';
import { TailoredFactoryAccessory } from '../../../../../../../common/application/model/TailoredFactoryAccessory';
import { FactoryAccessoryCategory } from '../../../../../../../common/application/model/erp/FactoryAccessoryCategory';
import { TailoredVehicleModel } from '../../../../../../../common/application/model/TailoredVehicleModel';
import { ApplicationResource } from '../../../../../../../common/application/enumeration/ApplicationResource';
import SelectField from '../../../../../../framework/fields/SelectField.vue';
import { getOptionLabel, getOptionLabelKey, OptionValue } from '../../../../../../framework/service/options';

@Component({
    components: { SelectField, TextField, ValidationObserver },
})
export default class AddTailoredFactoryAccessory extends Vue {
    readonly resourceType = ApplicationResource.TAILORED_FACTORY_ACCESSORY;
    @Prop(String) readonly tailoredVehicleModelId!: string;

    shared = sharedState;
    local = {
        row: {
            netPrice: 0,
            carTax: 0,
            category: FactoryAccessoryCategory.DEFAULT,
            tailoredVehicleModelId: this.tailoredVehicleModelId,
        } as TailoredFactoryAccessory,
        factoryAccessoryOptions: [] as OptionValue[],
        tailoredVehicleModel: undefined as TailoredVehicleModel | undefined,
        factoryAccessoryCategoryOptions: [] as Array<{ id: undefined | string; labelKey: string }>,
        category: '',
        loading: false,
    };

    async mounted() {
        await this.loadAsyncData();
    }

    @Watch('local.row.externalId')
    async externalIdChanged(externalId: string) {
        if (this.local.loading) {
            return;
        }

        if (externalId) {
            const name = getOptionLabel(externalId, this.local.factoryAccessoryOptions);

            const vehicleModelInfo = await getVehicleModelInfo(this.local.tailoredVehicleModel!!.modelExternalId);
            const factoryAccessory = vehicleModelInfo.factoryAccessories.filter(
                (o) => o.oid.toString() === externalId,
            )[0];
            const vendorFactoryAccessoryCode = factoryAccessory.code;
            const category = factoryAccessory.category;
            this.local.category = this.$t(
                getOptionLabelKey(factoryAccessory.category, this.local.factoryAccessoryCategoryOptions),
            ).toString();
            const description = factoryAccessory.description;

            this.local.row = {
                ...this.local.row,
                name,
                vendorFactoryAccessoryCode,
                category,
                description,
            };
        } else {
            this.local.row = {
                ...this.local.row,
                name: '',
                vendorFactoryAccessoryCode: '',
                category: FactoryAccessoryCategory.DEFAULT,
                description: '',
            };
        }
    }

    async add() {
        await postResource(this.resourceType, this.local.row);

        this.$router.go(-1);
    }

    back() {
        this.$router.go(-1);
    }

    private async loadAsyncData(): Promise<void> {
        this.local.loading = true;

        this.local.tailoredVehicleModel = (await getResource<TailoredVehicleModel>(
            ApplicationResource.TAILORED_VEHICLE_MODEL,
            this.tailoredVehicleModelId,
        ))!!;

        this.local.row = {
            ...this.local.row,
            customerId: this.local.tailoredVehicleModel.customerId,
        };
        this.local.factoryAccessoryOptions = await getFactoryAccessoryOptions(
            this.local.tailoredVehicleModel.modelExternalId,
        );
        this.local.factoryAccessoryCategoryOptions = getFactoryAccessoryCategoryOptions();

        this.local.loading = false;
    }
}
