
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { sharedState } from '../../../../framework/state';
import { deleteAssetBinary, getAssetBinaryMeta } from '../../../client/asset_binary_client';
import { hyphenatedToCamel, lowerCamelToUpperCamel } from '../../../../../common/framework/util/convert';
import AssetBinary from '../../../../../common/framework/model/AssetBinary';
import AssetRenderView from '../../../module/xr/components/shared/AssetRenderView.vue';
@Component
export default class AssetBinaryComponent extends Vue {
    @Prop(String) readonly assetId!: string;
    @Prop(String) readonly type!: string;
    //@Prop(String) readonly assetBinaryId!: string;

    shared = sharedState;
    local = {
        file: null as { name: string; size: number; type: string } | null,
        loading: false,
        assetBinary: undefined as AssetBinary | undefined,
        assetBinaryId: undefined as string | undefined,
    };

    async mounted() {
        this.local.assetBinary = await getAssetBinaryMeta(this.assetId, this.type);
        if (this.local.assetBinary) {
            this.local.assetBinaryId = this.local.assetBinary.id;
        }

        /*if (this.local.assetBinaryId === undefined || this.local.assetBinaryId === '') {
                const assetBinary = await getAssetBinaryMeta(this.assetId, this.type);
                if (assetBinary !== undefined) {
                    this.local.assetBinaryId = assetBinary.id;
                }

            }*/
    }

    @Watch('local.file')
    async fileChange(file: { name: string; size: number; type: string }) {
        if (file && file.name) {
            this.upload();
        }
    }

    upload() {
        this.local.loading = true;

        const formData = new FormData();
        const elementId = 'assetBinaryUpload' + this.type;
        formData.append('assetBinary', (document.getElementById(elementId) as any).files[0]);

        const xhr = new XMLHttpRequest();
        xhr.addEventListener('loadend', (e: Event) => {
            this.local.loading = false;
        });
        xhr.addEventListener('error', () => {
            this.errorToast();
            this.local.loading = false;
        });
        xhr.addEventListener('abort', () => {
            this.local.loading = false;
        });
        xhr.onreadystatechange = () => {
            if (xhr.readyState == 4) {
                if (xhr.status == 200 || xhr.status == 301) {
                    const response: { id: string } = JSON.parse(xhr.responseText);
                    this.local.assetBinaryId = response.id;
                    //this.local.assetBinary = await getAssetBinaryMeta(this.assetId, this.type);
                } else {
                    console.warn('Upload returned error status: ' + xhr.status);
                    this.errorToast();
                }
            }
        };

        xhr.open('POST', '/api/resource/asset/' + this.assetId + '/bytes/' + this.type, true);
        xhr.setRequestHeader('Authorization', 'Bearer ' + sharedState.context.idToken);
        //xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(formData);
    }

    @Watch('local.assetBinaryId')
    async assetBinaryIdChange(newValue: string) {
        this.local.assetBinary = await getAssetBinaryMeta(this.assetId, this.type);
    }

    errorToast() {
        this.$buefy.toast.open({
            message: this.$t('error.system.error').toString(),
            type: 'is-danger',
            position: 'is-top',
        });
    }

    async deleteImage() {
        await deleteAssetBinary(this.assetId, this.type);
        this.local.assetBinaryId = undefined;
        this.local.assetBinary = undefined;
    }

    convertHyphenatedToCamel(str: string) {
        return lowerCamelToUpperCamel(hyphenatedToCamel(str));
    }
}
