
import { Component, Prop, Vue } from 'vue-property-decorator';
import { sharedState } from '../../../../state';
import { EngineContext } from '../../model/EngineContext';
import { CommunicationState } from '../../../communication/model/CommunicationState';
import { getGlobalState } from '../../../../service/global_state_service';
import { FrameworkStateType } from '../../../../../../common/framework/enumeration/FrameworkStateType';

@Component({
    components: {},
})
export default class SpaceUsersView extends Vue {
    @Prop(Object) readonly context!: EngineContext;

    shared = sharedState;
    local = {
        communicationState: {} as any as CommunicationState,
    };

    async mounted() {
        this.local.communicationState = getGlobalState<CommunicationState>(FrameworkStateType.COMMUNICATION_STATE);
    }

    async destroyed() {}
}
