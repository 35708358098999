
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../../state';
import { PAGE_SIZE } from '../../../../../../../common/framework/constants';

import { deleteResource, getResourcePaging, getResources } from '../../../../../client/resource';
import BakeInfo from '../../../../../../../common/framework/model/BakeInfo';
import { BakeType } from '../../../../../../../common/framework/model/BakeType';
import { getEnumOptions, getOptionLabel } from '../../../../../service/options';

@Component
export default class Bakes extends Vue {
    readonly resourceType = 'bake-info';

    shared = sharedState;
    local = {
        rows: new Array<BakeInfo>(),
        total: 0,
        loading: false,
        page: 1,
        perPage: PAGE_SIZE,
        selected: undefined as BakeInfo | undefined,
        typeOptions: [] as { id: string | undefined; label: string }[],
    };

    async mounted() {
        this.local.typeOptions = getEnumOptions('BakeType', Object.keys(BakeType)).map((o) => {
            return { id: o.id, label: this.$t(o.labelKey).toString() };
        });
        await this.loadAsyncData();
    }

    async onPageChange(page: number) {
        this.local.page = page;
        await this.loadAsyncData();
    }

    uploadBake() {
        this.$router.push('/bake/upload');
    }

    edit() {
        if (this.local.selected) {
            this.$router.push('/bake/' + this.local.selected.id + '/edit');
        }
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    await deleteResource(this.resourceType, this.local.selected.id);
                    await this.loadAsyncData();
                }
            },
        });
    }

    async loadAsyncData() {
        this.local.loading = true;
        this.local.total = (await getResourcePaging(this.resourceType)).pageCount * PAGE_SIZE;
        this.local.rows = [];
        const rows = (await getResources(this.resourceType, this.local.page - 1)) as Array<BakeInfo>;
        rows.forEach((row) => {
            this.local.rows.push(row);
        });
        this.local.loading = false;
        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }
    }

    getBakeTypeLabel(id: string): string {
        return getOptionLabel(id, this.local.typeOptions);
    }
}
