export enum ApplicationResource {
    CUSTOMER = 'customer',
    CUSTOMER_DEALER = 'customer-dealer',
    DEALER = 'dealer',
    IMPORTER = 'importer',
    ORGANIZATION = 'organization',
    UNIT = 'unit',
    RETROFITTED_ACCESSORY = 'retrofitted-accessory',
    RETROFITTED_ACCESSORY_IMAGE = 'retrofitted-accessory-image',
    TAILORED_VEHICLE_MODEL = 'tailored-vehicle-model',
    TAILORED_VEHICLE_MODEL_IMAGE = 'tailored-vehicle-model-image',
    TAILORED_FACTORY_ACCESSORY = 'tailored-factory-accessory',
    TAILORED_RETROFITTED_ACCESSORY = 'tailored-retrofitted-accessory',
    ORDER = 'order',
    ORDER_FACTORY_ACCESSORY = 'order-factory-accessory',
    ORDER_RETROFITTED_ACCESSORY = 'order-retrofitted-accessory',
    VEHICLE = 'vehicle',
    SALES_PERSON = 'sales-person',
    ORDERER_PERSON = 'orderer-person',
    NOTIFICATION_GROUP = 'notification-group',
    NOTIFICATION_TYPE = 'notification-type',
    NOTIFICATION_SOURCE_CUSTOMER = 'notification-source-customer',
    NOTIFICATION_SOURCE_ORGANIZATION = 'notification-source-organization',
    NOTIFICATION_RECIPIENT_EMAIL = 'notification-recipient-email',
    NOTIFICATION_LOG = 'notification-log',
}
