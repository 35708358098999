
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { isNumber } from 'lodash';
import { ValidationProvider } from 'vee-validate';

import { sharedState } from '../state';
import { getFieldNameFromVModelProperty } from '../util/component_util';
import JsonInput from './JsonInput.vue';

@Component({
    components: { JsonInput, ValidationProvider },
})
export default class JsonField extends Vue {
    @Prop(String) readonly name!: string;
    @Prop(Object) readonly value!: object;
    @Prop(Number) readonly maxlength!: number;
    @Prop(Boolean) readonly readonly!: boolean;
    @Prop(Boolean) readonly required!: boolean;
    @Prop(Boolean) readonly horizontal!: boolean;
    @Prop(Boolean) readonly disabled!: string;

    // Refs
    $refs!: {
        observer: Vue;
    };

    shared = sharedState;
    local = {
        regex: /^[a-zåäöA-ZÅÄÖ0-9 ^,.;:!?&%'`"@|+*=/\\\-_~#()${}<>\[\]\n]+$/,
        value: undefined as object | undefined,
    };

    async mounted() {
        await this.modelValueChange(this.value);
    }

    @Watch('value')
    async modelValueChange(newValue: object) {
        this.local.value = newValue;
    }

    @Watch('local.value')
    async inputValueChange(newValue: string) {
        this.$emit('input', newValue);
    }

    getFieldName() {
        return getFieldNameFromVModelProperty(this);
    }

    isNumber(value: number) {
        return isNumber(value);
    }
}
