var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"disable-mobile-select render-container",attrs:{"id":"render-container"}},[_c('div',{staticClass:"front buttons has-addons space-left-0-5 space-0-5 disable-mobile-select"}),_c('canvas',{class:_vm.local.renderCanvasClass,attrs:{"id":"render-canvas","touch-action":"none"}}),(_vm.local.renderer && !_vm.local.renderer.inFullScreen && !_vm.local.renderer.inVr)?_c('div',{staticClass:"xr-view-left-container",style:('max-height: ' + _vm.local.sideContainerHeight + 'px')},[_c('div',{staticClass:"xr-panel",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"xr-tool"},[_c('button',{staticClass:"front button is-medium",on:{"click":_vm.back}},[_c('b-icon',{attrs:{"icon":"arrow-left","size":"is-small"}}),_c('span',[_vm._v(_vm._s(_vm.$t('button.back')))])],1)]),_c('div',{staticClass:"xr-tool"},[_c('button',{staticClass:"front button is-medium",on:{"click":_vm.enterFullScreen}},[_c('b-icon',{attrs:{"icon":"fullscreen","size":"is-small"}}),_c('span',[_vm._v(_vm._s(_vm.$t('button.enterFullScreen')))])],1)]),(_vm.local.renderer && _vm.local.renderer.vrSupported)?_c('div',{staticClass:"xr-tool"},[_c('button',{staticClass:"front button is-medium",on:{"click":_vm.enterVr}},[_c('b-icon',{attrs:{"icon":"cube-scan","size":"is-small"}}),_c('span',[_vm._v(_vm._s(_vm.$t('button.enterVr')))])],1)]):_vm._e()])]):_vm._e(),(_vm.shared.authenticated && _vm.local.renderer && !_vm.local.renderer.inFullScreen && !_vm.local.renderer.inVr)?_c('div',{staticClass:"xr-view-right-container",style:('max-height: ' + _vm.local.sideContainerHeight + 'px')},[_c('div',{staticClass:"xr-panel",staticStyle:{"height":"100%"}},[_c('space-users-view')],1)]):_vm._e(),(
            _vm.shared.authenticated &&
            _vm.local.renderer &&
            !_vm.local.renderer.inFullScreen &&
            !_vm.local.renderer.inVr &&
            this.local.bottomContainerHeight > 100
        )?_c('div',{staticClass:"xr-view-bottom-container"},[_c('b-tabs',{staticClass:"bottom-tabs",attrs:{"position":"is-left","type":"is-rounded"}},[_c('b-tab-item',{attrs:{"label":"Communication"}},[_c('CommunicationView',{attrs:{"context":_vm.local.context,"height":_vm.local.bottomContainerHeight}})],1)],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }