
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../framework/state';
import { getResources } from '../../framework/client/resource';
import { ValidationProvider } from 'vee-validate';
import { CustomerDealer } from '../../../common/application/model/CustomerDealer';
import { PAGE_SIZE } from '../../../common/framework/constants';
import { Unit } from '../../../common/application/model/Unit';

@Component({
    components: { ValidationProvider },
})
export default class UnitSelect extends Vue {
    @Prop(String) readonly customerId!: string;
    @Prop(String) readonly value!: string;

    shared = sharedState;
    local = {
        rows: new Array<CustomerDealer>(),
        total: 0,
        loading: false,
        page: 1,
        perPage: PAGE_SIZE,
        selected: undefined as CustomerDealer | undefined,
        typedName: '',
        selectedId: undefined as string | undefined,
    };

    objects: Unit[] = [];

    async mounted() {
        this.objects = [];
    }

    @Watch('customerId')
    async customerIdChange(newValue: string) {
        this.local.typedName = '';
        this.local.selectedId = '';
        if (newValue) {
            this.objects = (await getResources('unit', -1, new Map([['customerId', this.customerId]]))) as Unit[];
        } else this.objects = [];
    }

    @Watch('value')
    async valueChange(newValue: string) {
        this.local.selectedId = newValue;
    }

    @Watch('local.selectedId')
    async selectedIChange(newValue: string) {
        this.$emit('input', newValue);
    }

    get filteredObjects() {
        return this.objects.filter((o) => {
            return o.name!!.toString().toLowerCase().indexOf(this.local.typedName.toLowerCase()) != -1;
        });
    }
}
