
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { sharedState } from '../../../../../../framework/state';
import { getResource, postResource } from '../../../../../../framework/client/resource';
import { getRetrofittedAccessoryOptions } from '../../../../../service/options';
import { getVehicleModelInfo } from '../../../../../client/vehicle_model_client';
import { ApplicationResource } from '../../../../../../../common/application/enumeration/ApplicationResource';
import SelectField from '../../../../../../framework/fields/SelectField.vue';
import TextField from '../../../../../../framework/fields/TextField.vue';
import NumberField from '../../../../../../framework/fields/NumberField.vue';
import { TailoredRetrofittedAccessory } from '../../../../../../../common/application/model/TailoredRetrofittedAccessory';
import { TailoredVehicleModel } from '../../../../../../../common/application/model/TailoredVehicleModel';
import { getOptionLabel, OptionValue } from '../../../../../../framework/service/options';

@Component({
    components: { NumberField, TextField, SelectField, ValidationObserver },
})
export default class AddTailoredRetrofittedAccessory extends Vue {
    readonly resourceType = ApplicationResource.TAILORED_RETROFITTED_ACCESSORY;
    @Prop(String) readonly tailoredVehicleModelId!: string;

    shared = sharedState;
    local = {
        row: {} as TailoredRetrofittedAccessory,
        tailoredVehicleModel: undefined as TailoredVehicleModel | undefined,
        accessoryOptions: [] as OptionValue[],
        loading: false,
    };

    async mounted() {
        await this.loadAsyncData();
    }

    @Watch('local.row.externalId')
    async externalIdChanged(externalId: string) {
        if (externalId) {
            const vehicleModelInfo = await getVehicleModelInfo(this.local.tailoredVehicleModel!!.modelExternalId);
            const retrofittedAccessory = vehicleModelInfo.retrofittedAccessories.filter(
                (o) => o.oid.toString() === externalId,
            )[0];

            this.local.row = {
                ...this.local.row,
                name: getOptionLabel(externalId, this.local.accessoryOptions),
                vendorAccessoryCode: retrofittedAccessory.code,
                description: retrofittedAccessory.description,
                netPrice: retrofittedAccessory.netPrice ? retrofittedAccessory.netPrice : 0,
            };
        } else {
            this.local.row = {
                ...this.local.row,
                name: '',
                vendorAccessoryCode: '',
                description: '',
                netPrice: 0,
            };
        }
    }

    async add() {
        await postResource<TailoredRetrofittedAccessory>(this.resourceType, this.local.row);

        this.$router.go(-1);
    }

    back() {
        this.$router.go(-1);
    }

    private async loadAsyncData(): Promise<void> {
        this.local.loading = true;

        this.local.tailoredVehicleModel = (await getResource<TailoredVehicleModel>(
            ApplicationResource.TAILORED_VEHICLE_MODEL,
            this.tailoredVehicleModelId,
        ))!!;

        this.local.row = {
            ...this.local.row,
            customerId: this.local.tailoredVehicleModel.customerId,
            tailoredVehicleModelId: this.local.tailoredVehicleModel.id,
        };

        this.local.accessoryOptions = await getRetrofittedAccessoryOptions(
            this.local.tailoredVehicleModel.modelExternalId,
        );

        this.local.loading = false;
    }
}
