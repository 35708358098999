
import { Component, Prop, Vue } from 'vue-property-decorator';
import { sharedState } from '../../../../../../framework/state';
import { getRetrofittedAccessoryImages } from '../../../../../client/retrofitted_accessory_client';
import RetrofittedAccessoryImage from './RetrofittedAccessoryImage.vue';
import { AccessoryImage } from '../../../../../../../common/application/model/AccessoryImage';
import { AccessoryImageCategory } from '../../../../../../../common/application/model/AccessoryImageCategory';

@Component({
    components: { RetrofittedAccessoryImage },
})
export default class RetrofittedAccessoryImages extends Vue {
    @Prop(String) readonly retrofittedAccessoryId!: string;

    shared = sharedState;
    local = {
        images: [] as AccessoryImage[],
        primaryImageId: undefined as string | undefined,
        secondaryImageId: undefined as string | undefined,
    };

    async mounted() {
        this.local.images = await getRetrofittedAccessoryImages(this.retrofittedAccessoryId);

        for (const image of this.local.images) {
            if (image.category === AccessoryImageCategory.PRIMARY) {
                this.local.primaryImageId = image.id;
            }
            if (image.category === AccessoryImageCategory.SECONDARY) {
                this.local.secondaryImageId = image.id;
            }
        }
    }
}
