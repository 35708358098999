
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { sharedState } from '../../../../framework/state';
import { getTailoredVehicleModelImage } from '../../../client/tailored_vehicle_model_client';

@Component
export default class TailoredVehicleModelImage extends Vue {
    @Prop(String) readonly tailoredVehicleModelId!: string;
    @Prop(String) readonly imageType!: string;
    @Prop(String) readonly imageId!: string;

    shared = sharedState;
    local = {
        file: null as { name: string; size: number; type: string } | null,
        loading: false,
        imageId: undefined as string | undefined,
        objectUrl: '',
    };

    async mounted() {
        this.local.imageId = this.imageId;
        await this.getImage();
    }

    @Watch('local.file')
    async vehicleTypeChange(file: { name: string; size: number; type: string }) {
        if (file && file.name) {
            this.upload();
        }
    }

    @Watch('imageId')
    async imageIdChange(imageId: string | undefined) {
        this.local.imageId = imageId;
        await this.getImage();
    }

    upload() {
        this.local.loading = true;

        const formData = new FormData();
        let file: any = this.local.file;
        let name = file.uploadName;
        file = new File([file], name, { type: file.type });
        formData.append('vehicleImage', file);

        const xhr = new XMLHttpRequest();

        xhr.addEventListener('loadend', (e: Event) => {
            this.local.loading = false;
        });

        xhr.addEventListener('error', () => {
            this.errorToast();
            this.local.loading = false;
        });
        xhr.addEventListener('abort', () => {
            this.local.loading = false;
        });
        xhr.onreadystatechange = () => {
            if (xhr.readyState == 4) {
                if (xhr.status == 200 || xhr.status == 301) {
                    const response: { id: string } = JSON.parse(xhr.responseText);
                    this.local.imageId = response.id;
                    //success(xhr.responseText);
                } else {
                    console.error('Upload returned error status: ' + xhr.status);
                    this.errorToast();
                }
            }
        };

        xhr.open(
            'POST',
            `/api/configurer/tailored-vehicle-models/${this.tailoredVehicleModelId}/images/${this.imageType}`,
            true,
        );
        xhr.setRequestHeader('Authorization', 'Bearer ' + sharedState.context.idToken);
        xhr.send(formData);
    }

    errorToast() {
        this.$buefy.toast.open({
            message: this.$t('error.system.error').toString(),
            type: 'is-danger',
            position: 'is-top',
        });
    }

    private async getImage(): Promise<void> {
        if (!this.local.imageId) {
            return;
        }

        const image = await getTailoredVehicleModelImage(this.local.imageId);
        this.local.objectUrl = URL.createObjectURL(image);
    }
}
