
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../framework/state';
import {
    dateToDbDateString,
    dateToFinnishDbDateString,
    dateToFinnishUiDateString,
} from '../../../common/framework/util/convert';
import { getFieldNameFromVModelProperty } from '../util/component_util';
import { ValidationProvider } from 'vee-validate';

@Component({
    components: { ValidationProvider },
})
export default class DateField extends Vue {
    @Prop(String) readonly name!: string;
    @Prop(String) readonly value!: string;
    @Prop(Boolean) readonly readonly!: string;
    @Prop(Boolean) readonly required!: boolean;
    @Prop(Boolean) readonly horizontal!: boolean;
    @Prop(Boolean) readonly disabled!: string;
    @Prop(Boolean) readonly validDate!: boolean;
    @Prop(Boolean) readonly inline!: boolean;
    @Prop(Date) readonly minDate!: Date;

    shared = sharedState;
    local = {
        value: undefined as Date | undefined,
        dayNames: [] as string[],
        monthNames: [] as string[],
    };

    async mounted() {
        this.local.monthNames = [
            this.$t('enum.month.january').toString(),
            this.$t('enum.month.february').toString(),
            this.$t('enum.month.march').toString(),
            this.$t('enum.month.april').toString(),
            this.$t('enum.month.may').toString(),
            this.$t('enum.month.june').toString(),
            this.$t('enum.month.july').toString(),
            this.$t('enum.month.august').toString(),
            this.$t('enum.month.september').toString(),
            this.$t('enum.month.october').toString(),
            this.$t('enum.month.november').toString(),
            this.$t('enum.month.december').toString(),
        ];
        this.local.dayNames = [
            this.$t('enum.weekDay.sunday').toString(),
            this.$t('enum.weekDay.monday').toString(),
            this.$t('enum.weekDay.tuesday').toString(),
            this.$t('enum.weekDay.wednesday').toString(),
            this.$t('enum.weekDay.thursday').toString(),
            this.$t('enum.weekDay.friday').toString(),
            this.$t('enum.weekDay.saturday').toString(),
        ];
        await this.modelValueChange(this.value as any as Date);
    }

    @Watch('value')
    async modelValueChange(newValue: Date) {
        if (typeof newValue === 'string') {
            this.local.value = new Date(newValue);
        } else {
            this.local.value = newValue;
        }
    }

    @Watch('local.value')
    async inputValueChange(newValue: Date) {
        if (newValue) {
            this.$emit('input', dateToDbDateString(newValue));
        } else {
            this.$emit('input', newValue);
        }
    }

    formatDate(date: string) {
        if (!date) {
            return '';
        }
        return dateToFinnishUiDateString(new Date(date));
    }

    getFieldName() {
        return getFieldNameFromVModelProperty(this);
    }

    dateToString(date: Date) {
        if (!date) {
            return '';
        }
        return dateToFinnishDbDateString(date);
    }
}
