import { apiDelete, apiGet, apiPost, apiPut } from './index';
import { ERROR_API_CALL_FAILED } from '../../../common/framework/constants';
import { Profile } from '../../../common/framework/model/Profile';
import { ApiToken } from '../../../common/framework/model/ApiToken';
import { GenerateApiTokenRequest } from '../../../common/framework/model/GenerateApiTokenRequest';
import { GenerateApiTokenResponse } from '../../../common/framework/model/GenerateApiTokenResponse';

export async function postGenerateApiToken(
    generateApiTokenRequest: GenerateApiTokenRequest,
): Promise<GenerateApiTokenResponse> {
    const response = await apiPost('/api/token', generateApiTokenRequest);
    if (response.status === 200) {
        return (await response.json()) as GenerateApiTokenResponse;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getApiTokens(): Promise<ApiToken[]> {
    const response = await apiGet('/api/token');
    if (response.status === 200) {
        return (await response.json()) as ApiToken[];
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function deleteApiToken(id: string): Promise<void> {
    const response = await apiDelete('/api/token/' + id);
    if (response.status === 200) {
        return;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}
