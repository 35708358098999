
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../framework/state';
import { getFieldNameFromVModelProperty } from '../util/component_util';

@Component
export default class SelectComponent extends Vue {
    @Prop(String) readonly name!: string;
    @Prop(String) readonly value!: string;
    @Prop(Array) readonly options!: { id: string | undefined; label: string }[];

    shared = sharedState;
    local = {
        value: undefined as string | undefined,
    };

    async mounted() {
        await this.modelValueChange(this.value);
    }

    @Watch('value')
    async modelValueChange(newValue: string) {
        this.local.value = newValue;
    }

    @Watch('local.value')
    async inputValueChange(newValue: string) {
        this.$emit('input', newValue);
    }

    getFieldName() {
        return getFieldNameFromVModelProperty(this);
    }
}
