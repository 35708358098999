import { isEmpty, isNil } from 'lodash';
import { v4 as uuid } from 'uuid';

import { addSourceBuffer, transmitMediaStreamMessage } from './media_stream_service';
import { MediaStreamMessage } from '../../../../../common/framework/module/xr/network/model/MediaStreamMessage';
import { PlayStreamContext } from '../model/PlayStreamContext';
import { addNetworkMessageListener, removeNetworkMessageListener } from './network_service';
import { MessageType } from '../../../../../common/framework/module/xr/network/model/MessageType';
import { getGlobalState } from '../../../service/global_state_service';
import { CommunicationState } from '../model/CommunicationState';
import { FrameworkStateType } from '../../../../../common/framework/enumeration/FrameworkStateType';

let mediaStream: MediaStream | undefined;
let mediaRecorder: MediaRecorder | undefined;
let recordedStreamId: string | undefined;
let recordedStreamMessageIndex = -1;
let playStreamContexts: Map<string, PlayStreamContext> = new Map();

export async function startVoice() {
    const state = getGlobalState<CommunicationState>(FrameworkStateType.COMMUNICATION_STATE);

    if (navigator.mediaDevices && !isEmpty(state.authenticationToken)) {
        mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
        console.log('got media stream');
        mediaRecorder = new MediaRecorder(mediaStream, { mimeType: 'audio/webm;codecs=opus' });
        console.log('got media recorder');
    }

    addNetworkMessageListener(MessageType.MEDIA_STREAM, onMediaStreamMessage);
}

export async function stopVoice() {
    removeNetworkMessageListener(MessageType.MEDIA_STREAM, onMediaStreamMessage);
    //console.log("voice module engine context unset.")

    if (!isNil(mediaRecorder)) {
        mediaRecorder.stop();
        mediaRecorder = undefined;
        console.log('stop recording.');
    }
    if (!isNil(mediaStream) && mediaStream.active) {
        for (const track of mediaStream.getTracks()) {
            track.stop();
        }
        mediaStream = undefined;
    }
}

export async function startRecording() {
    if (!isNil(mediaRecorder)) {
        if (!isNil(recordedStreamId)) {
            await stopRecording();
            return;
        }

        recordedStreamId = uuid();
        recordedStreamMessageIndex = 0;

        mediaRecorder.ondataavailable = async (e: BlobEvent) => {
            await recordedDataAvailable(e);
        };
        mediaRecorder.onstop = async (e: Event) => {
            await recordingStopped(e);
        };

        mediaRecorder.start(150);
        console.log('started recording');
    }
}

export async function stopRecording() {
    if (!isNil(mediaRecorder)) {
        mediaRecorder.stop();
    }
}

async function recordingStopped(evt: Event) {
    console.log('stopped recording.');
    setTimeout(async () => {
        await transmitMediaStreamMessage(
            recordedStreamId!!,
            recordedStreamMessageIndex,
            new Buffer(new ArrayBuffer(0)),
            '',
        );
        console.log('send voice message: ' + recordedStreamId + ' #' + recordedStreamMessageIndex + ' : ' + 0);
        recordedStreamId = undefined;
        recordedStreamMessageIndex = 0;
    }, 500);
}

async function recordedDataAvailable(e: BlobEvent) {
    if (e.data.size > 0) {
        console.log(
            'send voice message: ' +
                recordedStreamId +
                ' #' +
                recordedStreamMessageIndex +
                ' : ' +
                e.data.size +
                ' ' +
                e.data.type,
        );
        const buffer = new Buffer(await new Response(e.data).arrayBuffer());
        await transmitMediaStreamMessage(recordedStreamId!!, recordedStreamMessageIndex, buffer, e.data.type);
        recordedStreamMessageIndex++;
    }
}

export async function onMediaStreamMessage(messageType: MessageType, message: MediaStreamMessage) {
    const state = getGlobalState<CommunicationState>(FrameworkStateType.COMMUNICATION_STATE);

    const playStreamId = message.StreamId;
    const playStreamChannelId = message.ChannelId;
    const playStreamUserId = message.UserId;
    const playStreamMessageIndex = message.Index;
    const playStreamMessageBytes = message.Bytes;
    const playStreamMessageMimeType = message.MimeType;

    console.log(
        'recv voice message ' + playStreamId + ' #' + playStreamMessageIndex + ' : ' + playStreamMessageBytes.length,
    );

    if (message.Bytes.length === 0) {
        setUserVoiceTransmissionState(state, message.UserId, false);
    } else {
        setUserVoiceTransmissionState(state, message.UserId, true);
    }

    if (message.Bytes.length === 0 && playStreamContexts.has(playStreamId)) {
        const playStreamContext = playStreamContexts.get(playStreamId)!!;
        playStreamContext.mediaSource.endOfStream();
        playStreamContexts.delete(playStreamId);
        console.log('play stream marked ended: ' + playStreamId);
        return;
    }

    if (message.UserId === state.userId) {
        return; // Ignore own messages
    }

    const firstMessage = !playStreamContexts.has(playStreamId);
    if (firstMessage) {
        if (message.Bytes.length !== 0) {
            const audio = new Audio();
            const mediaSource = new MediaSource();
            audio.src = URL.createObjectURL(mediaSource);
            const sourceBuffer = await addSourceBuffer(mediaSource, playStreamMessageMimeType);
            sourceBuffer.mode = 'sequence';
            const playStreamContext: PlayStreamContext = {
                playStreamId,
                playStreamChannelId,
                playStreamUserId,
                audio,
                mediaSource,
                sourceBuffer,
            };
            playStreamContext.audio.onended = () => {
                console.log('play stream ended: ' + playStreamId);
                playStreamContext.audio.remove();
            };

            // Perhaps wrap this to 300ms time out.
            playStreamContexts.set(playStreamId, playStreamContext);
            console.log('play stream created ' + playStreamId + '/' + playStreamMessageMimeType);
        } else {
            return; // First message was also last.
        }
    }

    const playStreamContext = playStreamContexts.get(playStreamId)!!;
    // Might need to append this to async function with SourceBuffer.onupdateend to avoid overlapping writes.
    playStreamContext.sourceBuffer.appendBuffer(playStreamMessageBytes);
    if (firstMessage) {
        await playStreamContext.audio.play();
    }
}

function setUserVoiceTransmissionState(
    communicationState: CommunicationState,
    userId: string,
    voiceTransmission: boolean,
) {
    const userState = communicationState.spaceChannel!.users.filter((u) => u.id === userId)[0];
    if (userState) {
        userState.voiceTransmission = voiceTransmission;
    }
}
