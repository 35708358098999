var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('validation-provider',{attrs:{"name":_vm.getFieldName(),"rules":{ required: _vm.required }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('b-field',{attrs:{"label":_vm.$t('field.' + _vm.getFieldName()),"type":{
            'is-danger': errors[0],
            'is-success':
                valid &&
                (_vm.local.typed ===
                    _vm.local.filteredObjects
                        .filter((o) => o.id === _vm.local.selectedId)
                        .map((o) => o.label)
                        .join('') ||
                    (_vm.local.typed === '' && typeof _vm.local.selectedId === 'undefined')),
        },"message":errors}},[_c('b-autocomplete',{attrs:{"icon":"magnify","field":"label","data":_vm.local.filteredObjects,"disabled":_vm.disabled === true,"open-on-focus":true,"keep-first":true,"name":_vm.getFieldName()},on:{"blur":function($event){return _vm.focusOut()},"select":(option) => (_vm.local.selectedId = option ? option.id : '')},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(_vm._s(_vm.$t('message.noResultsFound')))]},proxy:true}],null,true),model:{value:(_vm.local.typed),callback:function ($$v) {_vm.$set(_vm.local, "typed", $$v)},expression:"local.typed"}})],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }