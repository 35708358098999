import { v4 as uuid } from 'uuid';

import { sharedState } from '../state';

export async function apiDelete(path: string): Promise<Response> {
    return await jsonRequest('DELETE', path, undefined, undefined);
}

export async function apiPost(path: string, requestBody: any): Promise<Response> {
    return await jsonRequest('POST', path, requestBody, undefined);
}

export async function apiPut(path: string, requestBody: any): Promise<Response> {
    return await jsonRequest('PUT', path, requestBody, undefined);
}

export async function apiGet(path: string, queryParameters?: any): Promise<Response> {
    return await jsonRequest('GET', path, undefined, queryParameters);
}

async function jsonRequest(method: string, path: string, requestBody: any, queryParameters: any) {
    const requestId = uuid();
    const headers = {
        'Content-Type': 'application/json',
        'Request-ID': requestId,
    };
    if (sharedState.context.idToken.length > 0) {
        (headers as any).Authorization = 'Bearer ' + sharedState.context.idToken;
    }

    const url =
        typeof window != 'undefined'
            ? new URL(window.location.protocol + '//' + window.location.host + path)
            : new URL('http://127.0.0.1:8889' + path); // tests

    if (queryParameters) {
        Object.keys(queryParameters).forEach((key) => {
            if (queryParameters[key] !== undefined) {
                url.searchParams.append(key, queryParameters[key]);
            }
        });
    }

    const requestBodyJson = JSON.stringify(requestBody, null, 2);

    //console.log('request ' + requestId + ' : ' + method + ':' + url);

    if (typeof document !== 'undefined') {
        document.dispatchEvent(new Event('api-call-begin'));
    }

    const response = await fetch(url.toString(), {
        method,
        headers,
        body: requestBodyJson,
    });

    if (response.status === 403) {
        if (typeof document != 'undefined') {
            document.dispatchEvent(new Event('api-forbidden'));
        }
    } else if (response.status === 422) {
        if (typeof document != 'undefined') {
            document.dispatchEvent(new Event('api-unprocessable-entity'));
        }
    } else if (response.status === 401) {
        if (typeof document != 'undefined') {
            document.dispatchEvent(new Event('api-unauthorized'));
        }
    } else if (response.status === 500 && method === 'DELETE') {
        if (typeof document != 'undefined') {
            document.dispatchEvent(new Event('api-error-delete'));
        }
    } else if (response.status != 200 && response.status != 404) {
        if (typeof document != 'undefined') {
            document.dispatchEvent(new Event('api-error'));
        }
    }

    if (typeof document !== 'undefined') {
        document.dispatchEvent(new Event('api-call-end'));
    }

    //console.log('response ' + requestId + ' : ' + method + ':' + url + ' : ' + response.status);

    return response;
}
