
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../framework/state';
import { postResource } from '../../../../framework/client/resource';
import TextField from '../../../../framework/fields/TextField.vue';
import SelectField from '../../../../framework/fields/SelectField.vue';
import { Job } from '../../../../../common/framework/model/Job';
import SwitchField from '../../../../framework/fields/SwitchField.vue';
import PhoneField from '../../../fields/PhoneField.vue';
import EmailField from '../../../fields/EmailField.vue';
import UserGroups from '../users/UserGroups.vue';
import { ValidationObserver } from 'vee-validate';
import TextAreaField from '../../../fields/TextAreaField.vue';
import DateTimeField from '../../../fields/DateTimeField.vue';

@Component({
    components: {
        SwitchField,
        SelectField,
        PhoneField,
        TextField,
        EmailField,
        UserGroups,
        TextAreaField,
        DateTimeField,
        ValidationObserver,
    },
})
export default class AddJob extends Vue {
    // Constants
    readonly resourceType = 'job';

    // Properties

    // State
    shared = sharedState;
    local = {
        row: {} as Job,
    };

    // Functions
    async mounted() {}

    async add() {
        const addedRow = await postResource<Job>(this.resourceType, this.local.row);
        this.back();
    }

    back() {
        this.$router.go(-1);
    }
}
