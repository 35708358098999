import * as BABYLON from 'babylonjs';
import { RayIntersectResult } from './RayIntersectResult';
import { init, init_panic_hook, IntersectResult, MeshIntersectorJS, SphereIntersectorJS } from '@tlaukkan/intersect';
import { v4 as uuid } from 'uuid';

export class RayIntersectPlugin {
    readonly meshRadii: Map<string, number> = new Map<string, number>();
    readonly instances: Map<string, BABYLON.AbstractMesh> = new Map<string, BABYLON.AbstractMesh>();
    readonly instanceMeshIds: Map<string, string> = new Map<string, string>();
    readonly meshIntersector!: MeshIntersectorJS;
    readonly sphereIntersector!: SphereIntersectorJS;
    instanceBvhBuilt = false;

    constructor() {}

    isInstanceBvhBuilt() {
        return this.instanceBvhBuilt;
    }

    public async init() {
        // If this is browser environment then call init.
        if (typeof init !== 'undefined') {
            await init();
            init_panic_hook();
        }

        (this as any).meshIntersector = new MeshIntersectorJS();
        (this as any).sphereIntersector = new SphereIntersectorJS();
    }

    public dispose() {
        this.meshIntersector.free();
        this.sphereIntersector.free();
    }

    public setMesh(mesh: BABYLON.AbstractMesh) {
        const indices = mesh.getIndices() as Uint32Array;
        if (!indices || indices.length === 0) {
            console.warn('No indices in mesh: ' + mesh.id);
            return;
        }
        const positions = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind) as Float32Array;
        if (!positions) {
            console.warn('No positions in mesh: ' + mesh.id);
            return;
        }
        //console.log(mesh.id + " indices: " + indices.length + " (" + typeof(indices) + ") positions: " + positions.length + " (" + typeof(positions) + ")");

        try {
            this.meshRadii.set(mesh.id, this.meshIntersector.set(mesh.id, indices, positions));
        } catch (e: any) {
            console.warn(
                'error setting mesh to mesh intersector: ' +
                    mesh.id +
                    ' indices: ' +
                    indices.length +
                    ' (' +
                    typeof indices +
                    ') positions: ' +
                    positions.length +
                    ' (' +
                    typeof positions +
                    ')',
            );

            console.log('indices: ' + indices);
            console.log('positions: ' + positions);
            throw e;
        }
    }

    public hasMesh(meshId: string): boolean {
        return this.meshIntersector.has(meshId);
    }

    public removeMesh(meshId: string): boolean {
        this.meshRadii.delete(meshId);
        return this.meshIntersector.remove(meshId);
    }

    public addInstance(instance: BABYLON.AbstractMesh) {
        // Hack to find original mesh to use it with precission ray cast later.
        (instance as any).meshId = instance.id.replace('instance of ', '');

        instance.id = instance.id + '/' + uuid();
        this._addInstance(instance.id, (instance as any).meshId as string, instance);
        instance.getChildMeshes().forEach((childInstance) => {
            this.addInstance(childInstance);
        });
    }

    public updateInstance(instance: BABYLON.AbstractMesh) {
        this._updateInstance(instance.id, instance);
        instance.getChildMeshes().forEach((childInstance) => {
            this.updateInstance(childInstance);
        });
    }

    public hasInstance(instanceId: string): boolean {
        return this.instances.has(instanceId);
    }

    public removeInstance(instance: BABYLON.AbstractMesh) {
        this._removeInstance(instance.id);
        instance.getChildMeshes().forEach((childInstance) => {
            this.removeInstance(childInstance);
        });
    }

    private _addInstance(instanceId: string, meshId: string, instance: BABYLON.AbstractMesh) {
        instance.computeWorldMatrix();
        const position = BABYLON.Vector3.TransformCoordinates(instance.position, instance.getWorldMatrix());

        if (!this.meshRadii.has(meshId)) {
            //console.warn("error adding instance to sphere intersector, unknown mesh ID: " + meshId);
            return;
        }
        const radius = this.meshRadii.get(meshId)!!;
        if (!this.sphereIntersector.has(instanceId)) {
            try {
                //console.log("Added sphere intersector id: " + instanceId + " position: " + JSON.stringify(position) + " radius:" + radius + " parent: " + instance.parent);
                this.sphereIntersector.add(instanceId, position.x, position.y, position.z, radius);
                this.instances.set(instanceId, instance);
                this.instanceMeshIds.set(instanceId, meshId);
            } catch (e: any) {
                console.warn(
                    `error adding instance to sphere intersector instance ID : ${instanceId} mesh ID: ${meshId}`,
                );
            }
        } else {
            console.warn(`error adding instance to sphere intersector, instance ID already added: ${instanceId}`);
        }
    }

    private _updateInstance(instanceId: string, instance: BABYLON.AbstractMesh) {
        instance.computeWorldMatrix();
        const position = BABYLON.Vector3.TransformCoordinates(instance.position, instance.getWorldMatrix());

        if (!this.instanceMeshIds.has(instanceId)) {
            console.warn(`error updating instance to sphere intersector, unknown instance ID: ${instanceId}`);
            return;
        }
        const meshId = this.instanceMeshIds.get(instanceId)!!;
        if (!this.meshRadii.has(meshId)) {
            console.warn('error updating instance to sphere intersector, unknown mesh ID: ' + meshId);
            return;
        }
        const radius = this.meshRadii.get(meshId)!!;
        if (this.sphereIntersector.has(instanceId)) {
            try {
                this.sphereIntersector.update(instanceId, position.x, position.y, position.z, radius);
            } catch (e: any) {
                console.warn(
                    `error updating instance to sphere intersector instance ID : ${instanceId} mesh ID: ${meshId}`,
                );
            }
        } else {
            console.warn(`error updating instance to sphere intersector, unknown instance ID: ${instanceId}`);
        }
    }

    private _removeInstance(instanceId: string) {
        if (this.sphereIntersector.has(instanceId)) {
            this.sphereIntersector.remove(instanceId);
            this.instances.delete(instanceId);
            this.instanceMeshIds.delete(instanceId);
        } else {
            console.warn(`error removing instance from sphere intersector, unknown instance ID: ${instanceId}`);
        }
    }

    public buildInstanceBvh() {
        this.sphereIntersector.build();
        this.instanceBvhBuilt = true;
    }

    public rayIntersect(ray: BABYLON.Ray): RayIntersectResult {
        const rayIntersectResult: RayIntersectResult = {
            hit: false,
            distance: 0,
            meshId: '',
            instanceId: '',
            triangleIndex: 0,
            u: 0,
            v: 0,
        };

        const instanceIds = this.sphereIntersector.intersect(
            ray.origin.x,
            ray.origin.y,
            ray.origin.z,
            ray.direction.x,
            ray.direction.y,
            ray.direction.z,
            ray.length,
        );

        for (let _instanceId of instanceIds) {
            const instanceId = _instanceId.toString();

            if (!this.instanceMeshIds.get(instanceId)) {
                console.warn(`Mesh ID not found for instance: ${instanceId}`);
                continue;
            }
            const meshId = this.instanceMeshIds.get(instanceId)!!;
            if (!this.instances.get(instanceId)) {
                console.warn(`Instance not found for instance: ${instanceId}`);
                continue;
            }
            const instance = this.instances.get(instanceId)!!;
            const localRay = BABYLON.Ray.Transform(ray, instance.getWorldMatrix().clone().invert());
            const origin = localRay.origin;
            const direction = localRay.direction;
            const length = localRay.length;

            //console.log("picking: origin=" + origin.x + ", " + origin.y + ", " + origin.z + ", direction=" + direction.x + ", " + direction.y + ", " + direction.z + " length: " + length);
            const intersectResults = this.meshIntersector.intersect(
                meshId,
                origin.x,
                origin.y,
                origin.z,
                direction.x,
                direction.y,
                direction.z,
                length,
            );
            if (intersectResults.length > 0) {
                const result: IntersectResult = intersectResults[0];
                //console.log("hit");
                if (
                    result.distance < length &&
                    (!rayIntersectResult.hit || result.distance < rayIntersectResult.distance)
                ) {
                    rayIntersectResult.hit = true;
                    rayIntersectResult.distance = result.distance;
                    rayIntersectResult.meshId = meshId;
                    rayIntersectResult.instanceId = instanceId;
                    rayIntersectResult.triangleIndex = result.triangle_index;
                    rayIntersectResult.u = result.u;
                    rayIntersectResult.v = result.v;
                }
            }

            intersectResults.forEach((r) => r.free());
        }

        return rayIntersectResult;
    }
}
