
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { sharedState } from '../../../../framework/state';
import { getResource, postResource } from '../../../../framework/client/resource';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';
import TextField from '../../../../framework/fields/TextField.vue';
import { Unit } from '../../../../../common/application/model/Unit';
import { Organization } from '../../../../../common/application/model/Organization';

@Component({
    components: { TextField, ValidationObserver },
})
export default class AddUnit extends Vue {
    readonly resourceType = ApplicationResource.UNIT;

    @Prop(String) readonly organizationId!: string;

    shared = sharedState;
    local = {
        row: {} as Unit,
    };

    async mounted() {
        const organization = (await getResource<Organization>(ApplicationResource.ORGANIZATION, this.organizationId))!!;

        this.local.row = {
            ...this.local.row,
            customerId: organization.customerId,
            organizationId: this.organizationId,
            deliveryAddressStreet: organization.defaultDeliveryAddressStreet,
            deliveryAddressMunicipality: organization.defaultDeliveryAddressMunicipality,
            deliveryAddressPostalCode: organization.defaultDeliveryAddressPostalCode,
            deliveryAddressCountryCode: organization.defaultDeliveryAddressCountryCode,
        };
    }

    async add() {
        await postResource(this.resourceType, this.local.row);

        this.$router.go(-1);
    }

    back() {
        this.$router.go(-1);
    }
}
