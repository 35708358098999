
import { Component, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../../state';
import { User } from '../../../../../common/framework/model/User';
import { deleteUser, getUserPaging, getUsers } from '../../../client/users';
import { PAGE_SIZE } from '../../../../../common/framework/constants';

const localState = {
    rows: new Array<User>(),
    total: 0,
    loading: false,
    page: 1,
    perPage: PAGE_SIZE,
    selected: undefined as User | undefined,
    showLocked: false,
};

@Component
export default class Users extends Vue {
    async loadAsyncData() {
        localState.loading = true;
        localState.total = (await getUserPaging(localState.showLocked ? undefined : false)).pageCount * PAGE_SIZE;
        localState.rows = [];
        const users = await getUsers(localState.page - 1, localState.showLocked ? undefined : false);
        users.forEach((user) => {
            localState.rows.push(user);
        });
        localState.loading = false;
        if (localState && localState.rows.indexOf(localState.selected!!) == -1) {
            localState.selected = undefined;
        }
    }

    @Watch('local.showLocked')
    async showLockedChanged(showLocked: boolean) {
        await this.loadAsyncData();
    }

    async mounted() {
        await this.loadAsyncData();
    }

    data() {
        return {
            shared: sharedState,
            local: localState,
        };
    }

    async onPageChange(page: number) {
        localState.page = page;
        await this.loadAsyncData();
    }

    add() {
        this.$router.push('/users/add-user');
    }

    edit() {
        if (localState.selected) {
            this.$router.push('/users/edit-user/' + localState.selected.id);
        }
    }

    changePassword() {
        if (localState.selected) {
            this.$router.push('/users/change-user-password/' + localState.selected.id);
        }
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDeleteUser').toString(),
            message: this.$t('message.confirmDeleteUser').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (localState.selected) {
                    await deleteUser(localState.selected.id);
                    await this.loadAsyncData();
                }
            },
        });
    }
}
