
import { Component, Prop, Vue } from 'vue-property-decorator';

import { ValidationObserver } from 'vee-validate';
import PasswordField from '../../../fields/PasswordField.vue';
import { sharedState } from '../../../state';
import { postUserPasswordChange } from '../../../client/password';

const localState = {
    oldPassword: '',
    password: '',
    password2: '',
};

@Component({
    components: { PasswordField, ValidationObserver },
})
export default class ChangeUserPassword extends Vue {
    $refs!: {
        observer: Vue;
    };

    @Prop(String) public readonly id!: string;

    data() {
        return {
            shared: sharedState,
            local: localState,
        };
    }

    async submit() {
        if (localState.password == localState.password2) {
            await this.changePassword();
        } else {
            this.$buefy.toast.open({
                message: this.$t('message.passwordsDoNotMatch').toString(),
                type: 'is-warning',
                position: 'is-top',
            });
        }
    }

    async changePassword() {
        if (await postUserPasswordChange(localState.oldPassword!!, this.id, localState.password!!)) {
            this.$buefy.toast.open({
                message: this.$t('message.passwordChanged').toString(),
                type: 'is-success',
                position: 'is-top',
            });
            localState.oldPassword = '';
            localState.password = '';
            localState.password2 = '';
            (this.$refs.observer as any).reset();
            this.$router.go(-1);
        } else {
            this.$buefy.toast.open({
                message: this.$t('message.passwordChangeFailed').toString(),
                type: 'is-warning',
                position: 'is-top',
            });
        }
    }
}
