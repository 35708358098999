
import { Component, Prop, Vue } from 'vue-property-decorator';

import { sharedState } from '../../state';
import { postTokenPasswordChange } from '../../client/password';
import { $router } from '../../../main';
import { ValidationObserver } from 'vee-validate';
import PasswordField from '../../fields/PasswordField.vue';
import { frameworkLoggedIn } from '../../framework_hooks';
import { applicationLoggedIn } from '../../../application/application_hooks';
import { invokeUiModuleOnLoggedIn } from '../../../../common/framework/service/module_service';

@Component({
    components: { PasswordField, ValidationObserver },
})
export default class TokenPasswordChange extends Vue {
    @Prop(String) readonly token!: string;

    shared = sharedState;
    local = {
        password: '',
        password2: '',
    };

    async changePassword() {
        if (await postTokenPasswordChange(this.local.password!!, this.token)) {
            this.$buefy.toast.open({
                message: this.$t('message.passwordChanged').toString(),
                type: 'is-success',
                position: 'is-top',
            });
            this.local.password = '';
            this.local.password2 = '';
            await frameworkLoggedIn();
            await applicationLoggedIn();
            await invokeUiModuleOnLoggedIn();
            await this.$router.push('/');
        } else {
            this.$buefy.toast.open({
                message: this.$t('message.passwordChangedFailed').toString(),
                type: 'is-warning',
                position: 'is-top',
            });
        }
    }

    async submit() {
        if (this.local.password == this.local.password2) {
            await this.changePassword();
        } else {
            this.$buefy.toast.open({
                message: this.$t('message.passwordsDoNotMatch').toString(),
                type: 'is-warning',
                position: 'is-top',
            });
        }
    }

    mounted() {}
}
