
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../../../framework/state';
import { postResource } from '../../../../../../framework/client/resource';
import { getImporterOptions } from '../../../../../service/options';
import { ApplicationResource } from '../../../../../../../common/application/enumeration/ApplicationResource';
import { RetrofittedAccessory } from '../../../../../../../common/application/model/RetrofittedAccessory';
import { OptionValue } from '../../../../../../framework/service/options';
import SelectField from '../../../../../../framework/fields/SelectField.vue';
import TextField from '../../../../../../framework/fields/TextField.vue';
import NumberField from '../../../../../../framework/fields/NumberField.vue';
import { ValidationObserver } from 'vee-validate';

@Component({
    components: { NumberField, TextField, SelectField, ValidationObserver },
})
export default class AddRetrofittedAccessory extends Vue {
    readonly resourceType = ApplicationResource.RETROFITTED_ACCESSORY;

    shared = sharedState;
    local = {
        row: {} as RetrofittedAccessory,
        vendorOptions: [] as OptionValue[],
    };

    async mounted() {
        this.local.vendorOptions = await getImporterOptions();
    }

    clear() {
        this.local.row = {} as RetrofittedAccessory;
    }

    async add() {
        const addedObject = await postResource(this.resourceType, this.local.row);

        this.$router.go(-1);
        await this.$router.push('/retrofitted-accessories/edit/' + addedObject.id);
        this.clear();
    }

    back() {
        this.$router.go(-1);
    }
}
