
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../framework/state';
import { ValidationProvider } from 'vee-validate';

@Component({
    components: { ValidationProvider },
})
export default class OrderNumberTextInput extends Vue {
    @Prop(String) readonly value!: string;

    shared = sharedState;
    local = {
        value: undefined as string | undefined,
    };

    async mounted() {}

    @Watch('value')
    async valueChange(newValue: string) {
        this.local.value = newValue;
    }

    @Watch('local.value')
    async selectedIChange(newValue: string) {
        this.$emit('input', newValue);
    }
}
