
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../../../state';
import { getResource, putResource } from '../../../../client/resource';
import TextField from '../../../../fields/TextField.vue';
import AssetLink from '../../../../../../common/framework/model/AssetLink';
import { ValidationObserver } from 'vee-validate';
import AssetSelect from '../../../../fields/AssetSelect.vue';
import Asset from '../../../../../../common/framework/model/Asset';
import { FrameworkResource } from '../../../../../../common/framework/enumeration/FrameworkResource';

@Component({
    components: { AssetSelect, TextField, ValidationObserver },
})
export default class EditAssetLink extends Vue {
    // Constants
    readonly resourceType = 'asset-link';

    // Properties
    @Prop(String) readonly id!: string;

    // State
    shared = sharedState;
    local = {
        row: {} as AssetLink,
    };

    // Functions
    async mounted() {
        this.local.row = (await getResource<AssetLink>(this.resourceType, this.id))!!;
    }

    @Watch('local.row.childAssetId')
    async childAssetIdChange(newChildAssetId: string) {
        if (newChildAssetId) {
            const assetName = (await getResource<Asset>(FrameworkResource.ASSET, newChildAssetId))!!.name;
            this.local.row = {
                ...this.local.row,
                alias: assetName,
            };
        } else {
            this.local.row = {
                ...this.local.row,
                alias: '',
            };
        }
    }

    async save() {
        await putResource(this.resourceType, this.id, this.local.row);
        this.back();
    }

    back() {
        this.$router.go(-1);
    }
}
