import * as BABYLON from 'babylonjs';
import { SceneRenderer } from './SceneRenderer';
import { Scene } from 'babylonjs/scene';
import { Ray, TrianglePickingPredicate } from 'babylonjs/Culling/ray';
import { AbstractMesh } from 'babylonjs/Meshes/abstractMesh';
import { Nullable } from 'babylonjs/types';
import { isNil } from 'lodash';

import Entity from '../../../../../common/framework/model/Entity';
import { EngineEntity } from '../model/EngineEntity';
import { EngineContext } from '../model/EngineContext';
import { engineRenderLoop } from '../service/engine_service';
import {
    interpolateEntityPositionAndRotation,
    setEntityPositionAndRotation,
    setInstanceTransformation,
} from './babylon_util';
import { ViewMode } from './ViewMode';
import { RayIntersectPlugin } from '../../../../../common/framework/module/xr/babylonjs/RayIntersectPlugin';
import Mesh = BABYLON.Mesh;
import WebXRState = BABYLON.WebXRState;
import WebXRExperienceHelper = BABYLON.WebXRExperienceHelper;
import WebXRInput = BABYLON.WebXRInput;
import WebXRSessionManager = BABYLON.WebXRSessionManager;
import WebXRAbstractMotionController = BABYLON.WebXRAbstractMotionController;
import CubeTexture = BABYLON.CubeTexture;

interface Model {
    id: string;
    category: string;
    name: string;
    description: string;
    meshes: BABYLON.Mesh[];
}

interface Waiter {
    resolve: () => void;
    reject: (error: Error) => void;
}

export class BabylonSceneRenderer implements SceneRenderer {
    public godSpeed: number = 20;
    public flySpeed: number = 10;
    public walkSpeed: number = 2;
    public dropSpeed: number = 2;

    public readonly scene: BABYLON.Scene;
    public readonly engine: BABYLON.Engine;
    public readonly camera: BABYLON.UniversalCamera;
    //public readonly navigationPlugin: BABYLON.RecastJSPlugin;
    public readonly rayIntersectPlugin: RayIntersectPlugin;
    public inVr: boolean = false;
    public inFullScreen: boolean = false;

    public readonly modelLoadWaiters: Map<string, Array<Waiter>> = new Map<string, Array<Waiter>>();
    public readonly models: Map<string, Model> = new Map<string, Model>();
    public readonly instances: Map<string, Mesh> = new Map<string, Mesh>();
    public readonly context: EngineContext;
    public lastRenderTimeMillis: number | undefined;

    public xrHelper!: WebXRExperienceHelper;
    public xrInput!: WebXRInput;
    public vrSupported: boolean = false;
    public leftMotionController: WebXRAbstractMotionController | undefined;
    public rightMotionController: WebXRAbstractMotionController | undefined;

    public floorMeshes: Array<Mesh> = [];
    //public navMeshes: Array<Mesh> = [];
    viewMode: ViewMode = ViewMode.WALK;

    //private navigationMeshReady = false;
    private userHeight = 1.6;
    private userRadius = 0.5;

    loadingCounter = 0;

    private environmentTexture!: CubeTexture;

    constructor(canvas: HTMLCanvasElement, context: EngineContext) {
        this.context = context;
        this.engine = new BABYLON.Engine(canvas, true, undefined, true);

        //this.navigationPlugin = new BABYLON.RecastJSPlugin(Recast);
        this.rayIntersectPlugin = new RayIntersectPlugin();
        this.engine.vrPresentationAttributes = {
            highRefreshRate: true,
            foveationLevel: 0,
        };

        this.scene = new BABYLON.Scene(this.engine);
        this.environmentTexture = BABYLON.CubeTexture.CreateFromPrefilteredData('img/environment.dds', this.scene);
        this.scene.environmentTexture = this.environmentTexture;
        this.scene.useRightHandedSystem = true;

        const skyDome = new BABYLON.PhotoDome(
            'sky',
            '/img/gradient_env_equirectangular.png',
            {
                resolution: 4,
                size: 1000,
            },
            this.scene,
        );

        const ground = BABYLON.MeshBuilder.CreateGround(
            'ground',
            {
                width: 12,
                height: 12,
                subdivisions: 6,
            },
            this.scene,
        );
        ground.position.y = 0.1;
        ground.setEnabled(false);

        this.floorMeshes.push(ground);

        const light = new BABYLON.DirectionalLight('light', new BABYLON.Vector3(0, -1, 0.5), this.scene);
        light.diffuse = new BABYLON.Color3(0.6, 0.6, 0.6);
        light.specular = new BABYLON.Color3(0, 0, 0);
        light.position = new BABYLON.Vector3(0, 5, -2);

        const ambient = new BABYLON.HemisphericLight('hemiLight', new BABYLON.Vector3(0, 1, 0), this.scene);
        ambient.diffuse = new BABYLON.Color3(0.7, 0.7, 0.7);
        ambient.specular = new BABYLON.Color3(0, 0, 0);
        ambient.groundColor = new BABYLON.Color3(0.2, 0.2, 0.2);

        const camera = new BABYLON.UniversalCamera(
            'UniversalCamera',
            new BABYLON.Vector3(0, this.userHeight, -5),
            this.scene,
        );
        camera.setTarget(BABYLON.Vector3.Zero());
        camera.attachControl(canvas, true);
        camera.minZ = 0.1;
        //camera.keysUp.push(87);    //W
        //camera.keysDown.push(83);  //D
        //camera.keysLeft.push(65);  //A
        //camera.keysRight.push(68); //S*/
        //camera.speed = 0.1;
        this.camera = camera;

        /*this.camera.ellipsoid = new BABYLON.Vector3(0.3, 0.8, 0.3);
        this.scene.gravity = new BABYLON.Vector3(0, -9.81, 0);

        this.scene.collisionsEnabled = true;
        this.camera.checkCollisions = true;
        this.camera.applyGravity = true;*/

        this.setViewMode(ViewMode.WALK);

        //this.scene.pickWithRay()

        this.scene.pickWithRay = (
            ray: Ray,
            predicate?: (mesh: AbstractMesh) => boolean,
            fastCheck?: boolean,
            trianglePredicate?: TrianglePickingPredicate,
        ): Nullable<BABYLON.PickingInfo> => {
            //console.log('Scene pick with ray!');

            const result = this.rayIntersectPlugin.rayIntersect(ray);

            if (result.hit) {
                //console.log('hit');
                const hit = result.hit;
                const faceIndex = result.triangleIndex;
                const distance = result.distance;
                const u = result.u;
                const v = result.v;
                const instanceId = result.instanceId;
                const mesh = this.scene.getMeshByID(instanceId);
                const pickingInfo = new BABYLON.PickingInfo();

                pickingInfo.pickedPoint = ray.origin.clone().addInPlace(ray.direction.clone().scaleInPlace(distance));
                pickingInfo.ray = ray;
                pickingInfo.hit = hit;
                pickingInfo.faceId = faceIndex;
                pickingInfo.distance = distance;
                pickingInfo.bu = u;
                pickingInfo.bv = v;
                pickingInfo.pickedMesh = mesh;
                return pickingInfo;
            } else {
                console.log('miss');
                return null;
            }
        };

        this.engine.loadingUIBackgroundColor = '#ffffff';
        this.engine.loadingUIText = 'Loading assets...';
    }

    async startRenderer(): Promise<void> {
        await this.rayIntersectPlugin.init();

        console.log('Web XR session init begin...');
        this.vrSupported = await WebXRSessionManager.IsSessionSupportedAsync('immersive-vr');
        console.log('Web XR session init end. VR supported: ' + this.vrSupported);

        if (this.vrSupported) {
            const xr = await this.scene.createDefaultXRExperienceAsync({ floorMeshes: this.floorMeshes });
            this.xrHelper = xr.baseExperience;
            this.xrInput = xr.input;
            const featuresManager = this.xrHelper.featuresManager; // or any other way to get a features manager
            /*featuresManager.disableFeature(BABYLON.WebXRFeatureName.POINTER_SELECTION);*/
            featuresManager.disableFeature(BABYLON.WebXRFeatureName.TELEPORTATION);
            featuresManager.enableFeature(BABYLON.WebXRFeatureName.TELEPORTATION, 'latest', {
                xrInput: this.xrInput,
                floorMeshes: this.floorMeshes,
            });

            this.xrInput.onControllerAddedObservable.add((inputSource) => {
                inputSource.onMotionControllerInitObservable.add((c) => {
                    c.onModelLoadedObservable.add((controller) => {
                        if (controller.handness === 'right') {
                            console.log('Right motion controller added.');
                            this.rightMotionController = controller;
                        }
                        if (controller.handness === 'left') {
                            console.log('Left motion controller added.');
                            this.leftMotionController = controller;
                        }
                    });
                });
            });
            this.xrInput.onControllerRemovedObservable.add((inputSource) => {
                if (inputSource.motionController) {
                    if (inputSource.motionController.handness === 'right') {
                        console.log('Right motion controller removed.');
                        this.rightMotionController = undefined;
                    }
                    if (inputSource.motionController.handness === 'left') {
                        console.log('Left motion controller removed.');
                        this.leftMotionController = undefined;
                    }
                }
            });
            this.xrHelper.onStateChangedObservable.add((state: WebXRState) => {
                switch (state) {
                    case WebXRState.IN_XR:
                        console.log('in xr.');
                        this.inVr = true;
                        return;
                    case WebXRState.ENTERING_XR:
                        console.log('entering xr.');
                        return;
                    case WebXRState.EXITING_XR:
                        console.log('exiting xr.');
                        return;
                    case WebXRState.NOT_IN_XR:
                        console.log('not in xr.');
                        this.inVr = false;
                        return;
                }
            });
        }

        this.engine.runRenderLoop(() => {
            const timeNowMillis = new Date().getTime();
            if (this.lastRenderTimeMillis === undefined) {
                this.lastRenderTimeMillis = timeNowMillis;
            }
            if (timeNowMillis != this.lastRenderTimeMillis) {
                engineRenderLoop(timeNowMillis, timeNowMillis - this.lastRenderTimeMillis, this.context);
            }
            this.lastRenderTimeMillis = timeNowMillis;
            this.scene.render();
        });

        window.addEventListener('resize', this.resized);
    }

    resized = () => {
        this.engine.resize();
    };

    public stopRenderer() {
        window.removeEventListener('resize', this.resized);
        if (this.xrHelper) {
            this.xrHelper.dispose();
        }
        if (this.scene) {
            this.scene.dispose();
        }
        if (this.engine) {
            this.engine.dispose();
        }
        if (this.rayIntersectPlugin) {
            this.rayIntersectPlugin.dispose();
        }
    }

    setViewMode(viewMode: ViewMode): void {
        if (this.viewMode !== viewMode) {
            if (viewMode === ViewMode.GOD) {
                // TO GOD
                this.camera.position.y += 30;
            }
            this.viewMode = viewMode;
            console.log('View mode changed: ' + viewMode);
        }
    }

    public setFullScreen(fullscreen: boolean): void {
        this.inFullScreen = fullscreen;
    }

    public async setVR(vr: boolean): Promise<void> {
        //this.isInVr = vr;
        console.log('setVR');
        if (this.inVr) {
            if (!vr) {
                await this.xrHelper.exitXRAsync();
            }
        } else {
            if (vr) {
                if (this.vrSupported) {
                    const sessionManager = await this.xrHelper.enterXRAsync(
                        'immersive-vr',
                        'local-floor' /*, optionalRenderTarget */,
                    );
                } else {
                    const sessionManager = await this.xrHelper.enterXRAsync(
                        'inline',
                        'viewer' /*, optionalRenderTarget */,
                    );
                }
            }
        }
    }

    public loadModel(
        id: string,
        category: string,
        name: string,
        description: string,
        rooltUrl: string,
        fileName: string,
    ): Promise<void> {
        if (this.modelLoadWaiters.has(id)) {
            return new Promise<void>((resolve, reject) => {
                this.modelLoadWaiters.get(id)!!.push({
                    resolve: () => resolve,
                    reject: (error) => reject(error),
                });
            });
        } else {
            this.modelLoadWaiters.set(id, []);
            return new Promise<void>((resolve, reject) => {
                BABYLON.SceneLoader.ImportMesh(
                    '',
                    rooltUrl,
                    fileName,
                    this.scene,
                    (meshes) => {
                        const model: Model = { id, category, name, description, meshes: [] };
                        for (const mesh of meshes) {
                            mesh.setEnabled(false);
                            console.log('loaded mesh: ' + mesh.id);
                            /*console.log('set reflection to: ' + mesh.name);
                        if (mesh.material && (mesh.material as any).subMaterial) {
                            const multiMaterial: MultiMaterial = mesh.material as MultiMaterial;
                            for (const material of multiMaterial.subMaterials) {
                                console.log('set reflection to: ' + material!!.name);
                                (material as any).reflectionTexture = this.environmentTexture;
                            }

                        }*/
                            if (!mesh.parent) {
                                model.meshes.push(mesh as BABYLON.Mesh);
                            } else {
                                //mesh.id = mesh.parent.id + "/" + mesh.id; // Ensure unique names in tree.
                                /*if (model.category == 'environment') {
                                (mesh as any).subdivide(200);
                            }*/
                            }
                            if (mesh.getChildMeshes()) {
                                /*for (const child of mesh.getChildMeshes()) {
                                child.id = mesh.id + "/" + child.id;
                            }*/
                            }
                            //console.log("loaded: " + mesh.id + " parent: " + (mesh.parent ? mesh.parent.id : '') + " children: " + (mesh.getChildMeshes() ? mesh.getChildMeshes().map(m => m.id).join(','): ''));
                            mesh.name = mesh.id;
                            if (mesh.getIndices() && mesh.getIndices()!!.length > 0) {
                                this.rayIntersectPlugin.setMesh(mesh as BABYLON.Mesh);
                            }
                            //mesh.rotation = new BABYLON.Vector3(0, 0, 0);
                        }
                        this.models.set(id, model);

                        for (const waiter of this.modelLoadWaiters.get(id)!!) {
                            waiter.resolve();
                        }
                        this.modelLoadWaiters.delete(id);
                        resolve();
                    },
                    () => {},
                    (scene: Scene, message: string, exception?: any) => {
                        console.error(
                            'Error loading model "' + rooltUrl + '/' + fileName + '" : ' + message,
                            exception,
                        );
                        reject(exception);
                        for (const waiter of this.modelLoadWaiters.get(id)!!) {
                            waiter.reject(new Error('error loading model.'));
                        }
                        this.modelLoadWaiters.delete(id);
                    },
                );
            });
        }
    }

    orphanInstances: Map<string, Array<Mesh>> = new Map();

    public async addEntity(entity: Entity): Promise<void> {
        const assetId = entity.assetId;
        if (!this.models.has(assetId)) {
            console.log('entity: ' + entity.id + ' asset not loaded: ' + assetId);
            return;
        }

        const model = this.models.get(assetId)!!;

        const mesh = model.meshes[0];

        const instance = mesh.instantiateHierarchy()!! as any as Mesh;
        instance.setEnabled(true);
        instance.name = entity.name + ' / ' + model.name + ' / ' + mesh.name;
        setInstanceTransformation(instance, entity);
        this.instances.set(entity.id, instance);
        //console.log('instanced entity model: ' + instance.name + ' parent: ' + instance.parent);

        if (!isNil(entity.parentId)) {
            if (this.instances.has(entity.parentId)) {
                //console.log('Added child ' + entity.id + ' to parent: ' + entity.parentId);
                instance.parent = this.instances.get(entity.parentId) as AbstractMesh;
            } else {
                //console.log('Added child ' + entity.id + ' to orphans as parent not added yet: ' + entity.parentId);
                if (!this.orphanInstances.has(entity.parentId)) {
                    this.orphanInstances.set(entity.parentId, []);
                }
                this.orphanInstances.get(entity.parentId)!!.push(instance);
            }
        }

        this.floorMeshes.push(instance);

        if (!(entity as EngineEntity).dynamic) {
            this.rayIntersectPlugin.addInstance(instance);
        }

        console.log('added entity: ' + entity.name + ' (' + model.name + ')');

        if (this.orphanInstances.has(entity.id)) {
            const orphans = this.orphanInstances.get(entity.id)!!;
            for (const orphan of orphans) {
                orphan.parent = instance;
                //console.log('Added orphan ' + entity.id + ' to the added parent: ' + entity.parentId);
                if (this.rayIntersectPlugin.hasInstance(orphan.id)) {
                    this.rayIntersectPlugin.updateInstance(orphan);
                }
            }
            this.orphanInstances.delete(entity.id);
        }
    }

    async buildRayIntersectBvh() {
        this.rayIntersectPlugin.buildInstanceBvh();
    }

    removeEntity(entity: Entity): void {
        if (this.instances.has(entity.id)) {
            const instance = this.instances.get(entity.id)!!;
            for (const [parentId, orphans] of this.orphanInstances.entries()) {
                for (const orphan of orphans) {
                    if (orphan === instance) {
                        orphans.splice(orphans.indexOf(orphan), 1);
                    }
                }
                if (orphans.length === 0) {
                    this.orphanInstances.delete(parentId);
                }
            }
            instance.dispose();
            this.instances.delete(entity.id);
            if (this.rayIntersectPlugin.hasInstance(instance.id)) {
                this.rayIntersectPlugin.removeInstance(instance);
            }
        }
    }

    readAvatarHeadPositionAndOrientation(entity: EngineEntity): void {
        if (this.inVr) {
            const position = this.xrHelper.camera.position;
            const quaternion = this.xrHelper.camera.rotationQuaternion;
            setEntityPositionAndRotation(entity, position, quaternion);
        } else {
            const position = this.camera.position;
            const quaternion = BABYLON.Quaternion.RotationYawPitchRoll(
                this.camera.rotation.y,
                this.camera.rotation.x,
                this.camera.rotation.z,
            );
            setEntityPositionAndRotation(entity, position, quaternion);
        }
    }

    readAvatarLeftHandPositionAndOrientation(entity: EngineEntity): void {
        entity.visible = false;
        if (this.inVr && this.leftMotionController) {
            const position = (this.leftMotionController.rootMesh!!.parent!! as Mesh).position;
            const quaternion = (this.leftMotionController.rootMesh!!.parent!! as Mesh).rotationQuaternion!!;
            setEntityPositionAndRotation(entity, position, quaternion);
            entity.visible = true;
        }
    }

    readAvatarRightHandPositionAndOrientation(entity: EngineEntity): void {
        entity.visible = false;
        if (this.inVr && this.rightMotionController) {
            const position = (this.rightMotionController.rootMesh!!.parent!! as Mesh).position;
            const quaternion = (this.rightMotionController.rootMesh!!.parent!! as Mesh).rotationQuaternion!!;
            setEntityPositionAndRotation(entity, position, quaternion);
            entity.visible = true;
        }
    }

    readAvatarTorsoPositionAndOrientation(entity: EngineEntity): void {
        if (this.inVr) {
            const position = this.xrHelper.camera.position.clone();
            position.y = position.y - 1;
            const quaternion = this.xrHelper.camera.rotationQuaternion;
            setEntityPositionAndRotation(entity, position, quaternion);
        } else {
            const position = this.camera.position.clone();
            position.y = position.y - 1;
            const quaternion = BABYLON.Quaternion.RotationYawPitchRoll(this.camera.rotation.y, 0, 0);
            setEntityPositionAndRotation(entity, position, quaternion);
        }
    }

    interpolateEntity(timeDeltaMillis: number, entity: EngineEntity): void {
        interpolateEntityPositionAndRotation(timeDeltaMillis, entity, this.instances.get(entity.id));
    }

    getSpeed() {
        if (this.viewMode === ViewMode.GOD) {
            return this.godSpeed;
        } else if (this.viewMode === ViewMode.FLY) {
            return this.flySpeed;
        } else {
            return this.walkSpeed;
        }
    }

    readonly forward = new BABYLON.Vector3(0, 0, -1);
    readonly backward = new BABYLON.Vector3(0, 0, 1);
    readonly right = new BABYLON.Vector3(1, 0, 0);
    readonly left = new BABYLON.Vector3(-1, 0, 0);
    readonly down = new BABYLON.Vector3(0, -1, 0);

    moveCameraForward(timeDeltaMillis: number): void {
        this.moveCameraToDirection(this.forward, timeDeltaMillis);
    }

    moveCameraBackward(timeDeltaMillis: number): void {
        this.moveCameraToDirection(this.backward, timeDeltaMillis);
    }

    moveCameraRight(timeDeltaMillis: number): void {
        this.moveCameraToDirection(this.right, timeDeltaMillis);
    }

    moveCameraLeft(timeDeltaMillis: number): void {
        this.moveCameraToDirection(this.left, timeDeltaMillis);
    }

    moveCameraToDirection(localDirection: BABYLON.Vector3, timeDeltaMillis: number) {
        const distance = (this.getSpeed() * timeDeltaMillis) / 1000;

        const wallRay = new BABYLON.Ray(
            this.camera.position
                .clone()
                .addInPlace(this.camera.getDirection(this.down).scaleInPlace(this.userRadius / 2)),
            this.camera.getDirection(localDirection),
            this.userRadius + distance,
        );
        const wallResult = this.rayIntersectPlugin.rayIntersect(wallRay);

        if (!wallResult.hit) {
            const delta = this.camera.getDirection(localDirection).scaleInPlace(distance);
            this.camera.position.addInPlace(delta);
        }
    }

    jumpCamera(timeDeltaMillis: number): void {}

    translateCamera(timeDeltaMillis: number): void {
        if (
            !this.inVr &&
            this.viewMode === ViewMode.WALK &&
            this.rayIntersectPlugin.isInstanceBvhBuilt() /*&& this.navigationMeshReady*/
        ) {
            const floorRay = new BABYLON.Ray(
                this.camera.position.clone().addInPlace(new BABYLON.Vector3(0, -this.userHeight * 0.7, 0)),
                this.down,
                this.userHeight * 0.4,
            );
            const floorResult = this.rayIntersectPlugin.rayIntersect(floorRay);

            const dropDistance = this.dropSpeed * (timeDeltaMillis / 1000.0);
            if (floorResult.hit) {
                let oldY = this.camera.position.y;
                let newY = floorRay.origin.y - floorResult.distance + this.userHeight;
                let delta = newY - oldY;

                if (Math.abs(delta) > dropDistance) {
                    delta = Math.sign(delta) * dropDistance;
                }

                this.camera.position.y = oldY + delta;
            } else {
                if (this.viewMode === ViewMode.WALK) {
                    this.camera.position.y = this.camera.position.y - dropDistance;
                }
            }
        }
    }

    showLoadingUI() {
        this.loadingCounter++;
        this.engine.displayLoadingUI();
        console.log('show loading ui:' + this.loadingCounter);
    }

    hideLoadingUI() {
        this.loadingCounter--;
        console.log('hide loading ui:' + this.loadingCounter);
        setTimeout(() => {
            if (this.loadingCounter === 0) {
                this.engine.hideLoadingUI();
            }
        }, 2000);
    }
}
