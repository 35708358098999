
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../framework/state';
import SwitchField from '../../../fields/SwitchField.vue';
import SelectField from '../../../fields/SelectField.vue';
import PhoneField from '../../../fields/PhoneField.vue';
import TextField from '../../../fields/TextField.vue';
import EmailField from '../../../fields/EmailField.vue';
import { ValidationObserver } from 'vee-validate';
import { GenerateApiTokenRequest } from '../../../../../common/framework/model/GenerateApiTokenRequest';
import DateField from '../../../fields/DateField.vue';
import { postGenerateApiToken } from '../../../client/api_token_client';

@Component({
    components: { DateField, SwitchField, SelectField, PhoneField, TextField, EmailField, ValidationObserver },
})
export default class AddApiToken extends Vue {
    shared = sharedState;
    local = {
        row: {} as GenerateApiTokenRequest,
        generatedApiToken: undefined as string | undefined,
    };

    async add() {
        this.local.generatedApiToken = (await postGenerateApiToken(this.local.row)).token;
    }

    back() {
        this.$router.go(-1);
    }
}
