
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { sharedState } from '../../../../framework/state';
import { getResource, putResource } from '../../../../framework/client/resource';
import {
    getCustomerOptions,
    getVehicleMakeOptions,
    getVehicleModelOptions,
} from '../../../service/options';
import TailoredFactoryAccessories from '../accessory/factory/tailored/TailoredFactoryAccessories.vue';
import TailoredRetrofittedAccessories from '../accessory/retrofitted/tailored/TailoredRetrofittedAccessories.vue';
import { getVehicleModelInfo } from '../../../client/vehicle_model_client';
import TailoredVehicleModelImages from './TailoredVehicleModelImages.vue';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';
import { TailoredVehicleModel } from '../../../../../common/application/model/TailoredVehicleModel';
import { VehicleType } from '../../../../../common/application/model/VehicleType';
import {getEnumerationOptions, getOptionLabel, OptionValue} from '../../../../framework/service/options';
import SelectField from '../../../../framework/fields/SelectField.vue';
import TextField from '../../../../framework/fields/TextField.vue';

@Component({
    components: {
        TextField,
        SelectField,
        TailoredVehicleModelImages,
        TailoredRetrofittedAccessories,
        TailoredFactoryAccessories,
        ValidationObserver,
    },
})
export default class EditTailoredVehicleModel extends Vue {
    readonly resourceType = ApplicationResource.TAILORED_VEHICLE_MODEL;

    @Prop(String) readonly id!: string;

    shared = sharedState;
    local = {
        row: {} as TailoredVehicleModel,
        typeVehicleModelName: '',
        customerOptions: [] as OptionValue[],
        vehicleMakeOptions: [] as OptionValue[],
        vehicleModelOptions: [] as OptionValue[],
        vehicleTypeOptions: [] as OptionValue[],
    };

    async mounted() {
        this.local.customerOptions = await getCustomerOptions();
        this.local.vehicleMakeOptions = await getVehicleMakeOptions();
        this.local.vehicleTypeOptions = getEnumerationOptions('VehicleType', Object.keys(VehicleType), this);

        this.local.row = (await getResource<TailoredVehicleModel>(this.resourceType, this.id))!!;

        this.local.typeVehicleModelName = this.local.row.modelName;
        this.local.vehicleModelOptions = await getVehicleModelOptions(this.local.row.makeExternalId, this.local.row.vehicleType);
    }

    @Watch('local.row.vehicleType')
    async vehicleTypeChange(vehicleType: VehicleType) {
        if (vehicleType && this.local.row.makeExternalId) {
            this.local.vehicleModelOptions = await getVehicleModelOptions(
                this.local.row.makeExternalId,
                this.local.row.vehicleType,
            );
            if (this.filteredVehicleModelOptions.length == 0) {
                this.local.typeVehicleModelName = '';
                this.local.row = {
                    ...this.local.row,
                    modelExternalId: '',
                    modelName: '',
                };
            }
        } else {
            this.local.vehicleModelOptions = [] as OptionValue[];
            this.local.typeVehicleModelName = '';
            this.local.row = {
                ...this.local.row,
                modelExternalId: '',
                modelName: '',
                name: '',
            };
        }
    }

    @Watch('local.row.makeExternalId')
    async makeExternalIdChanged(makeExternalId: string) {
        if (makeExternalId) {
            this.local.vehicleModelOptions = await getVehicleModelOptions(makeExternalId, this.local.row.vehicleType);
            if (this.filteredVehicleModelOptions.length == 0) {
                this.local.typeVehicleModelName = '';
                this.local.row = {
                    ...this.local.row,
                    modelExternalId: '',
                    modelName: '',
                };
            }
        } else {
            this.local.vehicleModelOptions = [] as OptionValue[];
            this.local.typeVehicleModelName = '';
            this.local.row = {
                ...this.local.row,
                modelExternalId: '',
                modelName: '',
                name: '',
            };
        }
    }

    @Watch('local.row.modelExternalId')
    async modelExternalIdChanged(modelExternalId: string) {
        if (modelExternalId) {
            const vehicleModelInfo = await getVehicleModelInfo(modelExternalId);
            this.local.row = {
                ...this.local.row,
                name: vehicleModelInfo.model.name,
            };
        } else {
            this.local.row = {
                ...this.local.row,
                name: '',
            };
        }
    }

    get filteredVehicleModelOptions() {
        return this.local.vehicleModelOptions
            .filter((option) => !!option.id)
            .filter((option) => {
                return (
                    option.label!!.toString().toLowerCase().indexOf(this.local.typeVehicleModelName.toLowerCase()) == 0
                );
            });
    }

    modelSelected(optionId: string): void {
        this.local.row = {
            ...this.local.row,
            modelExternalId: optionId,
        };
    }

    async save() {
        await putResource<TailoredVehicleModel>(this.resourceType, this.id, {
            ...this.local.row,
            importerId: this.local.row.importerId ? this.local.row.importerId : this.shared.context.importerId!!,
            makeName: getOptionLabel(this.local.row.makeExternalId, this.local.vehicleMakeOptions),
            modelName: getOptionLabel(this.local.row.modelExternalId, this.local.vehicleModelOptions),
        });

        this.$router.go(-1);
    }

    back() {
        this.$router.go(-1);
    }
}
