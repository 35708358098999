import { isNil } from 'lodash';

import { MediaStreamMessage } from '../../../../../common/framework/module/xr/network/model/MediaStreamMessage';
import { getSpaceChannelId } from './channel_service';
import { sendMediaStreamMessage } from './network_service';
import { getGlobalState } from '../../../service/global_state_service';
import { CommunicationState } from '../model/CommunicationState';
import { FrameworkStateType } from '../../../../../common/framework/enumeration/FrameworkStateType';

export function transmitMediaStreamMessage(streamId: string, index: number, bytes: Buffer, mimeType: string) {
    const state = getGlobalState<CommunicationState>(FrameworkStateType.COMMUNICATION_STATE);
    const channelId = getSpaceChannelId();
    const userId = state.userId;

    if (isNil(channelId)) {
        console.warn('Unable to send voice steam as channel ID is undefined.');
        return;
    }

    if (isNil(userId)) {
        console.warn('Unable to send voice steam as user ID is undefined.');
        return;
    }

    const mediaStreamMessage: MediaStreamMessage = {
        ChannelId: channelId,
        UserId: userId,
        StreamId: streamId,
        Index: index,
        Bytes: bytes,
        MimeType: mimeType,
    };
    sendMediaStreamMessage(mediaStreamMessage);
}

export function addSourceBuffer(mediaSource: MediaSource, mimeType: string): Promise<SourceBuffer> {
    return new Promise<SourceBuffer>((resolve, reject) => {
        //if (MediaSource.isTypeSupported(mimeCodec)) {
        mediaSource.addEventListener('sourceopen', function () {
            resolve(mediaSource.addSourceBuffer(mimeType));
        });
    });
}
