
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { sharedState } from '../../../../framework/state';
import { hyphenatedToCamel, lowerCamelToUpperCamel } from '../../../../../common/framework/util/convert';
import { getEnumOptions } from '../../../service/options';
import { AssetType } from '../../../../../common/framework/model/AssetType';
import AssetLinks from './link/AssetLinks.vue';
import TextAreaField from '../../../fields/TextAreaField.vue';
import AssetBinaryComponent from './AssetBinaryComponent.vue';
import SelectField from '../../../fields/SelectField.vue';
import TextField from '../../../fields/TextField.vue';
import { ValidationObserver } from 'vee-validate';

@Component({
    components: { AssetLinks, TextAreaField, AssetBinaryComponent, SelectField, TextField, ValidationObserver },
})
export default class UploadAsset extends Vue {
    // Refs
    $refs!: {
        observer: Vue;
    };

    shared = sharedState;
    local = {
        file: null as { name: string; size: number; type: string } | null,
        loading: false,
        assetType: undefined as AssetType | undefined,
        category: '',
        typeOptions: [] as { id: string | undefined; label: string }[],
    };

    async mounted() {
        this.local.typeOptions = getEnumOptions('AssetType', Object.keys(AssetType)).map((o) => {
            return { id: o.id, label: this.$t(o.labelKey).toString() };
        });
    }

    @Watch('local.file')
    async fileChange(file: { name: string; size: number; type: string }) {
        if (file && file.name && (await (this.$refs.observer as any).validate())) {
            this.upload();
        }
    }

    upload() {
        this.local.loading = true;

        const formData = new FormData();
        const elementId = 'assetBinaryUpload';
        formData.append('assetBinary', (document.getElementById(elementId) as any).files[0]);

        const xhr = new XMLHttpRequest();
        xhr.addEventListener('loadend', (e: Event) => {
            this.local.loading = false;
        });
        xhr.addEventListener('error', () => {
            this.errorToast();
            this.local.loading = false;
        });
        xhr.addEventListener('abort', () => {
            this.local.loading = false;
        });
        xhr.onreadystatechange = () => {
            if (xhr.readyState == 4) {
                if (xhr.status == 200 || xhr.status == 301) {
                    this.$router.go(-1);
                } else {
                    console.warn('Upload returned error status: ' + xhr.status);
                    this.errorToast();
                }
            }
        };

        xhr.open(
            'POST',
            '/api/resource/asset/' + this.local.assetType + '/category/' + this.local.category + '/bytes/original',
            true,
        );
        xhr.setRequestHeader('Authorization', 'Bearer ' + sharedState.context.idToken);
        //xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(formData);
    }

    errorToast() {
        this.$buefy.toast.open({
            message: this.$t('error.system.error').toString(),
            type: 'is-danger',
            position: 'is-top',
        });
    }

    convertHyphenatedToCamel(str: string) {
        return lowerCamelToUpperCamel(hyphenatedToCamel(str));
    }

    back() {
        this.$router.go(-1);
    }
}
