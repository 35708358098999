
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { sharedState } from '../../../../../../framework/state';
import { getRetrofittedAccessoryImage } from '../../../../../client/retrofitted_accessory_client';

@Component
export default class RetrofittedAccessoryImage extends Vue {
    @Prop(String) readonly retrofittedAccessoryId!: string;
    @Prop(String) readonly imageType!: string;
    @Prop(String) readonly imageId!: string;

    shared = sharedState;
    local = {
        file: null as { name: string; size: number; type: string } | null,
        loading: false,
        imageId: undefined as string | undefined,
        objectUrl: '',
    };

    @Watch('local.file')
    async fileChange(file: { name: string; size: number; type: string }) {
        if (file && file.name) {
            this.upload();
        }
    }

    @Watch('imageId')
    async imageIdChange(imageId: string | undefined) {
        this.local.imageId = imageId;

        if (this.local.imageId) {
            await this.getImage(this.local.imageId);
        }
    }

    async mounted() {
        this.local.imageId = this.imageId;

        if (!this.local.imageId) {
            return;
        }

        await this.getImage(this.local.imageId);
    }

    private upload() {
        this.local.loading = true;

        const formData = new FormData();
        let file: any = this.local.file;
        let name = file.uploadName;
        file = new File([file], name, { type: file.type });
        formData.append('accessoryImage', file);

        const xhr = new XMLHttpRequest();

        xhr.addEventListener('loadend', (e: Event) => {
            console.log('loaded');
            console.log(e);
            this.local.loading = false;
        });

        xhr.addEventListener('error', () => {
            this.errorToast();
            this.local.loading = false;
        });

        xhr.addEventListener('abort', () => {
            this.local.loading = false;
        });

        xhr.onreadystatechange = () => {
            if (xhr.readyState == 4) {
                if (xhr.status == 200 || xhr.status == 301) {
                    const response: { id: string } = JSON.parse(xhr.responseText);
                    console.log(response.id);
                    this.local.imageId = response.id;
                } else {
                    console.log('Upload returned error status: ' + xhr.status);
                    this.errorToast();
                }
            }
        };

        xhr.open(
            'POST',
            `/api/configurer/retrofitted-accessories/${this.retrofittedAccessoryId}/images/${this.imageType}`,
            true,
        );
        xhr.setRequestHeader('Authorization', 'Bearer ' + sharedState.context.idToken);
        xhr.send(formData);
    }

    errorToast() {
        this.$buefy.toast.open({
            message: this.$t('error.system.error').toString(),
            type: 'is-danger',
            position: 'is-top',
        });
    }

    private async getImage(imageId: string): Promise<void> {
        const image = await getRetrofittedAccessoryImage(imageId);

        this.local.objectUrl = URL.createObjectURL(image);
    }
}
