import { registerModule } from '../../common/framework/service/module_service';
import { XrUiModule } from './module/xr/XrUiModule';
import { CommunicationModule } from './module/communication/CommunicationModule';
import { SpaceModule } from './module/space/SpaceModule';

export function registerFrameworkModules() {
    registerModule(new SpaceModule());
    registerModule(new XrUiModule());
    registerModule(new CommunicationModule());
}
