
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../framework/state';
import {
    dateAndTimeToDbDateTimeString,
    dateToDbDateString,
    dateToUiDateTimeString,
} from '../../../common/framework/util/convert';
import { getFieldNameFromVModelProperty } from '../util/component_util';
import { ValidationProvider } from 'vee-validate';

@Component({
    components: { ValidationProvider },
})
export default class DateTimeField extends Vue {
    @Prop(String) readonly name!: string;
    @Prop(String) readonly value!: string;
    @Prop(Boolean) readonly readonly!: string;
    @Prop(Boolean) readonly required!: boolean;
    @Prop(Boolean) readonly horizontal!: boolean;
    @Prop(Boolean) readonly disabled!: string;

    shared = sharedState;
    local = {
        value: undefined as Date | undefined,
        date: undefined as Date | undefined,
        time: undefined as Date | undefined,
    };

    async mounted() {
        await this.modelValueChange(this.value as any as Date);
    }

    @Watch('value')
    async modelValueChange(newValue: Date) {
        if (typeof newValue === 'string') {
            this.local.value = new Date(newValue);
            this.local.date = new Date(newValue);
            this.local.time = new Date(newValue);
        } else {
            if (newValue) {
                this.local.value = newValue;
                console.log(newValue);
                this.local.date = new Date(newValue);
                this.local.time = new Date(newValue);
            } else {
                this.local.value = newValue;
                this.local.date = undefined;
                this.local.time = undefined;
            }
        }
    }

    /*@Watch("local.value")
        async inputValueChange(newValue: Date) {
            this.$emit('input', dateToDbDateTimeString(newValue));
        }*/

    @Watch('local.date')
    async inputDateChange(date: Date) {
        if (date) {
            this.$emit('input', dateAndTimeToDbDateTimeString(date, this.local.time!!));
        } else {
            this.$emit('input', undefined);
        }
    }

    @Watch('local.time')
    async inputTimeChange(time: Date) {
        if (this.local.date) {
            this.$emit('input', dateAndTimeToDbDateTimeString(this.local.date!!, time));
        } else {
            this.$emit('input', undefined);
        }
    }

    formatDate(date: string) {
        if (!date) {
            return '';
        }
        return dateToUiDateTimeString(new Date(date));
    }

    getFieldName() {
        return getFieldNameFromVModelProperty(this);
    }

    dateToString(date: Date) {
        if (!date) {
            return '';
        }
        return dateToDbDateString(date);
    }
}
