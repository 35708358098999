
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../../state';
import { getResource, getResources } from '../../../client/resource';
import { PAGE_SIZE } from '../../../../../common/framework/constants';
import { FrameworkResource } from '../../../../../common/framework/enumeration/FrameworkResource';
import Entity from '../../../../../common/framework/model/Entity';
import { ValidationProvider } from 'vee-validate';
import { getFieldNameFromVModelProperty } from '../../../util/component_util';

@Component({
    components: { ValidationProvider },
})
export default class ParentEntitySelect extends Vue {
    @Prop(String) readonly spaceId!: string;
    @Prop(String) readonly value!: string;
    @Prop(Boolean) readonly required!: boolean;
    @Prop(Boolean) readonly disabled!: string;

    shared = sharedState;
    local = {
        rows: new Array<Entity>(),
        total: 0,
        loading: false,
        page: 1,
        perPage: PAGE_SIZE,
        selected: undefined as Entity | undefined,
        typedName: '',
        selectedId: undefined as string | undefined,
    };

    objects: Entity[] = [];

    async mounted() {
        this.objects = [];
        if (this.spaceId) {
            this.objects = (await getResources(
                FrameworkResource.ENTITY,
                -1,
                new Map([['spaceId', this.spaceId]]),
            )) as Entity[];
        } else {
            this.objects = [];
        }
    }

    @Watch('spaceId')
    async spaceIdChange(newValue: string) {
        console.log('type: ' + newValue);
        this.local.typedName = '';
        this.local.selectedId = undefined;
        if (newValue) {
            this.objects = (await getResources(
                FrameworkResource.ENTITY,
                -1,
                new Map([['spaceId', this.spaceId]]),
            )) as Entity[];
        } else {
            this.objects = [];
        }
    }

    @Watch('value')
    async valueChange(newValue: string) {
        this.local.selectedId = newValue;
        if (this.local.selectedId) {
            const entity = await getResource<Entity>(FrameworkResource.ENTITY, this.local.selectedId);
            if (entity) {
                this.local.typedName = entity.name;
            }
        }
    }

    @Watch('local.selectedId')
    async selectedIChange(newValue: string) {
        console.log(newValue);
        this.$emit('input', newValue);
    }

    get filteredObjects() {
        return [
            {
                id: undefined as undefined | string,
                name: '-',
            },
        ].concat(
            this.objects.filter((o) => {
                return o.name!!.toString().toLowerCase().indexOf(this.local.typedName.toLowerCase()) != -1;
            }),
        );
    }

    getFieldName() {
        return getFieldNameFromVModelProperty(this);
    }
}
