
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { debounce, isEmpty, isNil } from 'lodash';

import { sharedState } from '../state';
import { P_TEXT_SEARCH_FIELDS, P_TEXT_SEARCH_PATTERN, PAGE_SIZE } from '../../../common/framework/constants';
import { getFieldNameFromVModelProperty } from '../util/component_util';
import { OptionValue } from '../service/options';
import { getResources } from '../client/resource';

@Component({
    components: { ValidationProvider },
})
export default class AutoCompleteField extends Vue {
    @Prop(String) name!: string;
    @Prop(String) readonly value!: string;
    @Prop(Boolean) readonly required!: boolean;
    @Prop(Boolean) readonly disabled!: string;
    @Prop(String) readonly resource!: string;
    @Prop(String) readonly field!: string;
    @Prop(Map) readonly query!: Map<string, string>;

    shared = sharedState;
    local = {
        total: 0,
        loading: false,
        page: 1,
        perPage: PAGE_SIZE,
        selected: undefined as any | undefined,
        typed: '',
        selectedId: undefined as string | undefined,
        filteredObjects: [] as OptionValue[],
    };

    async mounted() {
        await this.load();
        await this.modelValueChange(this.value);
    }

    @Watch('value')
    async modelValueChange(newValue: string) {
        this.local.selectedId = newValue;
        if (this.local.selectedId) {
            await this.setTypedAccordingToSelectedId();
        } else {
            this.local.typed = '';
        }
    }

    @Watch('query')
    async queryValueChange(newValue: string) {
        this.lastTyped = undefined;
        await this.load();
    }

    private async setTypedAccordingToSelectedId() {
        if (typeof this.local.selectedId === 'undefined') {
            this.local.typed = '';
            return;
        }
        let matchedObjects = this.local.filteredObjects.filter((v) => v.id === this.local.selectedId);
        if (matchedObjects.length === 0) {
            // If not in preloaded set then load from database.
            matchedObjects = (
                await getResources<any>(this.resource, -1, new Map([['id', this.local.selectedId!!]]))
            ).map((o) => {
                return { id: o.id, label: o[this.field] };
            });
        }
        const typed = matchedObjects.length > 0 ? matchedObjects[0]['label'] : '?';
        if (this.local.typed !== typed) {
            this.local.typed = typed;
        }
    }

    @Watch('local.selectedId')
    async selectedIChange(newValue: string) {
        if (this.local.typed === '-') {
            this.local.typed = '';
        }
        if (!isEmpty(newValue)) {
            this.$emit('input', newValue);
        } else {
            this.$emit('input', undefined);
        }
    }

    @Watch('local.typed')
    async typedValueChange(newValue: string) {
        if (this.local.typed === '-') {
            this.local.typed = '';
        }
        if (this.local.typed === '') {
            this.local.filteredObjects = [{ id: undefined as string | undefined, label: '' }];
        }
        await debounce(this.load, 1000)();
    }

    lastTyped: string | undefined = undefined;

    async load() {
        if (this.local.typed === this.lastTyped) {
            return;
        }
        this.lastTyped = this.local.typed;
        const parameters = new Map();
        parameters.set('pageSize', 500);
        if (!isNil(this.query)) {
            this.query.forEach((value: string, key: string) => {
                parameters.set(key, value);
            });
        }
        if (this.local.typed) {
            parameters.set(P_TEXT_SEARCH_FIELDS, this.field);
            parameters.set(P_TEXT_SEARCH_PATTERN, '*' + this.local.typed + '*');
        }
        this.local.filteredObjects = await getOptions(this.resource, this.field, parameters);

        if (this.local.typed.length === 0) {
            this.local.filteredObjects = [
                {
                    id: undefined as string | undefined,
                    label: '',
                },
            ].concat(this.local.filteredObjects);
        }
        if (this.local.filteredObjects.length === 0) {
            this.local.filteredObjects = [{ id: undefined as string | undefined, label: '' }];
        }
    }

    getFieldName() {
        return getFieldNameFromVModelProperty(this);
    }

    async focusOut() {
        await debounce(this.ensureTypedCorrect, 100)();
    }

    async ensureTypedCorrect() {
        if (
            !(
                this.local.typed ===
                    this.local.filteredObjects
                        .filter((o) => o.id === this.local.selectedId)
                        .map((o) => o.label)
                        .join('') ||
                (this.local.typed === '' && typeof this.local.selectedId === 'undefined')
            )
        ) {
            await this.setTypedAccordingToSelectedId();
        }
    }
}

export async function getOptions(resource: string, field: string, parameters: Map<string, string>) {
    return (await getResources<any>(resource, -1, parameters)).map((o) => {
        return { id: o.id, label: o[field] };
    });
}
