import { getResource, getResources } from '../../client/resource';
import Space from '../../../../common/framework/model/Space';
import { FrameworkResource } from '../../../../common/framework/enumeration/FrameworkResource';
import { getGlobalState, hasGlobalState, setGlobalState } from '../../service/global_state_service';
import { FrameworkStateType } from '../../../../common/framework/enumeration/FrameworkStateType';
import { SpaceState } from './SpaceState';
import { setSpaceNavigationMenuLabel } from './space_navigation_service';
import Channel from '../../../../common/framework/model/Channel';
import ChannelUser from '../../../../common/framework/model/ChannelUser';
import { sharedState } from '../../state';

let globalSpace: Space | undefined;

export async function setGlobalSpace(id: string) {
    if (typeof globalSpace !== 'undefined' && globalSpace.id === id) {
        return;
    }
    const space = (await getResource<Space>(FrameworkResource.SPACE, id))!!;
    globalSpace = space;

    const spaceState = {
        space,
    };
    await setGlobalSpaceState(space, spaceState);
    setSpaceNavigationMenuLabel(space);
}

async function setGlobalSpaceState(space: Space, spaceState: { space: Space }) {
    if (hasGlobalState(FrameworkStateType.SPACE_STATE)) {
        if (getGlobalState<SpaceState>(FrameworkStateType.SPACE_STATE).space.id !== space.id) {
            await setGlobalState(FrameworkStateType.SPACE_STATE, spaceState);
        }
    } else {
        await setGlobalState(FrameworkStateType.SPACE_STATE, spaceState);
    }
}
