import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Buefy from 'buefy';
import Router from 'vue-router';
import { isNil } from 'lodash';
import VueMoment from 'vue-moment';

import App from './App';
import router from './Router';
import './worker/service_worker';
import { languages, localizeVeeValidate } from '../common/i18n';
import { sharedState } from './framework/state';
import { getContext } from './framework/client/context';

import { extend, ValidationProvider } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

import { postClientError } from './framework/client/error';
import { getVersion } from './framework/client/version';

import 'simplemde/dist/simplemde.min.css';

import { registerFrameworkNavigation } from './framework/framework_navigation';
import { registerApplicationNavigation } from './application/application_navigation';
import { registerFrameworkModules } from './framework/framework_modules';
import { registerApplicationModules } from './application/application_modules';
import { initializeModules } from '../common/framework/service/module_service';
import { logout } from './framework/service/authentication_service';
import { setGlobalSpace } from './framework/module/space/space_service';

let lang = navigator.language || (navigator as any).userLanguage || 'en';
if (lang.indexOf('-') > 0) {
    lang = lang.substring(0, lang.indexOf('-'));
}

localizeVeeValidate(lang);

Object.keys(rules).forEach((rule) => {
    // @ts-ignore
    extend(rule, rules[rule]);
});

extend('validDate', {
    validate: (value) => !value,
    message: 'The date is invalid.',
});

Vue.config.productionTip = false;

Vue.config.errorHandler = async (error, vm, info) => {
    await handleError(error);
};
window.onerror = async (msg, url, line, col, error) => {
    if (error) {
        await handleError(error);
    }
};
window.addEventListener('unhandledrejection', async (event) => {
    await handleError(event.reason);
});

Vue.use(Router);

Vue.use(Buefy);
Vue.config.productionTip = false;

Vue.use(VueI18n);
Vue.use(VueMoment);

export const $router = router;

router.beforeEach(async (to, from, next) => {
    if (!isNil(document) && !isNil(document!!.activeElement) && document.activeElement !== document.body) {
        (document!!.activeElement!! as any).blur();
    }

    if (to.matched.length > 0 && to.matched[0].path === '/space/:id/view') {
        const sceneId = to.params.id;
        await setGlobalSpace(sceneId);
        console.log('scene id: ' + to.params.id);
    }

    if (to.path === '/logout') {
        console.log('/logout pushed...');
        if (sharedState.authenticated) {
            await logout();
        }
        next(false);
        await router.replace('/logged-out').catch(() => {});
        return;
    }

    if (to.path === '/logged-out' && sharedState.authenticated) {
        next(false);
        await router.replace('/logout').catch(() => {});
        return;
    }

    if (to.path === '/customers/users') {
        next(false);
        if (sharedState.admin) {
            await router.push('/users').catch(() => {});
        } else {
            await router.push('/customers/' + sharedState.context.customerId + '/users').catch(() => {});
        }
        return;
    }

    if (to.path === '/' && !sharedState.authenticated) {
        next(false);
        await router.push('/login').catch(() => {});
        return;
    } else if (to.path === '/' && (sharedState.approver || sharedState.orderer || sharedState.dealer)) {
        next(false);
        await router.push('/orders').catch(() => {});
        return;
    } else if (to.path === '/' && sharedState.configurer) {
        next(false);
        await router.push('/orders').catch(() => {});
        return;
    } else if (to.path === '/' && sharedState.manager) {
        next(false);
        await router.push('/organizations').catch(() => {});
        return;
    } else if (to.path === '/' && sharedState.admin) {
        next(false);
        await router.push('/users').catch(() => {});
        return;
    }

    next();
});

start()
    .then()
    .catch((e) => console.error('error in app start: ', e));

const i18n = new VueI18n({
    locale: lang,
    fallbackLocale: 'en',
    messages: languages,
});

async function start() {
    const softwareVersion = await getVersion();
    if (softwareVersion) {
        document.title = softwareVersion!!.name;
        sharedState.version = softwareVersion!!;
        sharedState.context.version = softwareVersion!!.version;
    }

    registerFrameworkModules();
    registerApplicationModules();

    if (!sharedState.context.idToken) {
        const storedIdToken = sessionStorage.getItem('id-token');
        if (storedIdToken) {
            sharedState.context.idToken = storedIdToken;
            console.log('loaded id-token from session storage.');
        }
    }

    const context = await getContext();
    if (context) {
        sharedState.context = context;
        console.log('saved id-token to session storage.');
        sessionStorage.setItem('id-token', sharedState.context.idToken);
    } else {
        sharedState.context.idToken = '';
        console.log('cleared session storage.');
        sessionStorage.clear();
    }

    registerFrameworkNavigation();
    await registerApplicationNavigation();
    await initializeModules(sharedState.version.features);

    document.addEventListener('login', () => {
        if (sharedState.context.locale) {
            i18n.locale = sharedState.context.locale;
            localizeVeeValidate(sharedState.context.locale);
        }
    });

    i18n.locale = sharedState.context.locale || lang;
    localizeVeeValidate(i18n.locale);

    Vue.filter('uppercase', (value: string) => {
        if (!value) {
            return '';
        }
        return value.toUpperCase();
    });

    Vue.filter('euro', (value: string) => {
        if (!value) {
            return `0 €`;
        }
        value = value.toString();

        if (value.length > 3) {
            const endOf = value.substr(value.length - 3, 3);
            const startOf = value.substr(0, value.length - 3);

            return `${startOf} ${endOf} €`;
        } else {
            return `${value} €`;
        }
    });

    const vue = new Vue({
        i18n,
        components: {
            ValidationProvider,
        },
        router,
        render: (h) => h(App),
    }).$mount('#app');

    document.addEventListener('api-forbidden', async () => {
        console.warn('api call forbidden.');
        await router.replace('/logout').catch(() => {});
    });

    document.addEventListener('api-unauthorized', async () => {
        if (sharedState.anonymous) {
            if ($router.currentRoute.path !== '/login') {
                console.warn('api call unauthorized.');
                await router.replace('/login').catch(() => {});
            }
        } else {
            console.warn('api call unauthorized, logging out.');
            await router.replace('/logout').catch(() => {});
        }
    });

    document.addEventListener('api-error', () => {
        vue.$buefy.toast.open({
            message: vue.$t('error.system.error.general').toString(),
            type: 'is-danger',
            position: 'is-top',
        });
    });

    document.addEventListener('api-error-delete', () => {
        vue.$buefy.toast.open({
            message: vue.$t('error.system.error.delete').toString(),
            type: 'is-warning',
            position: 'is-top',
        });
    });

    document.addEventListener('api-call-begin', async (event) => {
        sharedState.increaseLoadingCounter();
    });

    document.addEventListener('api-call-end', async (event) => {
        sharedState.decreaseLoadingCounter();
    });
}

async function handleError(error: Error) {
    console.warn('Vue error: ' + error + '\n' + (error ? error.stack : ''));
    await postClientError(error + '\n' + (error ? error.stack : ''));
}

let loadingComponent: { close: () => any } | undefined;
let loadingCounter = 0;

export function loadingStart(vue: Vue) {
    if (loadingCounter === 0) {
        loadingCounter++;
        setTimeout(() => {
            if (loadingCounter > 0 && !loadingComponent) {
                loadingComponent = vue.$buefy.loading.open({
                    container: null,
                });
                setTimeout(() => {
                    if (loadingComponent) {
                        loadingComponent.close();
                        loadingComponent = undefined;
                        loadingCounter = 0;
                    }
                }, 10000);
            }
        }, 250);
    }
}

export function loadingEnd() {
    if (loadingCounter > 0) {
        loadingCounter--;
        setTimeout(() => {
            if (loadingCounter <= 0) {
                if (loadingComponent) {
                    loadingComponent.close();
                }
                loadingComponent = undefined;
                loadingCounter = 0;
            }
        }, 250);
    } else {
        loadingCounter = 0;
    }
}
