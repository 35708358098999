
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { isNil } from 'lodash';
import { ValidationProvider } from 'vee-validate';
import { v4 as uuid } from 'uuid';

import SimpleMDE = require('simplemde');
import { sharedState } from '../state';
import { getFieldNameFromVModelProperty } from '../util/component_util';

@Component({
    components: { ValidationProvider },
})
export default class MarkDownInput extends Vue {
    @Prop(String) readonly name!: string;
    @Prop(String) readonly value!: string;
    @Prop(Number) readonly maxlength!: number;
    @Prop(Boolean) readonly disabled!: string;
    @Prop(Boolean) readonly tabVisible!: string;

    shared = sharedState;
    local = {
        textAreaId: uuid().toString(),
        value: undefined as string | undefined,
    };
    simpleMde: SimpleMDE | undefined;

    async mounted() {
        this.simpleMde = new SimpleMDE({ element: document.getElementById(this.local.textAreaId)!! });
        //this.simpleMde.value(this.value);

        this.simpleMde.codemirror.on('change', () => {
            const value = this.simpleMde!!.value();
            if (!isNil(this.local.value) && value === '') {
                this.$emit('input', undefined);
            } else {
                this.local.value = this.value;
                this.$emit('input', this.simpleMde!!.value());
            }
        });
        await this.modelValueChange(this.value);
    }

    @Watch('value')
    async modelValueChange(newValue: string) {
        this.local.value = newValue;
        if (this.simpleMde && this.simpleMde.value() != newValue) {
            if (!isNil(newValue)) {
                this.simpleMde.value(newValue);
            } else {
                this.simpleMde.value('');
            }
        }
    }

    @Watch('tabVisible')
    async tabVisibleChange(newValue: string) {
        if (this.simpleMde) {
            this.simpleMde.codemirror.refresh();
        }
    }

    getFieldName() {
        return getFieldNameFromVModelProperty(this);
    }
}
