
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../framework/state';
import { PAGE_SIZE } from '../../../../../common/framework/constants';

import { deleteResource, getResourcePaging, getResources } from '../../../../framework/client/resource';
import { Job } from '../../../../../common/framework/model/Job';
import { dateToUiDateTimeString } from '../../../../../common/framework/util/convert';

@Component
export default class Jobs extends Vue {
    readonly resourceType = 'job';

    shared = sharedState;
    local = {
        rows: new Array<Job>(),
        total: 0,
        loading: false,
        page: 1,
        perPage: PAGE_SIZE,
        selected: undefined as Job | undefined,
    };

    async mounted() {
        await this.loadAsyncData();
    }

    async onPageChange(page: number) {
        this.local.page = page;
        await this.loadAsyncData();
    }

    add() {
        this.$router.push('/job/add');
    }

    edit() {
        if (this.local.selected) {
            this.$router.push('/job/' + this.local.selected.id + '/edit');
        }
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    await deleteResource(this.resourceType, this.local.selected.id);
                    await this.loadAsyncData();
                }
            },
        });
    }

    async loadAsyncData() {
        this.local.loading = true;
        this.local.total = (await getResourcePaging(this.resourceType)).pageCount * PAGE_SIZE;
        this.local.rows = [];
        const rows = (await getResources(this.resourceType, this.local.page - 1)) as Array<Job>;
        rows.forEach((row) => {
            this.local.rows.push(row);
        });
        this.local.loading = false;
        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }
    }

    dateTimeToString(timestamp: Date) {
        return dateToUiDateTimeString(timestamp);
    }
}
