export class UserInfo {
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
    readonly phone: string;
    readonly locale: string | undefined;
    readonly locked: boolean;

    readonly avatarHeadEntityId: string | undefined;
    readonly avatarRightHandEntityId: string | undefined;
    readonly avatarLeftHandEntityId: string | undefined;
    readonly avatarTorsoEntityId: string | undefined;

    readonly admin: boolean;
    readonly manager: boolean;
    readonly configurer: boolean;
    readonly dealer: boolean;
    readonly orderer: boolean;
    readonly approver: boolean;

    readonly importerId: string | undefined;
    readonly dealerId: string | undefined;
    readonly customerId: string | undefined;
    readonly organizationId: string | undefined;
    readonly unitId: string | undefined;

    constructor(
        firstName: string,
        lastName: string,
        email: string,
        phone: string,
        admin: boolean,
        manager: boolean,
        configurer: boolean,
        dealer: boolean,
        orderer: boolean,
        approver: boolean,
        locale: string | undefined,
        locked: boolean,
        importerId: string | undefined,
        dealerId: string | undefined,
        customerId: string | undefined,
        organizationId: string | undefined,
        unitId: string | undefined,
    ) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.phone = phone;
        this.admin = admin;
        this.manager = manager;
        this.configurer = configurer;
        this.dealer = dealer;
        this.orderer = orderer;
        this.approver = approver;
        this.locale = locale;
        this.locked = locked;

        this.importerId = importerId;
        this.dealerId = dealerId;
        this.customerId = customerId;
        this.organizationId = organizationId;
        this.unitId = unitId;
    }
}
