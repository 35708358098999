
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import { Loader } from '@googlemaps/js-api-loader';

import { sharedState } from '../../../../framework/state';
import { getResource, getResources } from '../../../../framework/client/resource';
import { postOrderUpdateRequest } from '../../../client/order';

import { getAllUnitOptions, getSalesPersonOptions } from '../../../service/options';
import { sumRetrofittedAccessoryNetPrice } from '../../../service/pricing';
import { getTailoredVehicleModelImages } from '../../../client/vehicle_model_client';
import {
    getRetrofittedAccessoryImage,
    getRetrofittedAccessoryImages,
} from '../../../client/retrofitted_accessory_client';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';
import { TailoredRetrofittedAccessory } from '../../../../../common/application/model/TailoredRetrofittedAccessory';
import { Order } from '../../../../../common/application/model/Order';
import { CustomerDealer } from '../../../../../common/application/model/CustomerDealer';
import { AccessoryImageCategory } from '../../../../../common/application/model/AccessoryImageCategory';
import { ImageCategory } from '../../../../../common/application/model/ImageCategory';
import { OrderRetrofittedAccessory } from '../../../../../common/application/model/OrderRetrofittedAccessory';
import { Dealer } from '../../../../../common/application/model/Dealer';
import { TailoredVehicleModel } from '../../../../../common/application/model/TailoredVehicleModel';
import TextField from '../../../../framework/fields/TextField.vue';
import { getEnumerationOptions, OptionValue } from '../../../../framework/service/options';
import { TailoredFactoryAccessory } from '../../../../../common/application/model/TailoredFactoryAccessory';
import SelectField from '../../../../framework/fields/SelectField.vue';
import TextAreaField from '../../../../framework/fields/TextAreaField.vue';
import DateField from '../../../../framework/fields/DateField.vue';
import PhoneField from '../../../../framework/fields/PhoneField.vue';
import { getTailoredVehicleModelImage } from '../../../client/tailored_vehicle_model_client';
import { FinancingModel } from '../../../../../common/application/model/FinancingModel';

interface ExtendedOrder extends Order {
    tailoredRetrofittedAccessoryIds: string[];
}

@Component({
    components: { PhoneField, DateField, TextAreaField, SelectField, TextField, ValidationObserver },
})
export default class EditOrder extends Vue {
    readonly orderResource = ApplicationResource.ORDER;
    readonly vehicleResource = ApplicationResource.TAILORED_VEHICLE_MODEL;
    @Prop(String) readonly id!: string;

    shared = sharedState;
    local = {
        row: {} as ExtendedOrder,
        exteriorImageObjectUrl: '',
        interiorImageObjectUrl: '',
        tailoredVehicleModel: {} as TailoredVehicleModel,
        retrofittedAccessoryIds: [] as string[],
        retrofittedAccessoryImageObjectUrls: new Map<string, string>(),
        loading: true,
        map: {} as google.maps.Map,
        financingModelOptions: [] as OptionValue[],
        dealerMarker: undefined as any,
    };

    factoryAccessories: any[] = [];
    factoryAccessoryIds: string[] = [];
    retrofittedAccessories: TailoredRetrofittedAccessory[] = [];
    retrofittedAccessoriesNetPrice = 0;

    replacesVehicle = false;

    myLatLng = { lat: 60.2803332, lng: 24.8165942 };
    dealers: Dealer[] = [];
    typedDealerName = '';
    dealer: Dealer = {} as Dealer;

    unitOptions = [] as OptionValue[];
    salesPersonOptions = [] as OptionValue[];

    async mounted() {
        await this.loadAsyncData();
        this.local.financingModelOptions = getEnumerationOptions('FinancingModel', Object.keys(FinancingModel), this);
    }

    @Watch('local.row.dealerId')
    public async onDealerIdChange(newDealerId: string) {
        this.salesPersonOptions = [];
        for (let dealer of this.dealers) {
            if (dealer.id === newDealerId) {
                this.setDealerLocation(dealer);
                this.dealer = dealer;
                this.salesPersonOptions = await getSalesPersonOptions(dealer.id);
            }
        }
        if (!newDealerId) {
            this.local.dealerMarker.setMap(null);
        } else {
        }
    }

    @Watch('local.retrofittedAccessoryIds')
    public async onRetrofittedAccessoriesChange(retrofittedAccessoryIds: string[]) {
        this.retrofittedAccessoriesNetPrice = sumRetrofittedAccessoryNetPrice(
            this.retrofittedAccessories.filter((a) => retrofittedAccessoryIds.indexOf(a.id) !== -1),
        );
    }

    async submit() {
        this.local.row.requestedDeliveryDate.setUTCHours(24, 0, 0, 0);
        this.local.row.projectedDeliveryDate.setUTCHours(24, 0, 0, 0);

        const orderRow: Order = Object.assign({
            additionalDetails: this.local.row.additionalDetails,
            created: this.local.row.created,
            customerId: this.local.row.customerId,
            customerReference: this.local.row.customerReference,
            dealerId: this.local.row.dealerId,
            externalOrderNumber: this.local.row.externalOrderNumber,
            financingModel: this.local.row.financingModel,
            financingCompany: this.local.row.financingCompany,
            holdingTime: this.local.row.holdingTime,
            id: this.local.row.id,
            mileagePerMonth: this.local.row.mileagePerMonth,
            modified: this.local.row.modified,
            numberOfVehicles: this.local.row.numberOfVehicles,
            orderNumber: this.local.row.orderNumber,
            orderPersonId: this.local.row.orderPersonId,
            projectedDeliveryDate: this.local.row.projectedDeliveryDate,
            rejectReason: this.local.row.rejectReason,
            replaceableVehicleFinanceCompany: this.local.row.replaceableVehicleFinanceCompany,
            replaceableVehicleLeaseEnd: this.local.row.replaceableVehicleLeaseEnd,
            replaceableVehicleLicensePlate: this.local.row.replaceableVehicleLicensePlate,
            replaceableVehicleMakeModel: this.local.row.replaceableVehicleMakeModel,
            replaceableVehicleOwnership: this.local.row.replaceableVehicleOwnership,
            requestedDeliveryDate: this.local.row.requestedDeliveryDate,
            salesPersonId: this.local.row.salesPersonId ? this.local.row.salesPersonId : undefined,
            state: this.local.row.state,
            tailoredVehicleModelId: this.local.tailoredVehicleModel.id,
            unitId: this.local.row.unitId,
            userFirstName: this.local.row.userFirstName,
            userLastName: this.local.row.userLastName,
            userPhone: this.local.row.userPhone,

            tailoredRetrofittedAccessoryIds: this.local.retrofittedAccessoryIds,
        }) as any as Order;

        await postOrderUpdateRequest(orderRow);
        await this.$router.push('/orders');
    }

    setDealerLocation(dealer: Dealer) {
        const geocoder = new google.maps.Geocoder();
        const address = `${dealer.visitingAddressStreet}, ${dealer.visitingAddressPostalCode} ${dealer.visitingAddressMunicipality}, ${dealer.visitingAddressCountryCode}`;
        geocoder.geocode({ address: address }, (results: any, status: any) => {
            if (status == 'OK') {
                this.local.dealerMarker.setMap(this.local.map);
                this.local.map.setCenter(results[0].geometry.location);
                this.local.dealerMarker.setPosition(results[0].geometry.location);
                this.local.dealerMarker.setAnimation(google.maps.Animation.BOUNCE);

                setTimeout(() => {
                    this.local.dealerMarker.setAnimation(null);
                }, 750);
            } else {
                this.local.dealerMarker.setMap(null);
                this.local.map.setCenter(this.myLatLng);
                console.error('Geocode was not successful for the following reason: ' + status);
            }
        });
    }

    setDealerId(dealerId: string): void {
        this.local.row = {
            ...this.local.row,
            dealerId,
        };
    }

    get filteredDealers() {
        return this.dealers
            .filter((dealer) => !!dealer.id)
            .filter((dealer) => {
                return (
                    dealer.name!!.toString().toLowerCase().indexOf(this.typedDealerName.toLowerCase()) != -1 ||
                    (dealer.customerNumber &&
                        dealer.customerNumber!!.toString().toLowerCase().indexOf(this.typedDealerName.toLowerCase()) !=
                            -1)
                );
            });
    }

    back() {
        this.$router.go(-1);
    }

    private async loadAsyncData(): Promise<void> {
        this.local.loading = true;

        this.unitOptions = await getAllUnitOptions();
        this.local.row = (await getResource<ExtendedOrder>(this.orderResource, this.id))!!;
        await this.initMap();

        this.local.row = {
            ...this.local.row,
            requestedDeliveryDate: new Date(this.local.row.requestedDeliveryDate),
            projectedDeliveryDate: new Date(this.local.row.projectedDeliveryDate),
            replaceableVehicleLeaseEnd: new Date(this.local.row.replaceableVehicleLeaseEnd),
        };

        if (this.local.row.replaceableVehicleLicensePlate) {
            this.replacesVehicle = true;
        }

        this.local.tailoredVehicleModel = (await getResource<TailoredVehicleModel>(
            this.vehicleResource,
            this.local.row.tailoredVehicleModelId,
        ))!!;

        const customerDealersIds = (
            await getResources<CustomerDealer>(
                ApplicationResource.CUSTOMER_DEALER,
                -1,
                new Map([['customerId', this.local.tailoredVehicleModel.customerId]]),
            )
        ).map((d) => d.dealerId);

        this.dealers = (await getResources<Dealer>(ApplicationResource.DEALER, -1)).filter(
            (d) => customerDealersIds.indexOf(d.id) !== -1,
        );

        this.dealers.find((dealer: Dealer) => {
            if (dealer.id === this.local.row.dealerId) {
                this.dealer = dealer;
                this.typedDealerName = this.dealer.name;
            }
        });

        if (this.dealer) {
            this.salesPersonOptions = await getSalesPersonOptions(this.dealer.id);
        }

        this.retrofittedAccessories = await getResources<TailoredRetrofittedAccessory>(
            ApplicationResource.TAILORED_RETROFITTED_ACCESSORY,
            -1,
            new Map([['tailoredVehicleModelId', this.local.tailoredVehicleModel.id]]),
        );

        for (const retrofittedAccessory of this.retrofittedAccessories) {
            if (!retrofittedAccessory.externalId || retrofittedAccessory.externalId.length !== 36) {
                continue;
            }
            const images = await getRetrofittedAccessoryImages(retrofittedAccessory.externalId);

            for (const image of images) {
                if (image.category === AccessoryImageCategory.PRIMARY) {
                    const imageBlob = await getRetrofittedAccessoryImage(image.id);
                    this.local.retrofittedAccessoryImageObjectUrls.set(
                        retrofittedAccessory.id,
                        URL.createObjectURL(imageBlob),
                    );
                }
            }
        }

        this.factoryAccessories = await getResources<TailoredFactoryAccessory>(
            ApplicationResource.TAILORED_FACTORY_ACCESSORY,
            -1,
            new Map([['tailoredVehicleModelId', this.local.tailoredVehicleModel.id]]),
        );
        this.factoryAccessoryIds = this.factoryAccessories.map(({ id }) => id);

        if (this.dealer) {
            this.setDealerLocation(this.dealer);
        }

        const images = await getTailoredVehicleModelImages(this.local.tailoredVehicleModel.id);
        for (const image of images) {
            if (image.category === ImageCategory.EXTERIOR) {
                this.local.exteriorImageObjectUrl = await this.getImage(image.id);
            }
            if (image.category === ImageCategory.INTERIOR) {
                this.local.interiorImageObjectUrl = await this.getImage(image.id);
            }
        }

        const tailoredRetrofittedAccessoryIds = await getResources<OrderRetrofittedAccessory>(
            ApplicationResource.ORDER_RETROFITTED_ACCESSORY,
            -1,
            new Map([['orderId', this.id]]),
        );

        this.local.row = {
            ...this.local.row,
            tailoredRetrofittedAccessoryIds: tailoredRetrofittedAccessoryIds.map(({ id }) => id),
        };

        for (const acc of tailoredRetrofittedAccessoryIds) {
            this.local.retrofittedAccessoryIds.push(acc.tailoredRetrofittedAccessoryId);
        }

        this.local.loading = false;
    }

    private async getImage(imageId: string): Promise<string> {
        if (!imageId) {
            return '';
        }

        const image = await getTailoredVehicleModelImage(imageId);
        return URL.createObjectURL(image);
    }

    private initMap(): void {
        EditOrder.loader
            .importLibrary('maps')
            .then(() => {
                this.local.map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
                    center: { lat: 60.293761, lng: 24.9657867 },
                    zoom: 10,
                });

                this.local.map.setCenter(this.myLatLng);
                this.local.map.setZoom(14);
                this.local.dealerMarker = new google.maps.Marker();

                // if (this.coords) {
                //     this.setMarker(this.coords);
                //     this.centerMap(this.coords);
                // }
            })
            .catch((error: any) => {
                console.error(`Error loading Google Maps: ${error.toString()}`);
            });
    }

    private static get loader(): Loader {
        return new Loader({
            apiKey: 'AIzaSyD3X2dXxNfl348ipkZ7ft7VMXaHEgS6x4A',
            version: 'weekly',
        });
    }
}
