import { BrowserContext } from '../model/BrowserContext';
import { Scopes } from '../model/Scopes';

export function getAnonymousContext(): BrowserContext {
    return new BrowserContext(
        'anonymous',
        'anonymous',
        ['anonymous'],
        ['anonymous'],
        '',
        '',
        '',
        undefined,
        {} as Scopes,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
    );
}
