import { apiGet } from '../../framework/client';
import { ERROR_API_CALL_FAILED } from '../../../common/framework/constants';

export async function getTailoredVehicleModelImage(tailoredVehicleModelId: string): Promise<Blob> {
    const response = await apiGet(`/api/user/tailored-vehicle-model-images/${tailoredVehicleModelId}`);
    if (response.status === 200) {
        return await response.blob();
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}
